import React from "react";
import * as Yup from "yup";
import {
  RegistrationCommitteeItemCategory,
  RegistrationCommitteeItemType,
  RegistrationCommitteeItemTypeInput
} from "registration-committee-items";
import { Validations } from "common/validations/common-yup-validations";
import { useMutation } from "@apollo/client";
import { FetchRegistrationCommitteeItemTypes, SaveRegistrationCommitteeItemTypeMutation } from "./queries";
import { Button, Checkbox, Dialog, DialogActions, DialogContent, FormControlLabel, Grid, MenuItem } from "@mui/material";
import { TextField as FmuiTextField } from "formik-mui";
import ClosableDialogTitle from "common/ClosableDialogTitle";
import { Formik, Form as FormikForm, Field as FormikField } from "formik";
import { LoadingButton } from "@mui/lab";
import RichTextEditor from "common/RichTextEditor";
import { MergeFieldType } from "common/HtmlMergeFields/models";
import { fieldStyles } from "styles/common";
import { makeStyles } from "../makeStyles";

interface Props {
  onClose: () => void;
  title: string;
  confirmButtonText: string;
  data?: RegistrationCommitteeItemType;
  categories: RegistrationCommitteeItemCategory[];
}

const useStyles = makeStyles()((theme) => ({
  ...fieldStyles(theme),
  richTextFields: {
    paddingTop: theme.spacing(1.5)
  }
}));

const EditRegistrationCommitteeItemTypeDialog = (props: Props) => {
  const { classes } = useStyles();

  const issueTemplateContentRetriever = React.useRef<{ getContentAsHtml: () => string | null }>({ getContentAsHtml: () => null });
  const backgroundTemplateContentRetriever = React.useRef<{ getContentAsHtml: () => string | null }>({ getContentAsHtml: () => null });
  const committeeToDecideTemplateContentRetriever = React.useRef<{ getContentAsHtml: () => string | null }>({
    getContentAsHtml: () => null
  });
  const decisionOptionsTemplateContentRetriever = React.useRef<{ getContentAsHtml: () => string | null }>({ getContentAsHtml: () => null });

  const validationSchema = Yup.object().shape({
    name: Validations.requiredText(),
    sortOrder: Validations.requiredNonNegativeNumber(),
    categoryId: Validations.requiredNonNegativeInteger()
  });

  const [saveMutate, saveMutation] = useMutation<
    { registrationCommitteeItem: { saveType: RegistrationCommitteeItemType } },
    { itemType: RegistrationCommitteeItemTypeInput }
  >(SaveRegistrationCommitteeItemTypeMutation, {
    refetchQueries: [{ query: FetchRegistrationCommitteeItemTypes, variables: { includeInactive: true } }]
  });

  return (
    <Dialog open={true} onClose={props.onClose} fullWidth scroll="body" maxWidth="md">
      <ClosableDialogTitle onClose={props.onClose}>{props.title}</ClosableDialogTitle>
      <Formik
        initialValues={{
          name: props.data?.name ?? "",
          sortOrder: props.data?.sortOrder ?? 1,
          isActive: props.data?.isActive ?? true,
          categoryId: props.data?.category.id,
          issueTemplate: props.data?.issueTemplate ?? null,
          backgroundTemplate: props.data?.backgroundTemplate ?? null,
          committeeToDecideTemplate: props.data?.committeeToDecideTemplate ?? null,
          decisionOptionsTemplate: props.data?.decisionOptionsTemplate ?? null
        }}
        onSubmit={async (values) => {
          const input: RegistrationCommitteeItemTypeInput = {
            id: props.data?.id,
            name: values.name,
            sortOrder: values.sortOrder,
            isActive: values.isActive,
            categoryId: values.categoryId!,
            issueTemplate: issueTemplateContentRetriever.current.getContentAsHtml(),
            backgroundTemplate: backgroundTemplateContentRetriever.current.getContentAsHtml(),
            committeeToDecideTemplate: committeeToDecideTemplateContentRetriever.current.getContentAsHtml(),
            decisionOptionsTemplate: decisionOptionsTemplateContentRetriever.current.getContentAsHtml()
          };
          await saveMutate({ variables: { itemType: input } });
          props.onClose();
        }}
        validationSchema={validationSchema}>
        {(formikProps) => (
          <FormikForm>
            <DialogContent>
              <Grid container columnSpacing={2} direction="row">
                <Grid item xs={6}>
                  <FormikField component={FmuiTextField} name="name" label="Name" fullWidth required />
                </Grid>
                <Grid item xs={6} />
                <Grid item xs={6}>
                  <FormikField component={FmuiTextField} select name="categoryId" label="Category" fullWidth required>
                    {props.categories.map((category) => (
                      <MenuItem value={category.id} key={category.id}>
                        {category.name}
                      </MenuItem>
                    ))}
                  </FormikField>
                </Grid>
                <Grid item xs={6} />
                <Grid item xs={6}>
                  <FormikField
                    component={FmuiTextField}
                    type="number"
                    className={classes.noNumberSpinners}
                    name="sortOrder"
                    label="Sort Order"
                    fullWidth
                    required
                    helperText="Sort Order can be any number, with or without a decimal."
                  />
                </Grid>
                <Grid item xs={2} />
                <Grid item xs={4}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formikProps.values.isActive}
                        name="isActive"
                        onChange={(e) => formikProps.setFieldValue("isActive", e.target.checked)}
                      />
                    }
                    label="Active"
                  />
                </Grid>
                <Grid container item rowSpacing={3} className={classes.richTextFields}>
                  <Grid item xs={12}>
                    <FormikField
                      component={RichTextEditor}
                      name="issueTemplate"
                      label="Issue"
                      html={formikProps.values.issueTemplate}
                      passContentRetriever={(getContentAsHtml: any) => {
                        issueTemplateContentRetriever.current = { getContentAsHtml };
                      }}
                      showMergeFields
                      mergeFieldType={MergeFieldType.RegComItem}
                      templateMarkup
                      error={
                        formikProps.errors.issueTemplate && formikProps.touched.issueTemplate ? formikProps.errors.issueTemplate : undefined
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormikField
                      component={RichTextEditor}
                      name="backgroundTemplate"
                      label="Background"
                      html={formikProps.values.backgroundTemplate}
                      passContentRetriever={(getContentAsHtml: any) => {
                        backgroundTemplateContentRetriever.current = { getContentAsHtml };
                      }}
                      showMergeFields
                      mergeFieldType={MergeFieldType.RegComItem}
                      templateMarkup
                      error={
                        formikProps.errors.backgroundTemplate && formikProps.touched.backgroundTemplate
                          ? formikProps.errors.backgroundTemplate
                          : undefined
                      }
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FormikField
                      component={RichTextEditor}
                      name="committeeToDecideTemplate"
                      label="Committee To Decide"
                      html={formikProps.values.committeeToDecideTemplate}
                      passContentRetriever={(getContentAsHtml: any) => {
                        committeeToDecideTemplateContentRetriever.current = { getContentAsHtml };
                      }}
                      showMergeFields
                      mergeFieldType={MergeFieldType.RegComItem}
                      templateMarkup
                      error={
                        formikProps.errors.committeeToDecideTemplate && formikProps.touched.committeeToDecideTemplate
                          ? formikProps.errors.committeeToDecideTemplate
                          : undefined
                      }
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FormikField
                      component={RichTextEditor}
                      name="decisionOptionsTemplate"
                      label="Decision Options"
                      html={formikProps.values.decisionOptionsTemplate}
                      passContentRetriever={(getContentAsHtml: any) => {
                        decisionOptionsTemplateContentRetriever.current = { getContentAsHtml };
                      }}
                      showMergeFields
                      mergeFieldType={MergeFieldType.RegComItem}
                      templateMarkup
                      error={
                        formikProps.errors.decisionOptionsTemplate && formikProps.touched.decisionOptionsTemplate
                          ? formikProps.errors.decisionOptionsTemplate
                          : undefined
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={props.onClose}>Cancel</Button>
              <LoadingButton color="primary" variant="contained" loading={saveMutation.loading} onClick={() => formikProps.submitForm()}>
                {props.confirmButtonText}
              </LoadingButton>
            </DialogActions>
          </FormikForm>
        )}
      </Formik>
    </Dialog>
  );
};

export default EditRegistrationCommitteeItemTypeDialog;
