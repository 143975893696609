import React, { useState } from "react";
import { GenerateChecklistNotesReportMutation, PracticeReview, PRPhaseCode } from "practice-reviews";
import { DocType } from "common/DocType";
import { Typography, DialogContentText } from "@mui/material";
import { ConfirmationDialog } from "common/ConfirmationDialog";
import { useCurrentUser, Permissions } from "../../users";
import { LoadingButton } from "@mui/lab";
import { makeStyles } from "../../makeStyles";
import { documentListStyles } from "styles/common";
import { MicrosoftWordLink } from "../../common/MicrosoftWordLink";
import { useMutation } from "@apollo/client";
import { getOpenableUrl } from "../../util/utilities";
import { showLinkAndActionsGenerateButton } from "practice-reviews/utilities";

const useStyles = makeStyles()((theme) => ({
  ...documentListStyles(theme)
}));

interface Props {
  practiceReview: PracticeReview;
}

export const ChecklistNotesReportLinkAndActions: React.FunctionComponent<Props> = (props) => {
  const { classes } = useStyles();
  const currentUser = useCurrentUser();

  const [confirmingRegenerate, setConfirmingRegenerate] = useState(false);

  const [generateReportMutate, generateReportMutation] = useMutation<
    { practiceReview: { generateReport: PracticeReview } },
    { practiceReviewId: number; documentType: DocType }
  >(GenerateChecklistNotesReportMutation);

  async function generateReport() {
    const result = await generateReportMutate({
      variables: { practiceReviewId: props.practiceReview.id, documentType: DocType.ChecklistNotesReport }
    });

    if (result.data?.practiceReview.generateReport?.checklistNotesReportUrl) {
      window.open(getOpenableUrl(result.data.practiceReview.generateReport.checklistNotesReportUrl!));
    }
  }

  const url = props.practiceReview.checklistNotesReportUrl;

  return (
    <>
      <div className={classes.documentName}>
        {!url ? (
          <Typography variant="body1" className={classes.missingForm}>
            Checklist Notes Report
          </Typography>
        ) : (
          <MicrosoftWordLink href={url}>Checklist Notes Report</MicrosoftWordLink>
        )}
      </div>
      {showLinkAndActionsGenerateButton(props.practiceReview, currentUser, true) && (
        <div className={classes.documentAction}>
          <LoadingButton
            variant="outlined"
            loading={generateReportMutation.loading}
            onClick={
              url
                ? () => setConfirmingRegenerate(true)
                : async () => {
                    await generateReport();
                  }
            }>
            {url ? "Regenerate" : "Generate"}
          </LoadingButton>
        </div>
      )}
      {confirmingRegenerate && (
        <ConfirmationDialog
          open={true}
          body={
            <DialogContentText>
              You have already generated this document. If you regenerate it, any changes you may have made will be lost. Regenerate anyway?
            </DialogContentText>
          }
          title="Regenerate report?"
          noDanger
          loading={generateReportMutation.loading}
          cancel={() => setConfirmingRegenerate(false)}
          confirm={async () => {
            await generateReport();
            setConfirmingRegenerate(false);
          }}
        />
      )}
    </>
  );
};
