import React from "react";
import { makeStyles } from "makeStyles";
import { datagridStyles, inaPanelStyles, tableStyles } from "styles/common";
import { Ina } from "inas";
import { GridColDef } from "@mui/x-data-grid/models";
import { InaPanel } from "./InaPanel";
import { dataGridDateValueFormatter } from "util/formats";
import { GridRowId } from "@mui/x-data-grid-pro";
import { Link, Tooltip } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

const useStyles = makeStyles<Props>()((theme) => ({
  ...datagridStyles(theme),
  ...tableStyles(theme),
  ...inaPanelStyles(theme)
}));

interface Props {
  title: string;
  inas: Ina[];
  getNavigationRoute?: (ina: Ina) => string;
  getFixedNavigationRoute?: () => string;
  className?: string;
  completable?: boolean;
  getCompletionBlockingReason?: (ina: Ina) => string | null;
  extraColumns?: GridColDef[];
  headerActions?: (selectedInas: Ina[]) => React.ReactElement;
  extraActions?: (ina: Ina) => React.ReactElement;
  extraActionsWidth?: number;
  getRowDetails?: (ina: Ina) => React.ReactElement;
  getDetailPanelHeight?: (ina: Ina) => number;
  navigateTo?: (event: React.MouseEvent<HTMLElement>, ina: Ina, id: GridRowId, location: string) => void;
  showCommitteeMeeting?: boolean;
}

export const RegistrationCommitteeItemInaPanel: React.FunctionComponent<Props> = (props) => {
  const { classes } = useStyles(props);

  const columns: GridColDef<Ina>[] = [
    {
      field: "entityNumber",
      headerName: "Entity Number",
      headerClassName: classes.wrapHeader,
      width: 90,
      valueGetter: ({ row }) => row.registrationCommitteeItem?.entityNumber ?? "--"
    },
    {
      field: "registeredName",
      headerName: "Registered Name",
      headerClassName: classes.wrapHeader,
      flex: 1,
      valueGetter: ({ row }) =>
        !!props.getNavigationRoute ? (
          <Tooltip title={row.registrationCommitteeItem!.registeredName}>
            <Link to={props.getNavigationRoute(row)} component={RouterLink}>
              {row.registrationCommitteeItem?.registeredName ?? "--"}
            </Link>
          </Tooltip>
        ) : (
          row.registrationCommitteeItem?.registeredName ?? "--"
        )
    },
    {
      field: "categoryName",
      headerName: "Category",
      headerClassName: classes.wrapHeader,
      flex: 1,
      valueGetter: ({ row }) => row.registrationCommitteeItem?.itemType.category.name ?? "--"
    },
    {
      field: "itemTypeName",
      headerName: "Type",
      headerClassName: classes.wrapHeader,
      flex: 1,
      valueGetter: ({ row }) => row.registrationCommitteeItem?.itemType.name ?? "--"
    },
    {
      field: "createdDate",
      headerName: "Created On",
      headerClassName: classes.wrapHeader,
      type: "date",
      width: 100,
      valueGetter: ({ row }) => row.registrationCommitteeItem?.createdDate,
      valueFormatter: dataGridDateValueFormatter
    },
    {
      field: "committeeMeeting",
      headerName: "Committee Meeting",
      headerClassName: classes.wrapHeader,
      flex: 1,
      valueGetter: ({ row }) => row.registrationCommitteeItem?.committeeMeeting?.name ?? "--",
      hide: !props.showCommitteeMeeting
    }
  ];

  columns.push(...(props.extraColumns ?? []));

  return (
    <>
      <InaPanel
        columns={columns}
        title={props.title}
        inas={props.inas}
        getNavigationRoute={props.getNavigationRoute}
        getFixedNavigationRoute={props.getFixedNavigationRoute}
        className={props.className}
        completable={props.completable}
        getCompletionBlockingReason={props.getCompletionBlockingReason}
        headerActions={props.headerActions}
        getRowDetails={props.getRowDetails}
        getDetailPanelHeight={props.getDetailPanelHeight}
        navigateTo={props.navigateTo}
        defaultSortModel={[
          {
            field: "createdDate",
            sort: "asc"
          }
        ]}
        itemType="Registration Committee Item"
      />
    </>
  );
};
