import { PracticeReview } from "../practice-reviews";
import { CommitteeMember } from "../committee-members";
import { RegistrationCommitteeItem } from "registration-committee-items";

export interface CommitteeMeeting {
  id: number;
  meetingDate: string;
  location: string | null;
  name: string;
  isRegistrationCommittee: boolean;
  reviewQuota: number;
  status: string;

  recordingSecretary: string | null;
  observer1: string | null;
  observer2: string | null;
  observer3: string | null;
  observer4: string | null;

  subcommitteeDate: string | null;
  subcommitteeMeetings: SubcommitteeMeeting[];

  isOpen: boolean;
  hasBeenClosed: boolean;
  binderGenerated: boolean;
  decisionLettersGenerated: boolean;
  minutesGenerated: boolean;
  minutesUrl: string | null;

  assignedReviews: PracticeReview[];
  tabledReviews: CommitteeMeetingTabledReview[];
  assignedRegistrationCommitteeItems: RegistrationCommitteeItem[];

  sharePointUrl: string;

  assembledBinders?: { fileName: string }[];
}

export interface SubcommitteeMeeting {
  id: number;
  time: string;
  members: CommitteeMember[];
}

export interface CommitteeMeetingTabledReview {
  committeeMeetingId: number;
  practiceReviewId: number;
  tab: string | null;
  reason: string | null;
  isTabledLetterReviewedByCoordinator: boolean;
  isTabledLetterReviewedByDirector: boolean;
  decisionTabledLetterHasBeenSent: boolean;
  practiceReview: PracticeReview;
  committeeMeeting: CommitteeMeeting;
}

export interface CommitteeMeetingInput {
  id?: number;
  meetingDate: string;
  location: string;
  isRegistrationCommittee: boolean;
  reviewQuota: number;
  isOpen?: boolean;

  recordingSecretary?: string;
  observer1?: string;
  observer2?: string;
  observer3?: string;
  observer4?: string;

  subcommitteeDate: string | null;
  subcommitteeMeetings?: { time: string; memberIds: number[] }[];
}

export interface JobProgress {
  id: number;
  stepsCompleted: number;
  totalSteps: number;
  stepsNotStarted: number;
  stepsRunning: number;
  stepsWithErrors: number;
  allStepsComplete: boolean;
  progressMessage: string;
  errorMessages?: string;
  resultData?: string;
  lastUpdated: string;
}

export interface BinderJob {
  id: number;
  committeeMeetingId?: number;
  isComplete: boolean;
  isDismissed: boolean;
  dateCreated: string;
  dateCompleted: string;
  queueJobTypeCode: QueueJobTypeCode;
  progress: JobProgress;
  committeeMeeting: CommitteeMeeting;
}

export enum QueueJobTypeCode {
  GenerateBinder = "GenerateBinder",
  AssembleBinder = "AssembleBinder"
}

export enum PastPeriod {
  OneYear = 365,
  SixMonths = 182,
  AllTime = 0
}
