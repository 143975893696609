import { FC } from "react";
import { Stack, StackProps, MenuItem } from "@mui/material";
import { Field as FormikField } from "formik";
import { TextField as FmuiTextField } from "formik-mui";
import { FrequencyOptions } from "./models";
import StackedStaticDataDisplay from "common/StackedStaticDataDisplay";
import { DateTime } from "luxon";
import { formatDate } from "util/formats";
import { makeStyles } from "makeStyles";
import { NumberedTextFieldEndAdornment } from "common/NumberedTextfield";
const useStyles = makeStyles()({
  stackItemWidth: {
    width: "25%",
    margin: 0
  },
  dateWidth: {
    width: "16em"
  }
});

export interface MonitoringHeaderStackProps extends Omit<StackProps, "onChange"> {
  monitoringFrequencyInDays: number | null;
  monitoringOccurrencesRemaining: number | null;
  currentMonitoringPeriodDate?: string;
  monitoringCompleted: boolean;
  monitoringCompletedOnDate?: string | null;
  createdDate: string | null;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}

const MAX_FREQUENCY_INPUT = 10;

export const MonitoringHeaderStack: FC<MonitoringHeaderStackProps> = ({
  monitoringFrequencyInDays,
  monitoringOccurrencesRemaining,
  currentMonitoringPeriodDate,
  monitoringCompleted,
  monitoringCompletedOnDate,
  createdDate,
  setFieldValue,
  ...stackProps
}) => {
  const { classes } = useStyles();

  const endDate =
    currentMonitoringPeriodDate && monitoringFrequencyInDays !== null && monitoringOccurrencesRemaining !== null
      ? DateTime.fromISO(currentMonitoringPeriodDate).plus({ days: monitoringFrequencyInDays * monitoringOccurrencesRemaining })
      : null;

  return (
    <Stack {...stackProps}>
      <StackedStaticDataDisplay label="Monitoring Started On" value={formatDate(createdDate)} className={classes.stackItemWidth} />
      {monitoringCompleted ? (
        <>
          <StackedStaticDataDisplay label="Monitoring Completed On" value={formatDate(monitoringCompletedOnDate ?? null)} />
        </>
      ) : (
        <>
          <FormikField component={FmuiTextField} name="monitoringFrequencyInDays" label="Frequency" select fullWidth>
            {Object.keys(FrequencyOptions)
              .filter((key) => isNaN(Number(key)))
              .map((key) => (
                <MenuItem key={key} value={FrequencyOptions[key as keyof typeof FrequencyOptions]}>
                  {key}
                </MenuItem>
              ))}
          </FormikField>
          <FormikField
            component={FmuiTextField}
            name="monitoringOccurrencesRemaining"
            label="Occurrences Remaining"
            InputProps={{
              endAdornment: (
                <NumberedTextFieldEndAdornment
                  value={monitoringOccurrencesRemaining ?? 0}
                  onChange={(newValue) => setFieldValue("monitoringOccurrencesRemaining", newValue)}
                  min={0}
                  max={MAX_FREQUENCY_INPUT}
                />
              )
            }}
            className={classes.stackItemWidth}
            InputLabelProps={{ shrink: true }}
          />
          <StackedStaticDataDisplay
            label="Tentative Monitoring End Date"
            value={formatDate(endDate?.toISO() ?? null)}
            className={classes.stackItemWidth}
          />
        </>
      )}
    </Stack>
  );
};

export default MonitoringHeaderStack;
