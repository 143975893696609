import { Button, Link, Stack, Typography } from "@mui/material";
import { AttachedDocument } from "practice-reviews";
import { DocType } from "./DocType";
import React from "react";
import { getOpenableUrl } from "util/utilities";

interface DocumentLinkProps {
  document?: AttachedDocument;
  documentName: string;
  removingDocument: boolean;
}

interface ReplaceableDocumentLinkProps {
  documents?: AttachedDocument[];
  documentName: string;
  documentType: DocType;
  documentToAttach?: File;
  documentsToAttach?: { file: File; type: DocType }[];
  attachDocument?: (file: File) => void;
  setDocumentsToAttach?: (
    value: React.SetStateAction<
      {
        file: File;
        type: DocType;
      }[]
    >
  ) => void;
  clearAttachedDocument?: () => void;
  removingDocument?: boolean;
  documentsToRemove?: AttachedDocument[];
  removeDocument: () => void;
  acceptableFileTypes?: string;
  error?: string;
  disabled?: boolean;
}

export const DocumentLink: React.FunctionComponent<DocumentLinkProps> = (props) =>
  props.document && !props.removingDocument ? (
    <Link href={getOpenableUrl(props.document.url)} target="_blank">
      <Typography variant="body1" sx={{ whiteSpace: "nowrap" }}>
        {props.documentName}
      </Typography>
    </Link>
  ) : (
    <Typography variant="body1" sx={{ whiteSpace: "nowrap" }}>
      {props.documentName}
    </Typography>
  );

const ReplaceableDocumentLink: React.FunctionComponent<ReplaceableDocumentLinkProps> = (props) => {
  const document = props.documents?.filter((d) => d.type === props.documentType)[0];
  const documentToAttach = props.documentToAttach ?? props.documentsToAttach?.filter((d) => d.type === props.documentType)[0]?.file;
  const removingDocument =
    props.removingDocument ??
    (props.documentsToRemove ? props.documentsToRemove.findIndex((d) => d.type === props.documentType) !== -1 : false);

  const attachDocument = (e: React.ChangeEvent<HTMLInputElement>) =>
    props.setDocumentsToAttach
      ? props.setDocumentsToAttach((files) => [...files, { file: e.target.files![0], type: props.documentType }])
      : props.attachDocument?.(e.target.files![0]);
  const clearAttachedDocument =
    props.clearAttachedDocument ?? (() => props.setDocumentsToAttach?.((files) => files.filter((f) => f.type !== props.documentType)));

  return (
    <Stack direction="row" spacing={2} alignItems="center">
      <DocumentLink document={document} documentName={props.documentName} removingDocument={removingDocument} />
      {!document ? (
        !documentToAttach ? (
          <Stack direction="row" alignItems="center" gap={2}>
            <Button variant="outlined" size="small" component="label" disabled={!!props.disabled}>
              Attach
              <input type="file" hidden accept={props.acceptableFileTypes ?? "*/*"} onChange={attachDocument} />
            </Button>
            {props.error && (
              <Typography variant="body2" color="red">
                {props.error}
              </Typography>
            )}
          </Stack>
        ) : (
          <Stack direction="row" gap={2}>
            <Stack>
              <Typography variant="body2">Attaching:</Typography>
              <Typography variant="body2">{documentToAttach.name}</Typography>
            </Stack>
            <Button
              variant="outlined"
              size="small"
              color="error"
              onClick={clearAttachedDocument}
              disabled={!!props.disabled}
              className="removeButton">
              Clear
            </Button>
          </Stack>
        )
      ) : removingDocument ? (
        <Stack>
          {!documentToAttach ? (
            <Stack direction="row" alignItems="center" gap={2}>
              <Stack>
                <Typography variant="body2">Removing:</Typography>
                <Typography variant="body2">{document.fileName}</Typography>
              </Stack>
              <Button variant="outlined" size="small" component="label" disabled={!!props.disabled}>
                Attach
                <input type="file" hidden accept={props.acceptableFileTypes ?? "*/*"} onChange={attachDocument} />
              </Button>
            </Stack>
          ) : (
            <Stack direction="row" alignItems="center" gap={2}>
              <Stack>
                <Typography variant="body2">Attaching:</Typography>
                <Typography variant="body2">{documentToAttach.name}</Typography>
              </Stack>

              <Button
                variant="outlined"
                size="small"
                color="error"
                onClick={props.removeDocument}
                disabled={!!props.disabled}
                className="removeButton">
                Clear
              </Button>
            </Stack>
          )}
        </Stack>
      ) : (
        <Button
          variant="outlined"
          size="small"
          color="error"
          onClick={props.removeDocument}
          disabled={!!props.disabled}
          className="removeButton">
          Clear
        </Button>
      )}
    </Stack>
  );
};

export default ReplaceableDocumentLink;
