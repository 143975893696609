import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { SecureRoute } from "auth/SecureRoute";
import { AuthCompletedScreen } from "auth/AuthCompletedScreen";
import { InaScreen } from "inas/InaScreen";
import { PracticeReviewScreen } from "practice-reviews/PracticeReviewScreen";
import { UpcomingReviewNoticesScreen } from "scheduling/UpcomingReviewNoticesScreen";
import { ImportPRs } from "practice-reviews/ImportPrs";
import StatHolidaysScreen from "stat-holidays/StatHolidaysScreen";
import SystemEstimatedTimeRulesScreen from "./system-time-estimates/SystemEstimatedTimeRulesScreen";
import CustomReportsScreen from "custom-reports/CustomReportsScreen";
import TimedEventMarginsScreen from "timed-event-margins/TimedEventMarginsScreen";
import AdjustmentQuestionTemplatesScreen from "adjustment-question-templates/AdjustmentQuestionTemplatesScreen";
import EmailTemplatesScreen from "./email-templates/EmailTemplatesScreen";
import SignOffChecklistItemTemplatesScreen from "sign-off-checklist-items/SignOffChecklistItemTemplatesScreen";
import TimeConversionFormulasScreen from "time-conversion-formulas/TimeConversionFormulasScreen";
import RetentionPoliciesScreen from "retention-policies/RetentionPoliciesScreen";
import PdCoursesScreen from "./pd-courses/PdCoursesScreen";
import WorkflowInasScreen from "workflow-inas/WorkflowInasScreen";
import EngagementTypeTemplateScreen from "./engagement-type-templates/EngagementTypeTemplatesScreen";
import AdvancedPrSearchScreen from "./practice-reviews/AdvancedPrSearchScreen";
import { MasterChecklistScreen } from "master-checklist/MasterChecklistScreen";
import PersonalActivityLogScreen from "personal-activity-log/PersonalActivityLogScreen";
import CommitteeMeetingsScreen from "committee-meetings/CommitteeMeetingsScreen";
import CommitteeMeetingScreen from "committee-meetings/CommitteeMeetingScreen";
import CommitteeMembersScreen from "committee-members/CommitteeMembersScreen";
import AppRolesScreen from "app-roles/AppRolesScreen";
import { Permissions } from "users";
import MotionsScreen from "./motions/MotionsScreen";
import CommitteeDecisionsScreen from "./committee-meetings/CommitteeDecisionsScreen";
import ManageUsersScreen from "users/ManageUsersScreen";
import ExemptionLettersScreen from "exemptions/ExemptionLettersScreen";
import ActiveExemptionsScreen from "exemptions/ActiveExemptionsScreen";
import DecisionAdditionalParagraphsScreen from "decisions/DecisionAdditionalParagraphsScreen";
import DocumentTemplateSandboxScreen from "document-template-sandbox/DocumentTemplateSandboxScreen";
import PprpProgramsScreen from "pprp/PprpProgramsScreen";
import PprpProgramTasksScreen from "pprp/PprpProgramTasksScreen";
import RegistrationCommitteeItemScreen from "registration-committee-items/RegistrationCommitteeItemScreen";
import RegistrationCommitteeItemTypesScreen from "registration-committee-items/RegistrationCommitteeItemTypesScreen";

export enum AppRoute {
  INAs = "/",
  PracticeReview = "/practice-review/:prNumber/:tab?",
  PracticeReviewClientChecklists = "/practice-review/:prNumber/client-checklists/:reviewedClientId?",
  PracticeReviewCandidateChecklists = "/practice-review/:prNumber/candidate-checklists/:reviewedClientId?",
  PracticeReviewDecision = "/practice-review/:prNumber/decision/:decisionTab?",
  GenerateUpcomingPafPracticeReviewNotices = "/generate-upcoming-review-notices",
  ApproveUpcomingPafPracticeReviewNotices = "/approve-upcoming-review-notices",
  GenerateUpcomingPprpProgramReviewNotices = "/generate-upcoming-program-review-notices",
  ApproveUpcomingPprpProgramReviewNotices = "/approve-upcoming-program-review-notices",
  PRCommitteeMeetings = "/committee-meetings",
  RegistrationCommitteeMeetings = "/registration-committee-meetings",
  PRCommitteeMeeting = "/committee-meetings/:id",
  RegistrationCommitteeMeeting = "/registration-committee-meetings/:id",
  PrcCommitteeMembers = "/prc-committee-members",
  RegistrationCommitteeMembers = "/registration-committee-members",
  AppRoles = "/app-roles",
  ImportPrs = "/import-prs",
  StatHolidays = "/stat-holidays",
  CustomReports = "/custom-reports",
  TimedEventMargins = "/timed-event-margins",
  Users = "/users",
  AdjustmentQuestionTemplates = "/adjustment-question-templates",
  EmailTemplates = "/email-templates",
  SignOffChecklistItems = "/sign-off-checklist-items",
  TimeConversionFormulas = "/time-conversion-formulas",
  RetentionPolicy = "/retention-policies",
  RetentionPolicies = "/retention-policies",
  PdCourses = "/pd-courses",
  Motions = "/motions",
  DecisionAdditionalPafParagraphs = "/additional-paragraphs-for-decisions",
  DecisionParagraphsForRegCom = "/decision-paragraphs-for-reg-com",
  WorkflowINAs = "/workflow-inas",
  ThemeTests = "/theme-tests",
  SystemEstimatedTimeRules = "/system-estimated-time-rules",
  AuthCompleted = "/authCompleted",
  EngagementTypeTemplates = "/engagement-type-templates",
  AdvancedPrSearch = "/advanced-pr-search",
  MasterChecklist = "/master-checklist",
  MyActivityLog = "/my-activity-log",
  EnterPafPracticeReviewCommitteeDecisions = "/enter-practice-review-committee-decisions",
  ApproveFinalPafPracticeReviewReports = "/approve-final-practice-review-reports",
  EnterPprpProgramReviewCommitteeDecisions = "/enter-program-review-committee-decisions", // TODO: if we use this screen for new Reg Com Committee Items, change this URL?
  ApproveFinalPprpProgramReviewReports = "/approve-final-program-review-reports", // TODO: if we use this screen for new Reg Com Committee Items, change this URL?
  ReviewPafPracticeExemptionLetters = "/review-exemption-letters",
  ApprovePafPracticeExemptionLetters = "/approve-exemption-letters",
  ReviewPprpProgramExemptionLetters = "/review-program-exemption-letters",
  ApprovePprpProgramExemptionLetters = "/approve-program-exemption-letters",
  Exemptions = "/active-exemptions",
  DocumentTemplateSandbox = "/document-template-sandbox",
  PprpPrograms = "/programs",
  PprpProgramTasks = "/program-tasks",
  RegistrationCommitteeItem = "/registration-committee-item/:id/:tab?",
  AddRegistrationCommitteeItem = "/registration-committee-item",
  RegistrationCommitteeItemDecision = "/registration-committee-item/:id/decision",
  RegistrationCommitteeItemTypes = "/registration-committee-item-types"
}

export const Routes: React.FunctionComponent = () => {
  return (
    <Switch>
      <Route exact path={AppRoute.AuthCompleted} component={AuthCompletedScreen} />
      <SecureRoute exact path={AppRoute.INAs} component={InaScreen} />
      <SecureRoute
        exact
        path={[
          AppRoute.PracticeReviewClientChecklists,
          AppRoute.PracticeReviewCandidateChecklists,
          AppRoute.PracticeReviewDecision,
          AppRoute.PracticeReview
        ]}
        component={PracticeReviewScreen}
      />
      <SecureRoute exact path={AppRoute.AdvancedPrSearch} component={AdvancedPrSearchScreen} />
      <SecureRoute exact path={AppRoute.MyActivityLog} component={PersonalActivityLogScreen} />

      <SecureRoute
        exact
        path={AppRoute.ReviewPafPracticeExemptionLetters}
        component={() => <ExemptionLettersScreen mode="review" />}
        authorizedPermissions={[Permissions.ExemptionReviewLetter]}
      />
      <SecureRoute
        exact
        path={AppRoute.ApprovePafPracticeExemptionLetters}
        component={() => <ExemptionLettersScreen mode="approve" />}
        authorizedPermissions={[Permissions.ExemptionConfirm]}
      />

      <SecureRoute
        exact
        path={AppRoute.GenerateUpcomingPafPracticeReviewNotices}
        component={() => <UpcomingReviewNoticesScreen mode="generate" />}
        authorizedPermissions={[Permissions.AdminTasks]}
      />
      <SecureRoute
        exact
        path={AppRoute.ApproveUpcomingPafPracticeReviewNotices}
        component={() => <UpcomingReviewNoticesScreen mode="approve" />}
        authorizedPermissions={[Permissions.AdminTasks]}
      />

      <SecureRoute
        exact
        path={AppRoute.PRCommitteeMeetings}
        component={CommitteeMeetingsScreen}
        authorizedPermissions={[Permissions.AdminTasks]}
      />
      <SecureRoute
        exact
        path={AppRoute.RegistrationCommitteeMeetings}
        component={() => <CommitteeMeetingsScreen registrationCommittees />}
        authorizedPermissions={[Permissions.AdminTasksPprp]}
      />
      <SecureRoute
        exact
        path={AppRoute.PRCommitteeMeeting}
        component={CommitteeMeetingScreen}
        authorizedPermissions={[Permissions.AdminTasks]}
      />
      <SecureRoute
        exact
        path={AppRoute.RegistrationCommitteeMeeting}
        component={CommitteeMeetingScreen}
        authorizedPermissions={[Permissions.AdminTasksPprp]}
      />
      <SecureRoute
        exact
        path={AppRoute.EnterPafPracticeReviewCommitteeDecisions}
        component={() => <CommitteeDecisionsScreen mode="enter-committee-decisions" />}
        authorizedPermissions={[Permissions.AdminTasks]}
      />
      <SecureRoute
        exact
        path={AppRoute.ApproveFinalPafPracticeReviewReports}
        component={() => <CommitteeDecisionsScreen mode="review-final-reports" />}
        authorizedPermissions={[Permissions.AdminTasks]}
      />
      <SecureRoute
        exact
        path={AppRoute.PrcCommitteeMembers}
        component={CommitteeMembersScreen}
        authorizedPermissions={[Permissions.AdminTasks]}
      />
      <SecureRoute exact path={AppRoute.AppRoles} component={AppRolesScreen} authorizedPermissions={[Permissions.AdminTasks]} />
      <SecureRoute exact path={AppRoute.ImportPrs} component={ImportPRs} authorizedPermissions={[Permissions.AdminTasks]} />
      <SecureRoute exact path={AppRoute.StatHolidays} component={StatHolidaysScreen} authorizedPermissions={[Permissions.AdminTasks]} />
      <SecureRoute
        exact
        path={AppRoute.SystemEstimatedTimeRules}
        component={SystemEstimatedTimeRulesScreen}
        authorizedPermissions={[Permissions.AdminTasks]}
      />
      <SecureRoute exact path={AppRoute.CustomReports} component={CustomReportsScreen} authorizedPermissions={[Permissions.AdminTasks]} />
      <SecureRoute
        exact
        path={AppRoute.TimedEventMargins}
        component={TimedEventMarginsScreen}
        authorizedPermissions={[Permissions.AdminTasks]}
      />
      <SecureRoute
        exact
        path={AppRoute.AdjustmentQuestionTemplates}
        component={AdjustmentQuestionTemplatesScreen}
        authorizedPermissions={[Permissions.AdminTasks]}
      />
      <SecureRoute exact path={AppRoute.EmailTemplates} component={EmailTemplatesScreen} authorizedPermissions={[Permissions.AdminTasks]} />
      <SecureRoute
        exact
        path={AppRoute.SignOffChecklistItems}
        component={SignOffChecklistItemTemplatesScreen}
        authorizedPermissions={[Permissions.AdminTasks]}
      />
      <SecureRoute
        exact
        path={AppRoute.TimeConversionFormulas}
        component={TimeConversionFormulasScreen}
        authorizedPermissions={[Permissions.AdminTasks]}
      />
      <SecureRoute
        exact
        path={AppRoute.RetentionPolicies}
        component={RetentionPoliciesScreen}
        authorizedPermissions={[Permissions.AdminTasks]}
      />
      <SecureRoute exact path={AppRoute.PdCourses} component={PdCoursesScreen} authorizedPermissions={[Permissions.AdminTasks]} />
      <SecureRoute exact path={AppRoute.Motions} component={MotionsScreen} authorizedPermissions={[Permissions.AdminTasks]} />
      <SecureRoute
        exact
        path={AppRoute.DecisionAdditionalPafParagraphs}
        component={DecisionAdditionalParagraphsScreen}
        authorizedPermissions={[Permissions.AdminTasks]}
      />
      <SecureRoute exact path={AppRoute.WorkflowINAs} component={WorkflowInasScreen} authorizedPermissions={[Permissions.AdminTasks]} />
      <SecureRoute
        exact
        path={AppRoute.EngagementTypeTemplates}
        component={EngagementTypeTemplateScreen}
        authorizedPermissions={[Permissions.AdminTasks]}
      />
      <SecureRoute
        exact
        path={AppRoute.MasterChecklist}
        component={MasterChecklistScreen}
        authorizedPermissions={[Permissions.AdminTasks]}
      />
      <SecureRoute exact path={AppRoute.Users} component={ManageUsersScreen} authorizedPermissions={[Permissions.AdminTasks]} />

      <SecureRoute exact path={AppRoute.Exemptions} component={ActiveExemptionsScreen} authorizedPermissions={[Permissions.AdminTasks]} />

      <SecureRoute
        exact
        path={AppRoute.GenerateUpcomingPprpProgramReviewNotices}
        component={() => <UpcomingReviewNoticesScreen mode="generate" pprpProgramReviews />}
        authorizedPermissions={[Permissions.AdminTasksPprp]}
      />
      <SecureRoute
        exact
        path={AppRoute.ApproveUpcomingPprpProgramReviewNotices}
        component={() => <UpcomingReviewNoticesScreen mode="approve" pprpProgramReviews />}
        authorizedPermissions={[Permissions.AdminTasksPprp]}
      />
      <SecureRoute
        exact
        path={AppRoute.ReviewPprpProgramExemptionLetters}
        component={() => <ExemptionLettersScreen mode="review" onPprpProgramReviews />}
        authorizedPermissions={[Permissions.ExemptionReviewLetter]}
      />
      <SecureRoute
        exact
        path={AppRoute.ApprovePprpProgramExemptionLetters}
        component={() => <ExemptionLettersScreen mode="approve" onPprpProgramReviews />}
        authorizedPermissions={[Permissions.ExemptionConfirm]}
      />
      <SecureRoute
        exact
        path={AppRoute.EnterPprpProgramReviewCommitteeDecisions}
        component={() => <CommitteeDecisionsScreen mode="enter-committee-decisions" registrationCommittees />}
        authorizedPermissions={[Permissions.AdminTasksPprp]}
      />
      <SecureRoute
        exact
        path={AppRoute.ApproveFinalPprpProgramReviewReports}
        component={() => <CommitteeDecisionsScreen mode="review-final-reports" registrationCommittees />}
        authorizedPermissions={[
          Permissions.AdminTasksPprp,
          Permissions.RegComItemDecisionLetterRegistrarReview,
          Permissions.RegComItemDecisionLetterChairReview
        ]}
      />
      <SecureRoute
        exact
        path={AppRoute.RegistrationCommitteeMembers}
        component={() => <CommitteeMembersScreen registrationCommittee />}
        authorizedPermissions={[Permissions.AdminTasksPprp]}
      />
      <SecureRoute
        exact
        path={AppRoute.DecisionParagraphsForRegCom}
        component={() => <DecisionAdditionalParagraphsScreen forRegistrationCommitteeItems />}
        authorizedPermissions={[Permissions.AdminTasksPprp]}
      />
      <SecureRoute exact path={AppRoute.PprpPrograms} component={PprpProgramsScreen} authorizedPermissions={[Permissions.AdminTasksPprp]} />
      <SecureRoute
        exact
        path={AppRoute.PprpProgramTasks}
        component={PprpProgramTasksScreen}
        authorizedPermissions={[Permissions.AdminTasksPprp]}
      />

      <SecureRoute
        exact
        path={[AppRoute.RegistrationCommitteeItem, AppRoute.AddRegistrationCommitteeItem, AppRoute.RegistrationCommitteeItemDecision]}
        component={RegistrationCommitteeItemScreen}
      />

      <SecureRoute exact path={AppRoute.RegistrationCommitteeItemTypes} component={RegistrationCommitteeItemTypesScreen} />

      <SecureRoute
        exact
        path={AppRoute.DocumentTemplateSandbox}
        component={DocumentTemplateSandboxScreen}
        authorizedPermissions={[Permissions.DocumentTemplateSandboxAllow]}
      />

      <Redirect to={AppRoute.INAs} />
    </Switch>
  );
};
