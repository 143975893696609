import React, { useState } from "react";
import { GeneratePrReportMutation, PracticeReview } from "practice-reviews";
import { DocType } from "common/DocType";
import { Typography, Tooltip, DialogContentText } from "@mui/material";
import { ConfirmationDialog } from "common/ConfirmationDialog";
import { DecisionTypeCode } from "../../decisions";
import { useCurrentUser } from "../../users";
import { LoadingButton } from "@mui/lab";
import { makeStyles } from "../../makeStyles";
import { documentListStyles } from "styles/common";
import { MicrosoftWordLink } from "../../common/MicrosoftWordLink";
import { useMutation } from "@apollo/client";
import { getOpenableUrl } from "../../util/utilities";
import { showLinkAndActionsGenerateButton } from "practice-reviews/utilities";

interface Props {
  practiceReview: PracticeReview;
  buttonSize?: "small" | "medium" | "large";
  documentClass?: string;
  actionClass?: string;
  useMissingStyling?: boolean;
}

export const PrReportLinkAndActions: React.FunctionComponent<Props> = (props) => {
  const useStyles = makeStyles()((theme) => ({
    ...documentListStyles(theme)
  }));

  const { classes, cx } = useStyles();
  const currentUser = useCurrentUser();
  const url = props.practiceReview.practiceReviewReportUrl;
  const reviewerDecisionIsComplete = props.practiceReview.decisions.filter((d) => d.decisionType.typeCode === DecisionTypeCode.Reviewer)[0]
    ?.isComplete;
  const [confirmingRegeneratePrReport, setConfirmingRegeneratePrReport] = useState(false);

  const [generateReportMutate, generateReportMutation] = useMutation<
    { practiceReview: { generateReport: PracticeReview } },
    { practiceReviewId: number; documentType: DocType }
  >(GeneratePrReportMutation);

  async function generateReport() {
    const result = await generateReportMutate({
      variables: { practiceReviewId: props.practiceReview.id, documentType: DocType.PracticeReviewReport }
    });

    if (result.data?.practiceReview.generateReport.practiceReviewReportUrl) {
      window.open(getOpenableUrl(result.data.practiceReview.generateReport.practiceReviewReportUrl!));
    }
  }

  return (
    <>
      <div className={cx(classes.documentName, props.documentClass)}>
        {!url ? (
          <Typography variant="body1" className={cx({ [classes.missingForm]: props.useMissingStyling })}>
            {props.practiceReview.isPprpProgramReview ? "Program" : "Practice"} Review Report
          </Typography>
        ) : (
          <MicrosoftWordLink href={url}>
            {props.practiceReview.isPprpProgramReview ? "Program" : "Practice"} Review Report
          </MicrosoftWordLink>
        )}
      </div>
      {showLinkAndActionsGenerateButton(props.practiceReview, currentUser, true) && (
        <div className={cx(classes.documentAction, props.actionClass)}>
          <Tooltip title={!reviewerDecisionIsComplete ? "Complete the reviewer decision before generating this report." : ""}>
            <span>
              <LoadingButton
                variant="outlined"
                loading={generateReportMutation.loading}
                disabled={!reviewerDecisionIsComplete}
                size={props.buttonSize}
                onClick={
                  url
                    ? () => setConfirmingRegeneratePrReport(true)
                    : async () => {
                        await generateReport();
                      }
                }>
                {url ? "Regenerate" : "Generate"}
              </LoadingButton>
            </span>
          </Tooltip>
        </div>
      )}
      {confirmingRegeneratePrReport && (
        <ConfirmationDialog
          open={true}
          body={
            <DialogContentText>
              You have already generated this document. If you regenerate it, any changes you may have made will be lost. Regenerate anyway?
            </DialogContentText>
          }
          title="Regenerate report?"
          loading={generateReportMutation.loading}
          cancel={() => setConfirmingRegeneratePrReport(false)}
          confirm={async () => {
            await generateReport();
            setConfirmingRegeneratePrReport(false);
          }}
        />
      )}
    </>
  );
};
