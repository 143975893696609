import { useMutation } from "@apollo/client";
import { ConfirmationDialog } from "../common/ConfirmationDialog";
import { useNotifications } from "../notifications";
import { Alert, DialogContentText, Stack } from "@mui/material";
import { BinderJob, CommitteeMeeting } from "./models";
import { FetchBinderJobByMeetingIdQuery, FetchBinderJobsQuery, GenerateBinderMutation } from ".";

interface Props {
  onClose: () => void;
  committeeMeeting: CommitteeMeeting;
}

const ConfirmGenerateBinderDocumentsDialog = (props: Props) => {
  const notifications = useNotifications();
  const [generateMutate, generateMutation] = useMutation<{ committeeMeeting: { generateBinder: BinderJob } }, { meetingId: number }>(
    GenerateBinderMutation,
    {
      refetchQueries: [
        { query: FetchBinderJobsQuery },
        { query: FetchBinderJobByMeetingIdQuery, variables: { meetingId: props.committeeMeeting.id } }
      ]
    }
  );

  const autoGeneratedDocumentsList = props.committeeMeeting.isRegistrationCommittee
    ? ["Meeting Agenda"]
    : ["Meeting Agenda", "Office Listing", "Standard Motions Summary"];
  const reviewTitlePageDocumentsName = props.committeeMeeting.isRegistrationCommittee
    ? "Program Review Summary Data Sheets"
    : "Presentation Format documents";
  return (
    <>
      <ConfirmationDialog
        noDanger
        open={true}
        title={`${props.committeeMeeting.binderGenerated ? "Regenerate" : "Generate"} Binder Documents${
          props.committeeMeeting.isRegistrationCommittee ? "" : " and Tabs"
        }`}
        body={
          props.committeeMeeting.binderGenerated ? (
            <Stack spacing={2}>
              <Alert color="warning">Caution: the documents for this meeting binder have already been generated.</Alert>
              <DialogContentText>
                This action will {!props.committeeMeeting.isRegistrationCommittee && "reset the tab numbers and"} regenerate the following
                documents:
                <ul>
                  {autoGeneratedDocumentsList.map((f) => (
                    <li>{f}</li>
                  ))}
                  <li>{reviewTitlePageDocumentsName}</li>
                </ul>
                Any manual changes that have been made directly (i.e. using Word) to these documents since the binder documents were last
                generated will no longer be the "latest" version of the documents, and so those changes will not appear in the assembled
                binder.
              </DialogContentText>
              {!props.committeeMeeting.isRegistrationCommittee && (
                <DialogContentText>
                  You should only proceed if zones or motions have changed, impacting the tab numbers/sort order.
                </DialogContentText>
              )}
              <DialogContentText>
                (To simply update the binder to include manual changes to any documents it contains, without regenerating any, use the
                "Assemble Binder" option instead.)
              </DialogContentText>
              <DialogContentText>Are you sure you want to regenerate the binder documents?</DialogContentText>
            </Stack>
          ) : (
            <>
              <DialogContentText>
                Generate the binder documents for {props.committeeMeeting.name}?
                <br />
                This will:
              </DialogContentText>
              <DialogContentText>
                <ul>
                  {!props.committeeMeeting.isRegistrationCommittee && (
                    <li>
                      Assign tab numbers to all practice reviews on the meeting
                      <br /> &nbsp;
                    </li>
                  )}
                  <li>
                    Generate documents in this meeting's folder in SharePoint:
                    <ul>
                      {autoGeneratedDocumentsList.map((f) => (
                        <li>{f}</li>
                      ))}
                    </ul>
                    <br />
                  </li>
                  <li>
                    Generate documents in assigned {props.committeeMeeting.isRegistrationCommittee ? "PPRP program" : "practice"} reviews'
                    folders in SharePoint:
                    <ul>
                      <li>{reviewTitlePageDocumentsName}</li>
                    </ul>
                  </li>
                </ul>
              </DialogContentText>
            </>
          )
        }
        confirm={async () => {
          await generateMutate({
            variables: { meetingId: props.committeeMeeting.id }
          });

          props.onClose();
        }}
        loading={generateMutation.loading}
        cancel={props.onClose}
      />
    </>
  );
};

export default ConfirmGenerateBinderDocumentsDialog;
