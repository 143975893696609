export enum DocType {
  CATOReport = "CATOReport",
  ChecklistNotesReport = "ChecklistNotesReport",
  CommitteeMeetingMinutes = "CommitteeMeetingMinutes",
  ConfidentialClientList = "ConfidentialClientList",
  DecisionComplyLetter = "DecisionComplyLetter",
  DecisionNonComplyLetter = "DecisionNonComplyLetter",
  DecisionTabledLetter = "DecisionTabledLetter",
  PprpProgramReviewDecisionLetter = "PprpProgramReviewDecisionLetter",
  DeficiencyReport = "DeficiencyReport",
  SignedDeficiencyReport = "SignedDeficiencyReport",
  DirectorPresentationFormat = "DirectorPresentationFormat",
  FirmActivityLogFullDetails = "FirmActivityLogFullDetails",
  FirmResponseOriginal = "FirmResponseOriginal",
  FirmResponseRedacted = "FirmResponseRedacted",
  MailedScheduleNotification = "MailedScheduleNotification",
  OfficeListingReport = "OfficeListingReport",
  OfficeListingReportWithFirm = "OfficeListingReportWithFirm",
  PDDeclarationForm = "PDDeclarationForm",
  PracticeReviewReport = "PracticeReviewReport",
  SignedDraftPracticeReviewReport = "SignedDraftPracticeReviewReport",
  ReviewerEvaluationForm = "ReviewerEvaluationForm",
  PresentationFormat = "PresentationFormat",
  SchedulingQuestionnaire = "SchedulingQuestionnaire",
  SelfEvaluationQuestionnaire = "SelfEvaluationQuestionnaire",
  StandardMotionsSummary = "StandardMotionsSummary",
  StandardMotionsWithNamesSummary = "StandardMotionsWithNamesSummary",
  StudentQuestionnaire = "StudentQuestionnaire",
  TaxQuestionnaire = "TaxQuestionnaire",
  ExemptionLetter = "ExemptionLetter",
  SignedExemptionLetter = "SignedExemptionLetter",
  AdditionalFile = "AdditionalFile",
  PDProofOfAttendanceForm = "PDProofOfAttendanceForm",
  SignedPDProofOfAttendance = "SignedPDProofOfAttendance",
  SignedPDDeclaration = "SignedPDDeclaration",
  MonitoringCompleteLetter = "MonitoringCompleteLetter",

  // PPRP types
  PprpProgramCompetencyMap = "PprpProgramCompetencyMap",
  PprpProgramMemoToRegistrar = "PprpProgramMemoToRegistrar",
  PprpProgramExecutiveSummary = "PprpProgramExecutiveSummary",
  PprpProgramSummaryOfChargeableHours = "PprpProgramSummaryOfChargeableHours",
  PprpProgramSeniorCommitmentLetter = "PprpProgramSeniorCommitmentLetter",

  PprpProgramTaskRegistrarPackage = "PprpProgramTaskRegistrarPackage",
  PprpProgramTaskDecisionLetter = "PprpProgramTaskDecisionLetter",
  PprpProgramTaskSignedDecisionLetter = "PprpProgramTaskSignedDecisionLetter",

  PprpProgramTaskChangeProgramLeaderForm = "PprpProgramTaskChangeProgramLeaderForm",
  PprpProgramTaskChangeProgramManagerForm = "PprpProgramTaskChangeProgramManagerForm",
  PprpProgramTaskPprInformationGatheringForm = "PprpProgramTaskPprInformationGatheringForm",
  PprpProgramTaskModifyProgramForm = "PprpProgramTaskModifyProgramForm",
  PprpProgramTaskDeregisterProgramForm = "PprpProgramTaskDeregisterProgramForm",
  PprpProgramRenewalRequestForm = "PprpProgramRenewalRequestForm",

  PprpProgramTaskNewApplicationForm = "PprpProgramTaskNewApplicationForm",
  PprpProgramTaskLetterOfAgreement = "PprpProgramTaskLetterOfAgreement",
  PprpProgramTaskSignedLetterOfAgreement = "PprpProgramTaskSignedLetterOfAgreement",

  // Registration Committee Item types
  RegistrationCommitteeItemDataSheet = "RegistrationCommitteeItemDataSheet",
  RegistrationCommitteeLegalCounselDecisionVerbiage = "RegistrationCommitteeLegalCounselDecisionVerbiage",
  RegistrationCommitteeItemDecisionLetter = "RegistrationCommitteeItemDecisionLetter",
  RegistrationCommittteeItemFinalReportPackage = "RegistrationCommitteeFinalReportPackage"
}
