import React from "react";
import { Typography } from "@mui/material";

import { staticDataStyles } from "styles/common";
import { RegistrationCommitteeItem } from "../registration-committee-items";
import { makeStyles } from "../makeStyles";

const useStyles = makeStyles()((theme) => ({
  ...staticDataStyles(theme),
  itemList: {
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    columnGap: theme.spacing(1)
  },
  moreIndicator: {
    gridColumn: "1 / span 2"
  }
}));

interface Props {
  items: RegistrationCommitteeItem[];
}

const RegistrationCommitteeItemList = (props: Props) => {
  const listLimit = 5;
  const { classes } = useStyles();

  return (
    <div>
      <Typography variant="body1" className={classes.label}>
        {props.items.length > 1 ? "Items" : "Item"}
      </Typography>
      <div className={classes.itemList}>
        {props.items.slice(0, listLimit).map((item) => (
          <React.Fragment key={item.id}>
            <Typography variant="body1">{item.entityNumber}</Typography>
            <Typography variant="body1">{item.registeredName}</Typography>
          </React.Fragment>
        ))}
        {props.items.length > listLimit && (
          <Typography variant="body2" className={classes.moreIndicator}>{`+ ${props.items.length - listLimit} more`}</Typography>
        )}
      </div>
    </div>
  );
};

export default RegistrationCommitteeItemList;
