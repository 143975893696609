import React, { useMemo, useState } from "react";
import { useQuery, gql } from "@apollo/client";
import { Button, Grid, Paper, Tab, Tabs, Typography, Skeleton, Stack } from "@mui/material";
import { LoadingButton, TabContext, TabPanel } from "@mui/lab";
import { Helmet } from "react-helmet";
import { buildChecklist } from "checklists/buildChecklist";
import { ChecklistSectionTemplate, MasterChecklist, QuestionContainerTemplate } from "checklists";
import { MasterChecklistSectionDisplay } from "./MasterChecklistSectionDisplay";
import EditTabSectionDialog from "./TabSections/EditTabSectionDialog";
import TabSectionDisplay from "./TabSections/TabSectionDisplay";
import { useNotifications } from "notifications";
import {
  QuestionTemplateFieldsFragment,
  ChecklistSectionTemplateFieldsFragment,
  QuestionHeaderTemplateFieldsFragment,
  SectionHeaderToPrintFieldsFragment
} from "./queries";
import { useAxios } from "auth/SecureAxios";
import fileDownload from "js-file-download";
import { DateTime } from "luxon";
import { makeStyles } from "../makeStyles";
import { useAppConfig } from "util/AppConfig";
import { MasterChecklistImportWarningDialog } from "./MasterChecklistImportWarningDialog";
import { optionalScreenWidthLimit } from "styles/theme";
import { AxiosResponse } from "axios";

const useStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100%",
    "& .MuiTabPanel-root": {
      flexGrow: 1
    }
  },
  content: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column"
  },
  header: {
    padding: theme.spacing(3),
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  headerTop: {
    display: "flex",
    alignItems: "baseline",
    flexWrap: "nowrap"
  },
  titleContent: {
    display: "flex",
    alignItems: "baseline",
    flexWrap: "wrap"
  },
  title: {
    marginRight: theme.spacing(3)
  },
  tabContent: {
    flexGrow: 1,
    display: "flex",
    alignItems: "stretch"
  },
  checklistContent: {
    maxWidth: optionalScreenWidthLimit
  },
  tabs: {
    width: "15em",
    flexShrink: 0,
    borderRight: `1px solid ${theme.palette.divider}`,
    "& .MuiTabs-flexContainer": {
      alignItems: "stretch"
    },
    "& .MuiTab-root": {
      minWidth: "unset",
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      alignItems: "flex-start",
      textAlign: "left"
    }
  }
}));

export const MasterChecklistQuery = gql`
  ${ChecklistSectionTemplateFieldsFragment}
  ${QuestionHeaderTemplateFieldsFragment}
  ${QuestionTemplateFieldsFragment}
  ${SectionHeaderToPrintFieldsFragment}
  query MasterChecklist {
    masterChecklistQuestionContainers {
      id
      parentId
      sortOrder
      text
      isFirmSection
      isForPafPracticeReviews
      isForPprpProgramReviews
      isAssignedToEngagementTypes
      sectionHeaderToPrint {
        ...SectionHeaderToPrintFields
      }
      questions {
        ...QuestionTemplateFields
      }
      ...ChecklistSectionTemplateFields
      ...QuestionHeaderTemplateFields
    }
  }
`;

export const MasterChecklistScreen: React.FunctionComponent = () => {
  const { classes } = useStyles();
  const { fileDownloadAxios } = useAxios();
  const notifications = useNotifications();
  const [showImportPafWarning, setShowPafImportWarning] = useState(false);
  const [showImportPprpWarning, setShowPprpImportWarning] = useState(false);
  const [pafMasterChecklistIsDownloading, setPafMasterChecklistIsDownloading] = useState(false);
  const [pprpProgramReviewsMasterChecklistIsDownloading, setPprpProgramReviewsMasterChecklistIsDownloading] = useState(false);
  const appConfig = useAppConfig();

  const [activeTab, setActiveTab] = useState<string>("");
  const [addTabSectionDialogOpen, setAddTabSectionDialogOpen] = useState<boolean>(false);

  const masterChecklistQuery = useQuery<{ masterChecklistQuestionContainers: QuestionContainerTemplate[] }>(MasterChecklistQuery);

  const exportMasterChecklist = async (forPprpProgramReviews: boolean) => {
    if (forPprpProgramReviews) {
      setPprpProgramReviewsMasterChecklistIsDownloading(true);
    } else {
      setPafMasterChecklistIsDownloading(true);
    }

    try {
      const today = DateTime.now();
      let getResult: AxiosResponse<never>;
      const getRequestEndpoint = `${
        appConfig.apiEndpoint
      }/api/master-checklist/export?forPprpProgramReviews=${forPprpProgramReviews.toString()}`;
      getResult = await fileDownloadAxios.get(getRequestEndpoint);
      if (getResult === undefined || getResult?.status === 401) {
        getResult = await fileDownloadAxios.get(getRequestEndpoint);
      }
      if (getResult?.status === 200) {
        fileDownload(getResult.data, `${forPprpProgramReviews ? "PPRP" : "PAF"} Master Checklist - ${today.toFormat("yyyy-MM-dd")}.xlsx`);
      } else {
        notifications.serverError(new Error(getResult?.statusText));
      }
    } catch (e) {
      notifications.error(e);
    }

    if (forPprpProgramReviews) {
      setPprpProgramReviewsMasterChecklistIsDownloading(false);
    } else {
      setPafMasterChecklistIsDownloading(false);
    }
  };

  const checklist: MasterChecklist = useMemo(() => {
    // if the data hasn't loaded yet, or we don't have data, fallback to an empty list.
    const lengthOfChecklist = masterChecklistQuery.data?.masterChecklistQuestionContainers?.length ?? 0;
    if (lengthOfChecklist === 0) {
      return { tabSections: [] };
    }

    // otherwise, build the checklist
    const builtChecklist = buildChecklist((masterChecklistQuery.data?.masterChecklistQuestionContainers ?? []) as any, true);

    // determine if the activeTab state should be set/overriden
    const activeTabNotYetSet = activeTab === "" && builtChecklist.tabSections.length;
    const activeTabNotInList =
      builtChecklist.tabSections
        .map((s) => {
          return s.id.toString();
        })
        .indexOf(activeTab) === -1;

    if (activeTabNotYetSet || activeTabNotInList) {
      // set the active tab to the first one in the list
      setActiveTab(builtChecklist.tabSections[0].id.toString());
    }

    return builtChecklist as any;
  }, [masterChecklistQuery.data?.masterChecklistQuestionContainers]);

  return (
    <div className={classes.root}>
      <Helmet>
        <title>Master Checklist</title>
      </Helmet>

      {masterChecklistQuery.loading ? (
        <Grid container direction="column" spacing={3}>
          <Grid item xs={12}>
            <Skeleton variant="rectangular" width="100%" height="8rem" />
          </Grid>
          <Grid item xs={12}>
            <Skeleton variant="rectangular" width="100%" height="50rem" />
          </Grid>
        </Grid>
      ) : (
        <Paper className={classes.content}>
          <div className={classes.header}>
            <div className={classes.headerTop}>
              <div className={classes.titleContent}>
                <Typography variant="h1" className={classes.title}>
                  Master Checklist
                </Typography>
              </div>
            </div>
            <Stack direction="row" spacing={10} sx={{ mt: 2 }}>
              <Button variant="outlined" onClick={() => setAddTabSectionDialogOpen(true)}>
                Add Tab
              </Button>
              <Stack direction="row" spacing={1}>
                <Button onClick={() => setShowPafImportWarning(true)} variant="outlined">
                  Import PAF
                </Button>
                <LoadingButton loading={pafMasterChecklistIsDownloading} variant="outlined" onClick={() => exportMasterChecklist(false)}>
                  Export PAF
                </LoadingButton>
                <Button onClick={() => setShowPprpImportWarning(true)} variant="outlined">
                  Import PPRP
                </Button>
                <LoadingButton
                  loading={pprpProgramReviewsMasterChecklistIsDownloading}
                  variant="outlined"
                  onClick={() => exportMasterChecklist(true)}>
                  Export PPRP
                </LoadingButton>
              </Stack>
            </Stack>
          </div>
          <div className={classes.tabContent}>
            <Tabs
              value={activeTab}
              indicatorColor="primary"
              textColor="primary"
              orientation="vertical"
              className={classes.tabs}
              onChange={(e, newTab) => {
                setActiveTab(newTab);
              }}>
              {checklist.tabSections.map((s) => (
                <Tab key={s.id} label={s.shortDescription} value={s.id.toString()} />
              ))}
            </Tabs>
            <TabContext value={activeTab}>
              {checklist.tabSections.map((section) => (
                <TabPanel key={section.id} value={section.id.toString()}>
                  <TabSectionDisplay section={section} tabs={checklist.tabSections} />
                  <div className={classes.checklistContent}>
                    {section.children.map((childSection) => (
                      <MasterChecklistSectionDisplay
                        key={`section-${childSection.id}`}
                        section={childSection as ChecklistSectionTemplate}
                        siblings={section.children}
                      />
                    ))}
                  </div>
                </TabPanel>
              ))}
            </TabContext>
            {addTabSectionDialogOpen && (
              <EditTabSectionDialog handleClose={() => setAddTabSectionDialogOpen(false)} confirmButtonText="Add" />
            )}
          </div>
        </Paper>
      )}

      {showImportPafWarning && (
        <MasterChecklistImportWarningDialog
          masterChecklistQuery={masterChecklistQuery}
          forPprpProgramReviews={false}
          onClose={() => setShowPafImportWarning(false)}
        />
      )}

      {showImportPprpWarning && (
        <MasterChecklistImportWarningDialog
          masterChecklistQuery={masterChecklistQuery}
          forPprpProgramReviews={true}
          onClose={() => setShowPprpImportWarning(false)}
        />
      )}
    </div>
  );
};
