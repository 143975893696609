import { useQuery } from "@apollo/client";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  Link,
  MenuItem,
  Paper,
  Select,
  Stack,
  Switch,
  TextField,
  Typography
} from "@mui/material";
import { GridSortCellParams, GridColDef, GridSortModel } from "@mui/x-data-grid-pro";
import { ScreenHeader } from "common/ScreenHeader";
import { DateTime } from "luxon";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Exemption, ExemptionStatus } from ".";
import { DataGridWithHeader } from "common/DataGridWithHeader";
import { makeStyles } from "makeStyles";
import { datagridStyles } from "styles/common";
import { sortDateStringByMonthOnly, standardDateFormat } from "util/formats";
import { PrsDatePicker } from "common/PrsDatePicker";
import { useCurrentUser, Permissions } from "users";
import { FetchExemptionsQuery } from "./queries";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { getRouteForPracticeReview, PracticeReviewTabs } from "../practice-reviews/PracticeReviewScreen";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons/faArrowRight";
import { EndExemptionDialog } from "./EndExemptionDialog";
import { PracticeReview } from "practice-reviews";
import useLocalStorage from "util/useLocalStorage";
import { ExemptionExportButton } from "./ExemptionExportButton";
import _ from "lodash";

const useStyles = makeStyles()((theme) => ({
  ...datagridStyles(theme),
  root: {
    "& .MuiBox-root": {
      height: "100%"
    }
  }
}));

type FilterState = {
  reviewType: string[];
  status: (ExemptionStatus | "Any")[];
  anniversaryMonth: number[];
  fromStartedOnDate: DateTime | null;
  toStartedOnDate: DateTime | null;
  fromEndedOnDate: DateTime | null;
  toEndedOnDate: DateTime | null;
  prNumber: string;
  ended: boolean;
};

const ActiveExemptionsScreen: React.FunctionComponent = () => {
  const { classes } = useStyles();
  const { userHasPermission } = useCurrentUser();
  const history = useHistory();

  const [endingExemptionForPr, setEndingExemptionForPr] = useState<PracticeReview | null>(null);

  const defaultSortModel: GridSortModel = [
    {
      field: "anniversaryMonth",
      sort: "asc"
    }
  ];
  const [sortModel, setSortModel] = useLocalStorage(`Active Exemptions sort model`, defaultSortModel);

  const emptyFilters: FilterState = {
    reviewType: ["Any"],
    status: ["Any"],
    anniversaryMonth: [-1],
    fromStartedOnDate: null,
    toStartedOnDate: null,
    fromEndedOnDate: null,
    toEndedOnDate: null,
    prNumber: "",
    ended: false
  };
  const [filters, setFilters] = useState<FilterState>(emptyFilters);

  const exemptionQuery = useQuery<{ exemptions: Exemption[] }, { ended: boolean }>(FetchExemptionsQuery, {
    variables: { ended: filters.ended }
  });
  const exemptions = exemptionQuery.data?.exemptions ?? [];
  const filteredExemptions = exemptions.filter((exemption) => {
    // Note: active vs. ended exemptions are filtered server-side
    const matchReviewTypeFilter =
      filters.reviewType.every((value) => value === "Any") ||
      filters.reviewType.some((value) => value === exemption.practiceReview.reviewType);
    const startedOnDate = exemption.startedOn ? DateTime.fromISO(exemption.startedOn) : null;
    const endedOnDate = exemption.endedOn ? DateTime.fromISO(exemption.endedOn) : null;

    const matchesStatusFilter =
      filters.status.every((value) => value === "Any") || filters.status.some((status) => status === exemption.status);
    const matchesMonthFilter =
      filters.anniversaryMonth.every((month) => month === -1) ||
      (startedOnDate !== null && filters.anniversaryMonth.some((month) => month === startedOnDate.month));
    const matchesStartDateFilter =
      (filters.fromStartedOnDate === null || (startedOnDate !== null && startedOnDate >= filters.fromStartedOnDate)) &&
      (filters.toStartedOnDate === null || (startedOnDate && startedOnDate <= filters.toStartedOnDate));
    const matchesEndDateFilter =
      (filters.fromEndedOnDate === null || (endedOnDate !== null && endedOnDate >= filters.fromEndedOnDate)) &&
      (filters.toEndedOnDate === null || (endedOnDate && endedOnDate <= filters.toEndedOnDate));
    const matchesPrNumberFilter = filters.prNumber.trim() === "" || exemption.practiceReview.prNumber.indexOf(filters.prNumber) !== -1;

    return (
      matchReviewTypeFilter &&
      matchesStatusFilter &&
      matchesMonthFilter &&
      matchesStartDateFilter &&
      matchesEndDateFilter &&
      matchesPrNumberFilter
    );
  });

  const handleReviewTypeChange = (value: string[]) => {
    if (value.length === 0 || value.filter((val) => val !== "Any").length === 3) {
      return setFilters({ ...filters, reviewType: emptyFilters.reviewType });
    }
    if (value.length < filters.reviewType.length) {
      return setFilters({ ...filters, reviewType: value });
    }
    const diff = value.filter((val) => !filters.reviewType.includes(val));
    if (diff.every((val) => emptyFilters.reviewType.includes(val))) {
      return setFilters({ ...filters, reviewType: emptyFilters.reviewType });
    }

    return setFilters({
      ...filters,
      reviewType: [...filters.reviewType.filter((val) => !emptyFilters.reviewType.includes(val)), ...diff]
    });
  };

  const handleStatusChange = (value: (ExemptionStatus | "Any")[]) => {
    if (value.length === 0 || value.filter((val) => val !== "Any").length === 3) {
      return setFilters({ ...filters, status: emptyFilters.status });
    }
    if (value.length < (filters.status ?? []).length) {
      return setFilters({ ...filters, status: value });
    }
    const diff = value.filter((val) => !filters.status.includes(val));
    if (diff.every((val) => emptyFilters.status.includes(val))) {
      return setFilters({ ...filters, status: emptyFilters.status });
    }
    return setFilters({
      ...filters,
      status: [...filters.status.filter((val) => !emptyFilters.status.includes(val)), ...diff]
    });
  };

  const handleAnniversaryMonthChange = (value: number[]) => {
    if (value.length === 0 || value.filter((val) => val !== -1).length === 12) {
      return setFilters({ ...filters, anniversaryMonth: emptyFilters.anniversaryMonth });
    }
    if (value.length < filters.anniversaryMonth.length) {
      return setFilters({ ...filters, anniversaryMonth: value });
    }
    const diff = value.filter((val) => !filters.anniversaryMonth.includes(val));
    if (diff.every((val) => emptyFilters.anniversaryMonth.includes(val))) {
      return setFilters({ ...filters, anniversaryMonth: emptyFilters.anniversaryMonth });
    }

    return setFilters({
      ...filters,
      anniversaryMonth: [...filters.anniversaryMonth.filter((val) => !emptyFilters.anniversaryMonth.includes(val)), ...diff]
    });
  };

  const prNumbers = filteredExemptions.map((obj) => obj.practiceReview.prNumber);

  return (
    <>
      <Helmet>
        <title>Exemptions - PRS Online</title>
      </Helmet>
      <Stack alignItems={"center"} justifyContent={"space-between"} direction={"row"}>
        <ScreenHeader title="Exemptions" />
        <ExemptionExportButton prNumbers={prNumbers} isEnded={filters.ended} />
      </Stack>
      <Paper sx={{ p: 2 }}>
        <DataGridWithHeader
          className={classes.root}
          headerActions={
            <Stack direction="row" spacing={2} alignItems="center">
              <Typography variant="h4">Filters</Typography>
              <FormControl margin="none" size="small" sx={{ width: "14em" }}>
                <InputLabel id="exemption-type-label">Review Type</InputLabel>
                <Select
                  multiple
                  labelId="exemption-type-label"
                  label="Review Type"
                  value={filters.reviewType}
                  onChange={({ target: { value } }) => handleReviewTypeChange(value as string[])}>
                  <MenuItem value={"Any"}>Any</MenuItem>
                  <MenuItem value={"Assurance"}>Assurance</MenuItem>
                  <MenuItem value={"Non-assurance"}>Non-Assurance</MenuItem>
                  <MenuItem value={"PPRP Program"}>PPRP Program</MenuItem>
                </Select>
              </FormControl>
              <FormControl margin="none" size="small" sx={{ width: "14em" }}>
                <InputLabel id="exemption-status-label">Exemption Status</InputLabel>
                <Select
                  multiple
                  labelId="exemption-status-label"
                  label="Exemption Status"
                  value={filters.status}
                  onChange={({ target: { value } }) => handleStatusChange(value as ExemptionStatus[])}>
                  <MenuItem value={"Any"}>Any</MenuItem> <MenuItem value={ExemptionStatus.Pending}>Pending</MenuItem>
                  <MenuItem value={ExemptionStatus.PendingRenewal}>Pending Renewal</MenuItem>
                  <MenuItem value={ExemptionStatus.Exempt}>Exempt</MenuItem>
                </Select>
              </FormControl>
              <FormControl margin="none" size="small" sx={{ width: "14em" }}>
                <InputLabel id="anniversary-month-label">Anniversary Month</InputLabel>
                <Select
                  multiple
                  labelId="anniversary-month-label"
                  label="Anniversary Month"
                  value={filters.anniversaryMonth}
                  onChange={({ target: { value } }) => handleAnniversaryMonthChange(value as number[])}>
                  <MenuItem value={-1}>Any</MenuItem> <MenuItem value={1}>January</MenuItem>
                  <MenuItem value={2}>February</MenuItem> <MenuItem value={3}>March</MenuItem>
                  <MenuItem value={4}>April</MenuItem> <MenuItem value={5}>May</MenuItem> <MenuItem value={6}>June</MenuItem>
                  <MenuItem value={7}>July</MenuItem> <MenuItem value={8}>August</MenuItem> <MenuItem value={9}>September</MenuItem>
                  <MenuItem value={10}>October</MenuItem> <MenuItem value={11}>November</MenuItem> <MenuItem value={12}>December</MenuItem>
                </Select>
              </FormControl>

              {filters.ended ? (
                <>
                  <PrsDatePicker
                    setValue={(newDate) => setFilters({ ...filters, fromEndedOnDate: newDate?.startOf("day") ?? null })}
                    value={filters.fromEndedOnDate}
                    label="From End Date"
                    allowNonWorkingDays
                  />

                  <PrsDatePicker
                    setValue={(newDate) => setFilters({ ...filters, toEndedOnDate: newDate?.startOf("day") ?? null })}
                    value={filters.toEndedOnDate}
                    label="To End Date"
                    allowNonWorkingDays
                  />
                </>
              ) : (
                <>
                  <PrsDatePicker
                    setValue={(newDate) => setFilters({ ...filters, fromStartedOnDate: newDate?.startOf("day") ?? null })}
                    value={filters.fromStartedOnDate}
                    label="From Start Date"
                    allowNonWorkingDays
                  />

                  <PrsDatePicker
                    setValue={(newDate) => setFilters({ ...filters, toStartedOnDate: newDate?.startOf("day") ?? null })}
                    value={filters.toStartedOnDate}
                    label="To Start Date"
                    allowNonWorkingDays
                  />
                </>
              )}

              <TextField value={filters.prNumber} label="PR No." onChange={(e) => setFilters({ ...filters, prNumber: e.target.value })} />

              <FormControlLabel
                control={
                  <Switch
                    checked={filters.ended}
                    onClick={() =>
                      setFilters({
                        ...filters,
                        ended: !filters.ended,
                        fromStartedOnDate: null,
                        toStartedOnDate: null,
                        fromEndedOnDate: null,
                        toEndedOnDate: null
                      })
                    }
                  />
                }
                label="Show Ended Exemptions"
              />

              <Box sx={{ flex: 1 }} />

              <Button variant="outlined" onClick={() => setFilters(emptyFilters)}>
                Clear
              </Button>
            </Stack>
          }
          stickyHeader
          columns={
            [
              {
                field: "entityNumber",
                headerName: "Entity No.",
                valueGetter: ({ row }) =>
                  row.practiceReview.isPprpProgramReview
                    ? row.practiceReview.pprpProgram?.programEntityNumber
                    : row.practiceReview.firm.entityNumber,
                width: 80
              },
              {
                field: "firmName",
                headerName: "Firm",
                valueGetter: ({ row }) => row.practiceReview.firm.name,
                renderCell: ({ row }) => (
                  <Link to={getRouteForPracticeReview(row.practiceReview, PracticeReviewTabs.Exemption)} component={RouterLink}>
                    {row.practiceReview.firm.name}
                  </Link>
                ),
                flex: 2
              },
              {
                field: "reviewType",
                headerName: "Type",
                width: 110,
                valueGetter: ({ row }) => row.practiceReview.reviewType
              },
              {
                field: "status",
                headerName: "Exemption Status",
                valueGetter: ({ row }) => (row.status === ExemptionStatus.PendingRenewal ? "Pending Renewal" : row.status.toString()),
                width: 140,
                hide: filters.ended
              },
              {
                field: "startedReason",
                headerName: "Reason Started",
                flex: 1
              },
              {
                field: "exemptionLetterSent",
                headerName: "Letter Sent",
                type: "date",
                valueGetter: ({ row }) => (row.exemptionLetterSent ? row.exemptionLetterSent! : null),
                valueFormatter: (params) => (params.value ? DateTime.fromISO(params.value).toFormat(standardDateFormat) : "--"),
                width: 90,
                hide: filters.ended
              },
              {
                field: "exemptionLetterAcknowledged",
                headerName: "Letter Acknowledged",
                headerClassName: classes.wrapHeader,
                type: "date",
                valueGetter: ({ row }) => (row.exemptionLetterAcknowledged ? row.exemptionLetterAcknowledged! : null),
                valueFormatter: (params) => (params.value ? DateTime.fromISO(params.value).toFormat(standardDateFormat) : "--"),
                width: 110,
                hide: filters.ended
              },
              {
                field: "startedOn",
                headerName: "Start Date",
                type: "date",
                valueGetter: ({ row }) => (row.startedOn ? row.startedOn! : null),
                valueFormatter: (params) => (params.value ? DateTime.fromISO(params.value).toFormat(standardDateFormat) : "--"),
                width: 90
              },
              {
                field: "anniversaryMonth",
                headerName: "Anniv. Month",
                headerClassName: `${classes.wrapHeader} ${classes.tightSortIconHeader}`,
                type: "date",
                sortComparator: sortDateStringByMonthOnly,
                valueGetter: ({ row }) => (row.startedOn ? row.startedOn! : null),
                valueFormatter: (params) => (params.value ? DateTime.fromISO(params.value).toFormat("MMM") : "--"),
                width: 70
              },
              {
                field: "approvedBy",
                headerName: "Approved By",
                valueGetter: ({ row }) => row.approvedByUser?.name ?? "--",
                width: 120,
                hide: filters.ended
              },
              {
                field: "currentActivity",
                headerName: "Current Activity",
                valueGetter: ({ row }) => row.practiceReview.inas.filter((ina) => !ina.isComplete)[0]?.type.friendlyName ?? "--",
                flex: 1,
                hide: filters.ended
              },
              {
                field: "endedReason",
                headerName: "Reason Ended",
                flex: 1,
                hide: !filters.ended
              },
              {
                field: "endedOn",
                headerName: "End Date",
                type: "date",
                valueGetter: ({ row }) => (row.endedOn ? row.endedOn! : null),
                valueFormatter: (params) => (params.value ? DateTime.fromISO(params.value).toFormat(standardDateFormat) : "--"),
                width: 90,
                hide: !filters.ended
              },
              {
                field: "endedBy",
                headerName: "Ended By",
                valueGetter: ({ row }) => row.endedByUser?.name ?? "--",
                width: 120,
                hide: !filters.ended
              },
              {
                field: "prNumber",
                headerName: "Last PR No.",
                width: 100,
                valueGetter: ({ row }) => row.practiceReview.prNumber,
                disableColumnMenu: true,
                sortComparator: (v1, v2, param1: GridSortCellParams, param2: GridSortCellParams) =>
                  param1.api.getRow(param1.id)!.practiceReview.prNumber.localeCompare(param2.api.getRow(param2.id)!.practiceReview.prNumber)
              },
              {
                field: "actions",
                headerName: "Actions",
                width: 180,
                renderCell: ({ row }) => {
                  const exemption = row;

                  return (
                    <Stack direction="row" spacing={1}>
                      {!filters.ended && userHasPermission(Permissions.ExemptionConfirm) && (
                        <Button
                          variant="outlined"
                          size="small"
                          sx={{ pt: 0, pb: 0 }}
                          onClick={() => setEndingExemptionForPr(exemption.practiceReview)}>
                          End Exemption
                        </Button>
                      )}
                      <IconButton
                        color="primary"
                        size="small"
                        title="Go"
                        onClick={() => history.push(getRouteForPracticeReview(exemption.practiceReview, PracticeReviewTabs.Exemption))}>
                        <FontAwesomeIcon icon={faArrowRight} />
                      </IconButton>
                    </Stack>
                  );
                }
              }
            ] as GridColDef<Exemption>[]
          }
          rows={filteredExemptions}
          displayWithoutContainer
          loading={exemptionQuery.loading}
          noDataMessage="No exemptions."
          sortModel={sortModel}
          onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
        />
      </Paper>

      {endingExemptionForPr && <EndExemptionDialog practiceReview={endingExemptionForPr} onClose={() => setEndingExemptionForPr(null)} />}
    </>
  );
};

export default ActiveExemptionsScreen;
