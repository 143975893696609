import React, { useState } from "react";
import { DialogContentText, Divider, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import { gql, useMutation } from "@apollo/client";
import { ChecklistSectionTemplate } from "checklists";
import EditTabSectionDialog from "./EditTabSectionDialog";
import { ConfirmationDialog } from "common/ConfirmationDialog";
import EditMasterChecklistSectionDialog from "master-checklist/dialogs/EditMasterChecklistSectionDialog";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons/faEllipsisV";
import EditTabOrderDialog from "master-checklist/dialogs/EditTabOrderDialog";
import { makeStyles } from "makeStyles";
import { optionalScreenWidthLimit } from "styles/theme";

const useStyles = makeStyles()((theme) => ({
  root: {
    padding: theme.spacing(3),
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: theme.spacing(-3),
    marginBottom: theme.spacing(2)
  },
  headerContainer: {
    display: "flex",
    maxWidth: optionalScreenWidthLimit,
    justifyContent: "space-between"
  },
  sectionDetails: {
    marginTop: theme.spacing(2),
    marginLeft: "auto",
    display: "flex",
    "& > *": {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      fontWeight: 500
    },
    "& > *:first-child": {
      paddingLeft: 0
    },
    "& > *:not(:first-child)": {
      borderLeft: `1px solid ${theme.palette.border}`
    }
  },
  buttonGroup: {
    "& > *": {
      marginLeft: theme.spacing(1)
    }
  },
  menu: {
    position: "relative"
  }
}));

const DeleteTabSectionMutation = gql`
  mutation DeleteTabSection($id: Int) {
    masterChecklistQuestionContainers {
      deleteSection(id: $id)
    }
  }
`;

interface Props {
  tabs: ChecklistSectionTemplate[];
  section: ChecklistSectionTemplate;
  onDelete?: () => void;
}

const TabSectionDisplay = (props: Props) => {
  const { classes } = useStyles();

  const [deleteMutation, { loading: deleting }] = useMutation<
    { masterChecklistQuestionContainers: { deleteTabSection: boolean } },
    { id: number }
  >(DeleteTabSectionMutation);

  const [menuAnchorElement, setMenuAnchorElement] = useState<HTMLElement | null>(null);

  const [editTabSectionDialogOpen, setEditTabSectionDialogOpen] = useState<boolean>(false);
  const [editTabOrderDialogOpen, setEditTabOrderDialogOpen] = useState<boolean>(false);
  const [addSubsectionDialogOpen, setAddSubsectionDialogOpen] = useState<boolean>(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);

  const deleteTabSection = async () => {
    await deleteMutation({
      variables: { id: props.section.id },
      update: (cache) => {
        const normalizedId = cache.identify({ id: props.section.id, __typename: props.section.__typename });
        cache.evict({ id: normalizedId });
        cache.gc();
      }
    });

    setDeleteDialogOpen(false);
  };

  return (
    <div className={classes.root}>
      <div className={classes.headerContainer}>
        <div>
          <div>
            <Typography variant="subtitle1">{props.section.shortDescription}</Typography>
          </div>
          <div>
            <Typography variant="h2">{props.section.text}</Typography>
          </div>
        </div>
        <div className={classes.buttonGroup}>
          <div className={classes.menu}>
            <IconButton onClick={(e) => setMenuAnchorElement(e.currentTarget)}>
              <FontAwesomeIcon icon={faEllipsisV} />
            </IconButton>
            <Menu
              id="pr-menu"
              anchorEl={menuAnchorElement}
              keepMounted
              open={!!menuAnchorElement}
              onClose={() => setMenuAnchorElement(null)}>
              <MenuItem
                onClick={() => {
                  setMenuAnchorElement(null);
                  setEditTabSectionDialogOpen(true);
                }}>
                Edit
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setMenuAnchorElement(null);
                  setEditTabOrderDialogOpen(true);
                }}>
                Reorder Tabs
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setMenuAnchorElement(null);
                  setDeleteDialogOpen(true);
                }}>
                Delete
              </MenuItem>
              <Divider />
              <MenuItem
                onClick={() => {
                  setMenuAnchorElement(null);
                  setAddSubsectionDialogOpen(true);
                }}>
                Add Section
              </MenuItem>
            </Menu>
          </div>
        </div>
      </div>
      <div className={classes.sectionDetails}>
        <div>
          <Typography variant="subtitle2">{props.section.isFirmSection ? "Firm-wide" : "Individual Client Files"}</Typography>
        </div>
        {props.section.sectionHeaderToPrint && (
          <div>
            <Typography variant="subtitle2">{props.section.sectionHeaderToPrint?.name}</Typography>
          </div>
        )}
        <div>
          <Typography variant="subtitle2">
            {props.section.isForPafPracticeReviews && props.section.isForPprpProgramReviews
              ? "PAF & PPRP"
              : props.section.isForPprpProgramReviews
              ? "PPRP"
              : "PAF"}
          </Typography>
        </div>
      </div>
      {editTabSectionDialogOpen && (
        <EditTabSectionDialog section={props.section} handleClose={() => setEditTabSectionDialogOpen(false)} confirmButtonText="Save" />
      )}
      {addSubsectionDialogOpen && (
        <EditMasterChecklistSectionDialog
          title={"Add Section"}
          isHeader={false}
          container={props.section}
          handleClose={() => setAddSubsectionDialogOpen(false)}
          confirmButtonText="Add"
        />
      )}
      {editTabOrderDialogOpen && <EditTabOrderDialog handleClose={() => setEditTabOrderDialogOpen(false)} tabs={props.tabs} />}
      {
        <ConfirmationDialog
          open={deleteDialogOpen}
          title={`Delete Tab ${props.section.shortDescription}?`}
          body={<DialogContentText>Are you sure you want to delete this tab and all the sections inside it?</DialogContentText>}
          confirm={deleteTabSection}
          cancel={() => setDeleteDialogOpen(false)}
          loading={deleting}
        />
      }
    </div>
  );
};

export default TabSectionDisplay;
