import { Box, Stack, Typography } from "@mui/material";
import { CompletionMarker } from "checklists/CompletionMarker";
import { ReviewedClient } from "practice-reviews";
import React from "react";
import { makeStyles } from "makeStyles";
import { formatDate } from "../../util/formats";

interface Props {
  isPprpProgramReview: boolean;
  reviewedClient: ReviewedClient;
  isSelected: boolean;
  reviewedClientClicked: (client: ReviewedClient) => void;
  markerClicked: (client: ReviewedClient) => void;
  userCanViewReviewedClient: boolean;
}

const useStyles = makeStyles()((theme) => ({
  reviewedClient: {
    padding: theme.spacing(1),
    display: "flex",
    alignItems: "baseline",
    border: "1px solid transparent",
    "&:hover": {
      border: `1px solid ${theme.palette.cpaAccentLavender.main}`,
      backgroundColor: theme.palette.cpaAccentLavender.light
    },
    cursor: "default"
  },
  selectedReviewedClient: {
    border: `1px solid ${theme.palette.cpaAccentTurquoise.main} !important`,
    backgroundColor: `${theme.palette.cpaAccentTurquoise.light} !important`,
    cursor: "inherit !important"
  },
  marker: {
    marginTop: theme.spacing(1)
  },
  refNum: {
    color: theme.palette.cpaGrey.light,
    marginLeft: theme.spacing(3)
  },
  reviewedClientDetails: {
    color: theme.palette.cpaGrey.light
  },
  clickableChecklistCompletion: {
    cursor: "pointer"
  }
}));

export const ReviewedClientTile: React.FunctionComponent<Props> = (props) => {
  const { classes, cx } = useStyles();

  return (
    <Box
      className={cx(classes.reviewedClient, {
        [classes.selectedReviewedClient]: props.isSelected
      })}
      onClick={props.userCanViewReviewedClient ? () => props.reviewedClientClicked!(props.reviewedClient) : undefined}
      title={
        !props.isSelected && props.userCanViewReviewedClient
          ? `Click to edit ${props.isPprpProgramReview ? "candidate" : "client"} details`
          : undefined
      }
      sx={{ ml: 4, mr: 2 }}>
      <Box
        sx={{ mr: 2 }}
        className={cx({ [classes.clickableChecklistCompletion]: props.userCanViewReviewedClient })}
        onClick={props.userCanViewReviewedClient ? () => props.markerClicked!(props.reviewedClient) : undefined}
        title={props.userCanViewReviewedClient ? "Click to go to checklist" : undefined}>
        <CompletionMarker completion={props.reviewedClient.checklistCompletion} className={classes.marker} />
      </Box>
      <div>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h4">{`${props.reviewedClient.name}${
            props.reviewedClient.candidate ? ` (${props.reviewedClient.candidate.idNo})` : ""
          }`}</Typography>
          <Typography variant="h4" className={classes.refNum}>
            <span>{props.reviewedClient.refNum}</span>
          </Typography>
        </Stack>
        <Stack direction="row" spacing={2} className={cx(classes.reviewedClientDetails)}>
          {props.isPprpProgramReview ? (
            <>
              <Typography variant="caption">{formatDate(props.reviewedClient.candidate?.perEffectiveDate ?? null)}</Typography>
            </>
          ) : (
            <>
              <Typography variant="caption">{props.reviewedClient.engagementType?.name}</Typography>
            </>
          )}
          <Typography variant="caption">{props.reviewedClient.reviewer?.name}</Typography>
        </Stack>
      </div>
    </Box>
  );
};
