import React, { useState, useEffect } from "react";
import { makeStyles } from "../makeStyles";
import { useAppConfig } from "../util/AppConfig";
import { useAxios } from "../auth/SecureAxios";
import { GeneratedDocumentType, GeneratedDocTypeCode, ReflectedDocGenDataSetInfo } from "./models";
import { LoadingButton, TabContext, TabList, TabPanel } from "@mui/lab";
import fileDownload from "js-file-download";
import { Formik, Field as FormikField, FormikErrors, FormikProps } from "formik";
import { TextField as FmuiTextField, CheckboxWithLabel as FmuiCheckboxWithLabel, RadioGroup } from "formik-mui";
import { useNotifications } from "notifications";
import {
  Box,
  Card,
  FormControl,
  FormControlLabel,
  InputLabel,
  ListSubheader,
  MenuItem,
  Paper,
  Radio,
  Select,
  Stack,
  Tab,
  Typography
} from "@mui/material";
import { Helmet } from "react-helmet";
import { ScreenHeader } from "common/ScreenHeader";
import { useQuery } from "@apollo/client";
import { CommitteeMeeting, FetchCommitteeMeetingsQuery, PastPeriod } from "../committee-meetings";
import _ from "lodash";
import { optionalScreenWidthLimit } from "styles/theme";
import { MergeFieldDisplay } from "./MergeFieldDisplay";
import RichTextEditor from "common/RichTextEditor";
import { getHtmlForField, htmlHasContent } from "util/utilities";
import { getMainBarHeight } from "chrome/SiteAppBar";

const useStyles = makeStyles()((theme) => ({
  tabContext: {
    flexGrow: 1,
    display: "flex",
    alignItems: "stretch",
    maxHeight: `calc(100vh - ${getMainBarHeight(theme)} - ${theme.spacing(18)})`,
    overflowY: "auto"
  },
  schemaTabHeader: {
    justifyContent: "flex-start"
  },
  typesListInHeader: {
    textAlign: "left",
    textTransform: "none",
    marginBottom: "0"
  },
  tabs: {
    alignSelf: "flex-start",
    borderRight: "1px solid rgba(0, 0, 0, 0.12)",
    left: 0,
    width: "20em",
    height: "100%",
    flexShrink: 0,
    "& .MuiTabs-flexContainer": {
      alignItems: "stretch"
    },
    "& .MuiTab-root": {
      minWidth: "unset",
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      alignItems: "flex-start",
      textAlign: "left",
      "&:hover": {
        backgroundColor: theme.palette.highlight
      }
    }
  },
  schemaTypeMenuHeader: {
    backgroundColor: "#f0f0f0",
    textDecoration: "underline",
    lineHeight: theme.spacing(4)
  },
  documentTypeMenuItem: {
    paddingLeft: theme.spacing(4)
  },
  tabPanel: {
    width: "700px",
    flexGrow: 1,
    flexShrink: 0,
    overflowY: "scroll",
    padding: `0 ${theme.spacing(2)}`
  },
  templateTextbox: {
    flexGrow: 2,
    flexShrink: 0,
    maxWidth: "700px",
    paddingLeft: theme.spacing(2),
    marginTop: "0"
  },
  tabHeaderColumn: {
    top: "0"
  },
  tabColumnName: {
    marginTop: "20px",
    marginLeft: "10px"
  },
  fieldDocPaper: {
    marginBottom: theme.spacing(-6)
  }
}));

interface FormValues {
  prnumber?: string;
  committeeMeetingId?: string;
  registrationCommitteeItemId?: string;
  documentTypeCode?: GeneratedDocTypeCode;
  templateToTest?: any;
  isExemptionRenewal?: boolean;
  generationActionType?: "CurrentTemplate" | "UploadCustom" | "FromTextbox";
  customText?: string;
}

const DocTypesRequiringPracticeReview = [
  GeneratedDocTypeCode.ChecklistNotesReport,
  GeneratedDocTypeCode.DeficiencyReport,
  GeneratedDocTypeCode.DirectorPresentationFormat,
  GeneratedDocTypeCode.PracticeReviewReport,
  GeneratedDocTypeCode.PresentationFormat,
  GeneratedDocTypeCode.PDDeclarationForm,
  GeneratedDocTypeCode.PDProofOfAttendanceForm,
  GeneratedDocTypeCode.DecisionComplyLetter,
  GeneratedDocTypeCode.DecisionNonComplyLetter,
  GeneratedDocTypeCode.DecisionTabledLetter,
  GeneratedDocTypeCode.PprpProgramReviewDecisionLetter,
  GeneratedDocTypeCode.ExemptionLetter
];

const DocTypesRequiringCommitteeMeeting = [
  GeneratedDocTypeCode.CommitteeMeetingAgenda,
  GeneratedDocTypeCode.CommitteeMeetingMinutes,
  GeneratedDocTypeCode.StandardMotionsSummary,
  GeneratedDocTypeCode.StandardMotionsWithNamesSummary,
  GeneratedDocTypeCode.OfficeListingReport,
  GeneratedDocTypeCode.OfficeListingReportWithFirm,
  GeneratedDocTypeCode.DecisionTabledLetter,
  GeneratedDocTypeCode.RegistrationCommitteeAgenda,
  GeneratedDocTypeCode.RegistrationCommitteeMinutes
];

const DocTypesRequiringRegistrationCommitteeItem = [
  GeneratedDocTypeCode.RegistrationCommitteeItemDataSheet,
  GeneratedDocTypeCode.RegistrationCommitteeItemDecisionLetter
];

const DocumentTemplateSandboxScreen: React.FunctionComponent = () => {
  const { classes, cx } = useStyles();
  const appConfig = useAppConfig();
  const { secureAxios } = useAxios();
  const notifications = useNotifications();
  const [generating, setGenerating] = useState<boolean>(false);
  const [loadingDocGenSchema, setLoadingDocGenSchema] = useState<boolean>(false);
  const [docGenSchema, setDocGenSchema] = useState<ReflectedDocGenDataSetInfo[] | undefined>(undefined);
  const [selectedSchema, setSelectedSchema] = React.useState<ReflectedDocGenDataSetInfo | undefined>(undefined);
  const [selectedDocType, setSelectedDocType] = React.useState<GeneratedDocumentType | undefined>(undefined);
  const [tabValue, setTabValue] = React.useState<string>("");
  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  const customTextContentRetriever = React.useRef<{
    getContentAsHtml: () => string | null;
    getNewHtmlWithTextInserted: (textToInsert: string) => string | null;
  }>({
    getContentAsHtml: () => null,
    getNewHtmlWithTextInserted: (textToInsert) => null
  });

  const initialValues: FormValues = {
    isExemptionRenewal: false,
    documentTypeCode: undefined
  };

  function validate(values: FormValues) {
    const errors: FormikErrors<FormValues> = {};

    if (!values.documentTypeCode) {
      errors.documentTypeCode = "Select the type of document to generate.";
    }

    if (!values.generationActionType) {
      errors.generationActionType = "Select the desired template source (existing, upload, or typing).";
    }

    if (values.generationActionType === "UploadCustom" && !values.templateToTest) {
      errors.templateToTest = "Select a template file to upload.";
    }

    if (values.generationActionType === "FromTextbox" && !getHtmlForField(customTextContentRetriever)) {
      errors.customText = "Please provide some template content.";
    }

    if (values.documentTypeCode && DocTypesRequiringPracticeReview.indexOf(values.documentTypeCode) > -1 && !values.prnumber) {
      errors.prnumber = `PR Number is required for a ${selectedDocType?.friendlyName}.`;
    }

    if (values.documentTypeCode && DocTypesRequiringCommitteeMeeting.indexOf(values.documentTypeCode) > -1 && !values.committeeMeetingId) {
      errors.committeeMeetingId = `Committee Meeting ID is required for a ${selectedDocType?.friendlyName}.`;
    }

    if (
      values.documentTypeCode &&
      DocTypesRequiringRegistrationCommitteeItem.indexOf(values.documentTypeCode) > -1 &&
      !values.registrationCommitteeItemId
    ) {
      errors.registrationCommitteeItemId = `Registration Committee Item ID is required for a ${selectedDocType?.friendlyName}.`;
    }

    return errors;
  }

  const b64toBlob = (base64: string, type = "application/octet-stream") => fetch(`data:${type};base64,${base64}`).then((res) => res.blob());

  const prcMeetingsQuery = useQuery<
    { committeeMeetings: CommitteeMeeting[] },
    { excludeFuture: boolean; pastPeriod: PastPeriod | null; registrationCommittee: boolean }
  >(FetchCommitteeMeetingsQuery, {
    variables: { excludeFuture: false, pastPeriod: PastPeriod.SixMonths, registrationCommittee: false }
  });
  const regComMeetingsQuery = useQuery<
    { committeeMeetings: CommitteeMeeting[] },
    { excludeFuture: boolean; pastPeriod: PastPeriod | null; registrationCommittee: boolean }
  >(FetchCommitteeMeetingsQuery, {
    variables: { excludeFuture: false, pastPeriod: PastPeriod.SixMonths, registrationCommittee: true }
  });
  const meetings = _.orderBy(
    _.concat(prcMeetingsQuery.data?.committeeMeetings ?? [], regComMeetingsQuery.data?.committeeMeetings ?? []),
    [(m) => m.meetingDate, (m) => m.location],
    ["asc", "asc"]
  );

  async function getSchemaFields() {
    setLoadingDocGenSchema(true);
    let getResult: any;

    try {
      getResult = await secureAxios.get(`${appConfig.apiEndpoint}/api/document-template-sandbox/schema`);
    } catch (e: any) {
      console.log(JSON.stringify(e));
      setLoadingDocGenSchema(false);
      notifications.serverError(e.message);
      return;
    }
    if (getResult.status !== 200) {
      notifications.serverError(new Error(getResult.statusText));
    } else {
      setDocGenSchema(getResult.data);
      console.log(getResult);
    }

    setLoadingDocGenSchema(false);
  }

  useEffect(() => {
    getSchemaFields();
  }, []);

  async function upload(values: FormValues) {
    setGenerating(true);

    const formData = new FormData();
    formData.append("PrNumber", values.prnumber ?? "");
    formData.append("CommitteeMeetingId", values.committeeMeetingId?.toString() ?? "");
    formData.append("RegistrationCommitteeItemId", values.registrationCommitteeItemId?.toString() ?? "");
    formData.append("DocumentTypeCode", values.documentTypeCode?.toString() ?? "");
    formData.append("TemplateToTest", values.templateToTest);
    formData.append("UseExistingTemplate", Boolean(values.generationActionType === "CurrentTemplate").toString());
    formData.append("UseCustomText", Boolean(values.generationActionType === "FromTextbox").toString());
    formData.append("CustomText", getHtmlForField(customTextContentRetriever) ?? "");
    formData.append("IsExemptionRenewal", values.isExemptionRenewal?.toString() ?? "");

    let postResult: any;
    try {
      postResult = await secureAxios.post(`${appConfig.apiEndpoint}/api/document-template-sandbox/upload`, formData);
    } catch (e: any) {
      console.log(JSON.stringify(e));
      setGenerating(false);
      notifications.serverError(e.message);
      return;
    }
    if (postResult.status !== 200) {
      notifications.serverError(new Error(postResult.statusText));
    } else if (!postResult.data?.isSuccess) {
      var errorMessage = postResult.data?.errorMessage ?? "An unknown error occurred during generation.";
      if (postResult.data?.documentBytes?.length) {
        notifications.warning(errorMessage);
        fileDownload(await b64toBlob(postResult.data.documentBytes), postResult.data.filename, "application/msword");
      } else {
        notifications.error(errorMessage);
      }
    } else {
      notifications.success("Generation succeeded.");
      fileDownload(await b64toBlob(postResult.data.documentBytes), postResult.data.filename, "application/msword");
    }

    setGenerating(false);
  }

  function insertMergeFieldText(textToInsert: string, formikProps: FormikProps<FormValues>) {
    console.log("getting new state...");
    var newHtmlContent = customTextContentRetriever.current.getNewHtmlWithTextInserted(textToInsert);
    console.log("got new state:");
    console.log(newHtmlContent);
    formikProps.setFieldValue("customText", newHtmlContent);
  }

  return (
    <div>
      <Helmet>
        <title>Document Template Sandbox - PRS Online</title>
      </Helmet>

      <ScreenHeader title="Document Template Sandbox" />
      <Stack>
        <Formik initialValues={initialValues} onSubmit={upload} validate={validate}>
          {(formikProps) => (
            <Stack spacing={2}>
              <Card sx={{ padding: 2, maxWidth: "80rem" }}>
                <Stack spacing={2}>
                  <Typography variant="h3">Experiment with Document Generation and Merge Fields</Typography>
                  <Typography variant="body2">
                    Here, you can try document templates and merge fields with the reviews and meetings in the system without affecting them
                    or making any special setup for them. <br />
                    Pick a particular document type to generate, <br />
                    indicate the data you wish to run it against (PR Number and/or Committee Meeting, as necessary), <br /> and then select
                    one of the three options:
                    <ul>
                      <li>
                        Use the system's existing template file from SharePoint, same as if it was being generated in the application. Use
                        this to test what's currently in place and make sure it's working correctly with a particular review or meeting.
                      </li>
                      <li>
                        Select a file from your computer to use as the template. This can be used to try out a new version of a document
                        template you're working on, without changing the template loaded into SharePoint that the system uses, so it won't
                        disrupt ongoing use of the system while you're getting it just the way you want it.
                      </li>
                      <li>
                        Type content into a "Template to Run" textbox, which appears next to the list of fields in the Field Documentation
                        pane below when you select this option. This allows you to experiment with fields and syntax using the documentation
                        as a guide and inserting fields from it as appropriate.
                      </li>
                    </ul>
                    The Field Documentation will automatically select the dataset that covers the document type you select in this form, or
                    if you scroll down without selecting one, you can freely navigate between the vertical tabs of the various datasets in
                    the application.
                  </Typography>
                  <Stack spacing={2} sx={{ maxWidth: "30rem" }}>
                    <FormControl variant="outlined" size="small">
                      <FormikField
                        component={FmuiTextField}
                        select
                        name="documentTypeCode"
                        label="Document Type to Generate"
                        onChange={(e: React.ChangeEvent<any>) => {
                          let schemaType = docGenSchema?.find(
                            (sch) => sch.documentTypes.findIndex((dt) => dt.typeCode === e.target.value) > -1
                          );
                          setSelectedSchema(schemaType);
                          if (schemaType) {
                            console.log(schemaType.dataSetType);
                            setTabValue(schemaType.dataSetName);
                          }
                          var documentType = schemaType?.documentTypes?.find((dt) => dt.typeCode === e.target.value);
                          setSelectedDocType(documentType);
                          formikProps.setFieldValue("documentTypeCode", e.target.value);
                        }}
                        required>
                        {_.orderBy(docGenSchema, (sch) => sch.dataSetName)?.map((dataSetType) => {
                          return [
                            <ListSubheader key={dataSetType.dataSetType} className={classes.schemaTypeMenuHeader}>
                              {dataSetType.dataSetName}
                            </ListSubheader>,
                            ..._.orderBy(dataSetType.documentTypes, (dt) => dt.friendlyName).map((documentType) => {
                              return (
                                <MenuItem
                                  key={documentType.typeCode}
                                  value={documentType.typeCode}
                                  className={classes.documentTypeMenuItem}>
                                  {documentType.friendlyName}
                                </MenuItem>
                              );
                            })
                          ];
                        })}
                      </FormikField>
                    </FormControl>
                    {formikProps.values.documentTypeCode &&
                      DocTypesRequiringPracticeReview.indexOf(formikProps.values.documentTypeCode) > -1 && (
                        <FormikField component={FmuiTextField} name="prnumber" label="PR Number" required />
                      )}
                    {formikProps.values.documentTypeCode &&
                      DocTypesRequiringRegistrationCommitteeItem.indexOf(formikProps.values.documentTypeCode) > -1 && (
                        <FormikField
                          component={FmuiTextField}
                          name="registrationCommitteeItemId"
                          label="Registration Committee Item Id"
                          required
                        />
                      )}
                    {formikProps.values.documentTypeCode &&
                      DocTypesRequiringCommitteeMeeting.indexOf(formikProps.values.documentTypeCode) > -1 && (
                        <FormikField
                          component={FmuiTextField}
                          select
                          name="committeeMeetingId"
                          label="Committee Meeting"
                          fullWidth
                          required>
                          {meetings.map((m) => (
                            <MenuItem key={m.id} value={m.id}>
                              {m.meetingDate} — {m.location}
                            </MenuItem>
                          ))}
                        </FormikField>
                      )}
                    {formikProps.values.documentTypeCode === GeneratedDocTypeCode.ExemptionLetter && (
                      <FormikField
                        component={FmuiCheckboxWithLabel}
                        type="checkbox"
                        name="isExemptionRenewal"
                        Label={{ label: "Annual Exemption Renewal" }}
                      />
                    )}
                  </Stack>
                  {formikProps.values.documentTypeCode && (
                    <Stack>
                      <FormikField component={RadioGroup} name="generationActionType" required>
                        <FormControlLabel
                          value={"CurrentTemplate"}
                          control={<Radio />}
                          label={`Use the current template file in SharePoint (${selectedDocType?.templateFileName})`}
                        />
                        <FormControlLabel value={"UploadCustom"} control={<Radio />} label="Upload a template file" />
                        <FormControlLabel value={"FromTextbox"} control={<Radio />} label="Type something in Field Documentation below" />
                      </FormikField>
                    </Stack>
                  )}
                  {formikProps.values.generationActionType === "UploadCustom" && (
                    <Stack direction="row" spacing={3} alignItems="center">
                      <LoadingButton size="small" component="label" variant="outlined">
                        Select Template File
                        <input
                          id="template_upload"
                          type="file"
                          hidden
                          required={formikProps.values.generationActionType === "UploadCustom"}
                          accept=".dotx"
                          onChange={(event) => {
                            formikProps.setFieldValue("templateToTest", event.currentTarget.files?.[0]);
                          }}
                        />
                      </LoadingButton>
                      {formikProps.values.templateToTest && (
                        <Box sx={{ padding: 0 }}>
                          {formikProps.values.templateToTest.name} ({formikProps.values.templateToTest.size} bytes)
                        </Box>
                      )}
                    </Stack>
                  )}
                  <LoadingButton
                    size="small"
                    loading={generating}
                    variant="contained"
                    color="primary"
                    disabled={
                      !Boolean(formikProps.values.documentTypeCode) ||
                      !formikProps.isValid ||
                      (formikProps.values.generationActionType === "UploadCustom" && !formikProps.values.templateToTest)
                    }
                    onClick={async () => {
                      await formikProps.submitForm();
                    }}
                    sx={{ alignSelf: "flex-start" }}>
                    Generate
                  </LoadingButton>
                </Stack>
              </Card>
              <Paper sx={{ p: 3, flex: 1 }} className={classes.fieldDocPaper}>
                <Typography variant="h3">Field Documentation</Typography>
                <TabContext value={tabValue}>
                  <div className={classes.tabContext}>
                    <Box className={classes.tabHeaderColumn}>
                      <TabList onChange={handleTabChange} orientation="vertical" className={classes.tabs} variant="scrollable">
                        {_.orderBy(docGenSchema, (sch) => sch.dataSetName).map((dataset) => {
                          return (
                            <Tab
                              disabled={
                                selectedDocType && dataset.documentTypes.findIndex((f) => f.typeCode === selectedDocType.typeCode) === -1
                              }
                              className={classes.schemaTabHeader}
                              label={
                                <div>
                                  <span>{dataset.dataSetName}</span>
                                  <ul className={classes.typesListInHeader}>
                                    {_.orderBy(dataset.documentTypes, (dt) => dt.friendlyName).map((doctype: any) => {
                                      return <li>{doctype.friendlyName}</li>;
                                    })}
                                  </ul>
                                </div>
                              }
                              value={dataset.dataSetName}
                            />
                          );
                        })}
                      </TabList>
                    </Box>
                    {docGenSchema?.map((dataset) => {
                      return (
                        <TabPanel value={dataset.dataSetName} className={classes.tabPanel}>
                          <MergeFieldDisplay
                            key={dataset.dataSetType}
                            fields={dataset.fieldList}
                            onInsertClick={
                              formikProps.values.generationActionType === "FromTextbox"
                                ? (text) => insertMergeFieldText(text, formikProps)
                                : undefined
                            }
                            topLevel
                          />
                        </TabPanel>
                      );
                    })}
                    {formikProps.values.generationActionType === "FromTextbox" && (
                      <FormControl sx={{ mt: 2 }} className={classes.templateTextbox}>
                        <Typography variant="h4">Try some template text</Typography>
                        <FormikField
                          component={RichTextEditor}
                          name="customText"
                          label="Template to Run"
                          html={formikProps.values.customText}
                          passContentRetriever={(getContentAsHtml: any, getNewStateWithTextInserted: any) => {
                            customTextContentRetriever.current = {
                              getContentAsHtml,
                              getNewHtmlWithTextInserted: getNewStateWithTextInserted
                            };
                          }}
                          required
                          templateMarkup
                          error={
                            formikProps.errors.customText && formikProps.touched.customText ? formikProps.errors.customText : undefined
                          }
                        />
                      </FormControl>
                    )}
                  </div>
                </TabContext>
              </Paper>
            </Stack>
          )}
        </Formik>
      </Stack>
    </div>
  );
};

export default DocumentTemplateSandboxScreen;
