import React from "react";
import { NoticeStage, UpcomingReviewNotice } from "./models";
import _ from "lodash";
import { DataGridWithHeader } from "common/DataGridWithHeader";
import { dataGridDateValueFormatter } from "util/formats";
import { gql, useMutation } from "@apollo/client";
import { GetUpcomingReviewNotices } from "./UpcomingReviewNoticesScreen";
import { useNotifications } from "notifications";
import { datagridStyles } from "styles/common";
import { LoadingButton } from "@mui/lab";
import { makeStyles } from "../makeStyles";
import { GridColDef } from "@mui/x-data-grid-pro";
import PrLink from "../common/PrLink";

const useStyles = makeStyles()((theme) => ({
  ...datagridStyles(theme)
}));

interface Props {
  upcomingReviewNotices: UpcomingReviewNotice[];
  className?: string;
  pprpProgramReviews?: boolean;
}

export const UpcomingReviewNoticesWithInvalidContactEmails: React.FunctionComponent<Props> = (props) => {
  const { classes } = useStyles();
  const notifications = useNotifications();

  const [refreshAllMutate, refreshAllMutation] = useMutation<{ practiceReview: { refreshAllFromDataSource: boolean } }>(
    gql`
      mutation RefreshAllFromDataSource {
        practiceReview {
          refreshAllFromDataSource
        }
      }
    `,
    {
      onCompleted: () => notifications.success("Refreshed from CAMMS."),
      refetchQueries: [
        {
          query: GetUpcomingReviewNotices,
          variables: {
            stage: NoticeStage.GenerateNotices,
            pprpProgramReviews: props.pprpProgramReviews ?? false
          }
        }
      ]
    }
  );

  const columns: GridColDef[] = [
    {
      field: "practiceReview.prNumber",
      headerName: "PR No.",
      width: 80,
      renderCell: (params: { row: UpcomingReviewNotice }) => <PrLink practiceReview={params.row.practiceReview} />,
      disableColumnMenu: true
    },
    {
      field: "practiceReview.firm.name",
      headerName: "Firm",
      flex: 5,
      valueGetter: (params) => (params.row as UpcomingReviewNotice).practiceReview.firm.name
    },
    {
      field: "practiceReview.contactName",
      headerName: "PR Contact",
      flex: 3,
      valueGetter: (params) => (params.row as UpcomingReviewNotice).practiceReview.contactName
    },
    {
      field: "practiceReview.contactEmail",
      headerName: "Email",
      flex: 4,
      valueGetter: (params) => (params.row as UpcomingReviewNotice).practiceReview.contactEmail
    },
    {
      field: "practiceReview.reviewType",
      headerName: "Type",
      width: 140,
      valueGetter: (params: { row: UpcomingReviewNotice }) => params.row.practiceReview.reviewType
    },
    {
      field: "practiceReview.startDate",
      headerName: "Tentative Review Date",
      headerClassName: classes.wrapHeader,
      type: "date",
      width: 120,
      valueGetter: (params) => (params.row as UpcomingReviewNotice).practiceReview.startDate,
      valueFormatter: dataGridDateValueFormatter
    }
  ];

  const sortedNotices = _.orderBy(props.upcomingReviewNotices, (sn) => sn.practiceReview.startDate);

  return (
    <DataGridWithHeader
      title="Invalid Contact Emails"
      columns={columns}
      rows={sortedNotices}
      className={props.className}
      collapsible
      storageKey="Notices with invalid contact emails"
      headerActions={
        <LoadingButton
          onClick={() => refreshAllMutate()}
          variant="contained"
          color="primary"
          size="small"
          loading={refreshAllMutation.loading}>
          Refresh from CAMMS
        </LoadingButton>
      }
    />
  );
};
