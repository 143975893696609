import sanitizeHtml from "sanitize-html";
import React, { useEffect, useRef } from "react";
import { msWordUrl } from "../common/MicrosoftWordLink";

export function htmlHasContent(input?: string | null) {
  return input ? sanitizeHtml(input, { allowedTags: [] }).trim() !== "" : false;
}

export function getHtmlForField(contentRetriever: React.MutableRefObject<{ getContentAsHtml: () => string | null }>) {
  let html = contentRetriever.current.getContentAsHtml();
  if (!htmlHasContent(html)) {
    html = null;
  }

  return html;
}

export function useInterval(callback: any, delay: number) {
  // https://overreacted.io/making-setinterval-declarative-with-react-hooks/
  const savedCallback: any = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export function msExcelUrl(url: string) {
  return `ms-excel:ofe|u|${url}`;
}

export function getOpenableUrl(url: string) {
  return url.endsWith(".docx") ? msWordUrl(url) : url.endsWith(".xlsx") ? msExcelUrl(url) : url;
}
