import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  Tooltip
} from "@mui/material";
import ClosableDialogTitle from "common/ClosableDialogTitle";
import { gql, useMutation, useQuery } from "@apollo/client";
import * as Yup from "yup";
import { Field as FormikField, Form as FormikForm, Formik } from "formik";
import { RadioGroup, TextField as FmuiTextField, Select as FmuiSelect } from "formik-mui";
import { Validations } from "common/validations/common-yup-validations";
import { TabSection } from "./models";
import { MasterChecklistQuery } from "master-checklist/MasterChecklistScreen";
import { SectionHeaderToPrint } from "master-checklist";
import { ChecklistSectionTemplate } from "checklists";
import { QuestionTemplateFieldsFragment, ChecklistSectionTemplateFieldsFragment, SectionHeaderToPrintFieldsFragment } from "../queries";
import { LoadingButton } from "@mui/lab";
import { useNotifications } from "notifications";

const AddTabSectionMutation = gql`
  ${ChecklistSectionTemplateFieldsFragment}
  ${QuestionTemplateFieldsFragment}
  ${SectionHeaderToPrintFieldsFragment}
  mutation AddTabSection($section: TabSectionInput!) {
    masterChecklistQuestionContainers {
      addTabSection(section: $section) {
        id
        parentId
        sortOrder
        text
        isFirmSection
        isForPafPracticeReviews
        isForPprpProgramReviews
        sectionHeaderToPrint {
          ...SectionHeaderToPrintFields
        }
        questions {
          ...QuestionTemplateFields
        }
        ...ChecklistSectionTemplateFields
      }
    }
  }
`;

const EditTabSectionMutation = gql`
  ${ChecklistSectionTemplateFieldsFragment}
  ${QuestionTemplateFieldsFragment}
  ${SectionHeaderToPrintFieldsFragment}
  mutation EditTabSection($section: TabSectionInput!) {
    masterChecklistQuestionContainers {
      editTabSection(section: $section) {
        id
        parentId
        sortOrder
        text
        isFirmSection
        isForPafPracticeReviews
        isForPprpProgramReviews
        sectionHeaderToPrint {
          ...SectionHeaderToPrintFields
        }
        questions {
          ...QuestionTemplateFields
        }
        ...ChecklistSectionTemplateFields
      }
    }
  }
`;

const SectionHeadersToPrintQuery = gql`
  ${SectionHeaderToPrintFieldsFragment}
  query SectionHeadersToPrint {
    sectionHeadersToPrint {
      ...SectionHeaderToPrintFields
    }
  }
`;

interface Props {
  handleClose: () => void;
  confirmButtonText: string;
  section?: ChecklistSectionTemplate;
}

const EditTabSectionDialog = (props: Props) => {
  const notifications = useNotifications();

  const validationSchema = Yup.object().shape({
    text: Validations.requiredText(),
    shortDescription: Validations.requiredText(),
    isFirmSection: Validations.requiredText(),
    isForPprpProgramReviews: Validations.requiredText(),
    sectionHeaderToPrintId: Yup.number().min(1, "Select a section.")
  });

  const [addTabSection, { loading: loadingAdd }] = useMutation<
    { masterChecklistQuestionContainers: { addTabSection: TabSection } },
    { section: TabSection }
  >(AddTabSectionMutation, { refetchQueries: [{ query: MasterChecklistQuery }] });

  const [editTabSection, { loading: loadingEdit }] = useMutation<
    { masterChecklistQuestionContainers: { editTabSection: TabSection } },
    { section: TabSection }
  >(EditTabSectionMutation);

  const sectionHeadersToPrintQuery = useQuery<{ sectionHeadersToPrint: SectionHeaderToPrint[] }>(SectionHeadersToPrintQuery);

  const dialogTitle = Boolean(props.section) ? "Edit Tab" : "Add Tab";
  const mutate = Boolean(props.section) ? editTabSection : addTabSection;
  const loading = Boolean(props.section) ? loadingEdit : loadingAdd;

  return (
    <Dialog open={true} onClose={props.handleClose} fullWidth={true} scroll="paper" maxWidth="sm">
      <ClosableDialogTitle onClose={props.handleClose}>{dialogTitle}</ClosableDialogTitle>
      <Formik
        initialValues={{
          text: props.section?.text ?? "",
          shortDescription: props.section?.shortDescription ?? "",
          isFirmSection: props.section?.isFirmSection ? "true" : "false",
          isForPprpProgramReviews: props.section?.isForPprpProgramReviews ? "true" : "false",
          sectionHeaderToPrintId: props.section?.sectionHeaderToPrint?.id ?? 0
        }}
        onSubmit={async (values) => {
          const section: TabSection = {
            id: props.section?.id,
            text: values.text,
            shortDescription: values.shortDescription,
            isFirmSection: values.isFirmSection === "true",
            isForPafPracticeReviews: values.isForPprpProgramReviews === "false",
            isForPprpProgramReviews: values.isForPprpProgramReviews === "true",
            sectionHeaderToPrintId: Number(values.sectionHeaderToPrintId)
          };

          const result = await mutate({
            variables: { section }
          });

          if ((result.errors?.length ?? 0) === 0) {
            notifications.success("Updated tab section.");
          }

          props.handleClose();
        }}
        validationSchema={validationSchema}>
        {(formikProps) => (
          <FormikForm>
            <DialogContent>
              <Grid container columnSpacing={3}>
                <Grid item xs={12}>
                  <FormikField component={FmuiTextField} name="text" label="Description" fullWidth required />
                </Grid>
                <Grid item xs={12}>
                  <FormikField component={FmuiTextField} name="shortDescription" label="Short Description" fullWidth required />
                </Grid>

                <Grid item xs={6}>
                  <Box sx={{ mt: 1, mb: 1 }}>
                    <FormikField component={RadioGroup} name="isFirmSection" required>
                      <FormControlLabel value={"false"} control={<Radio />} label="Individual Client Files" />
                      <Tooltip
                        title={
                          props.section?.isAssignedToEngagementTypes
                            ? "Remove this tab from all engagement types before switching it to a firm-wide tab."
                            : ""
                        }>
                        <span>
                          <FormControlLabel
                            value={"true"}
                            control={<Radio />}
                            label="Firm-wide"
                            disabled={props.section?.isAssignedToEngagementTypes}
                          />
                        </span>
                      </Tooltip>
                    </FormikField>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box sx={{ mt: 1, mb: 1 }}>
                    <FormikField component={RadioGroup} name="isForPprpProgramReviews" required>
                      <FormControlLabel value={"false"} control={<Radio />} label="PAF Practice Reviews" />
                      <FormControlLabel value={"true"} control={<Radio />} label="PPRP Program Reviews" />
                    </FormikField>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <FormControl variant="outlined" fullWidth margin="normal">
                    <FormikField
                      component={FmuiTextField}
                      select
                      name="sectionHeaderToPrintId"
                      label="Section to Print on Reports"
                      fullWidth
                      required>
                      {sectionHeadersToPrintQuery.data?.sectionHeadersToPrint.map((item) => (
                        <MenuItem value={item.id} key={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </FormikField>
                  </FormControl>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={props.handleClose}>Cancel</Button>
              <LoadingButton color="primary" variant="contained" loading={loading} onClick={() => formikProps.submitForm()}>
                {props.confirmButtonText}
              </LoadingButton>
            </DialogActions>
          </FormikForm>
        )}
      </Formik>
    </Dialog>
  );
};

export default EditTabSectionDialog;
