import React, { useEffect, useState } from "react";
import { PracticeReview, PrScreenQuery } from "practice-reviews";
import { formatCurrency } from "util/formats";
import { Grid, Tooltip } from "@mui/material";

import { staticDataStyles } from "../styles/common";
import StackedStaticDataDisplay from "../common/StackedStaticDataDisplay";
import { LoadingButton } from "@mui/lab";
import { makeStyles } from "../makeStyles";
import { gql, useMutation } from "@apollo/client";
import { useCurrentUser, Permissions } from "users";

const useStyles = makeStyles()((theme) => ({
  ...staticDataStyles(theme),
  header: {
    display: "flex"
  },
  headerDetails: {
    display: "flex",
    marginRight: "auto",
    alignItems: "center",
    ":first-child": {
      marginRight: theme.spacing(8)
    },
    "& > *": {
      marginRight: theme.spacing(4)
    }
  },
  headerActions: {
    display: "flex",
    marginLeft: "auto",
    alignItems: "center",
    "& > *": {
      marginLeft: theme.spacing(4)
    }
  }
}));

interface Props {
  practiceReview: PracticeReview;
}

const PostPendingInvoiceToCammsMutation = gql`
  mutation PostPendingInvoiceMutation($practiceReviewId: Int!) {
    practiceReview {
      postPendingInvoice(practiceReviewId: $practiceReviewId) {
        id
        invoiceAmount
        invoiceGst
        invoiceTotal
      }
    }
  }
`;

export const TimeEntriesHeader: React.FunctionComponent<Props> = (props) => {
  const { classes } = useStyles();
  const { userHasPermission } = useCurrentUser();
  const [isButtonEnabled, setIsButtonEnabled] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [mutate, { loading }] = useMutation<
    { practiceReview: { __typename: string; postPendingInvoice: PracticeReview } },
    { practiceReviewId: number }
  >(PostPendingInvoiceToCammsMutation, {
    refetchQueries: [{ query: PrScreenQuery, variables: { prNumber: props.practiceReview.prNumber } }]
  });
  useEffect(() => {
    if (props.practiceReview.timeEntries.length === 0) {
      setIsButtonEnabled(false);
      setErrorMessage("There are no time entries to post to the database.");
    } else if (props.practiceReview.invoiceIsFinal) {
      setIsButtonEnabled(false);
      setErrorMessage("The Practice Review's invoice has been finalized.");
    } else if (props.practiceReview.timeEntries.map((te) => te.user.cammsEntityNumber).some((c) => c === null)) {
      setIsButtonEnabled(false);
      setErrorMessage("One or more of the users submitting time entries do not have CAMMS Entity Numbers in the database.");
    } else {
      setIsButtonEnabled(true);
      setErrorMessage("");
    }
  }, [props.practiceReview]);

  return (
    <Grid container direction="column">
      <Grid item className={classes.header}>
        <div className={classes.headerDetails}>
          {!props.practiceReview.isPprpProgramReview && (
            <StackedStaticDataDisplay label="Estimated Hours" value={`${props.practiceReview.estimate.finalEstimate ?? 0}h`} />
          )}
          <StackedStaticDataDisplay label="Total Hours" value={`${props.practiceReview.actualTimeToDate ?? 0}h`} important={true} />
          {userHasPermission(Permissions.CammsViewAndGenerateInvoice) && (
            <>
              <StackedStaticDataDisplay
                label="Invoice Amount"
                value={`${formatCurrency(props.practiceReview.invoiceAmount, true) ?? "(pending)"}`}
                className={props.practiceReview.invoiceAmount ? undefined : classes.muted}
                tooltip={
                  props.practiceReview.invoiceAmount && props.practiceReview.actualTimeToDate
                    ? `Rate: ${formatCurrency(props.practiceReview.invoiceAmount / props.practiceReview.actualTimeToDate, true)}/h`
                    : undefined
                }
              />
              <StackedStaticDataDisplay
                label="GST"
                value={`${formatCurrency(props.practiceReview.invoiceGst, true) ?? "(pending)"}`}
                className={props.practiceReview.invoiceGst ? undefined : classes.muted}
              />
              <StackedStaticDataDisplay
                label="Total Amount"
                value={`${formatCurrency(props.practiceReview.invoiceTotal, true) ?? "(pending)"}`}
                className={props.practiceReview.invoiceTotal ? undefined : classes.muted}
              />
            </>
          )}
        </div>
        <div className={classes.headerActions}>
          {userHasPermission(Permissions.CammsViewAndGenerateInvoice) && (
            <div>
              <Tooltip title={errorMessage}>
                <div>
                  <LoadingButton
                    onClick={() => {
                      mutate({ variables: { practiceReviewId: props.practiceReview.id } });
                    }}
                    color={isButtonEnabled ? "primary" : "error"}
                    variant="outlined"
                    loading={loading}
                    disabled={!isButtonEnabled}>
                    Post to Database
                  </LoadingButton>
                </div>
              </Tooltip>
            </div>
          )}
        </div>
      </Grid>
    </Grid>
  );
};
