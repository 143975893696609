import { useLazyQuery } from "@apollo/client";
import { TabContext, TabPanel } from "@mui/lab";
import { getFullAppBarHeight } from "chrome/SiteAppBar";
import { Grid, Skeleton, Tab, Tabs, Typography } from "@mui/material";
import { DateTime } from "luxon";
import { makeStyles } from "makeStyles";
import { MonitoringTab } from "monitoring/MonitoringTab";
import { useNotifications } from "notifications";
import React, { createContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useHistory, useParams, useRouteMatch } from "react-router";
import { RegistrationCommitteeItem } from "registration-committee-items";
import { AppRoute } from "Routes";
import EditRegistrationCommitteeItemTab from "./EditRegistrationCommitteeItemTab";
import { FetchRegistrationCommitteeItemByIdQuery } from "./queries";
import RegistrationCommitteeItemDecisionTab from "./RegistrationCommitteeItemDecisionTab";

export const RegistrationCommitteeItemContext = createContext<{ registrationCommitteeItem: RegistrationCommitteeItem | undefined }>({
  registrationCommitteeItem: undefined
});

export enum RegistrationCommitteeItemTabs {
  Overview = "overview",
  Decision = "decision",
  Monitoring = "monitoring"
}

export function getRouteForRegistrationCommitteeItem(item?: RegistrationCommitteeItem, tab?: RegistrationCommitteeItemTabs) {
  return item ? getRouteForRegistrationCommitteeItemId(item.id, tab) : "";
}

export function getRouteForRegistrationCommitteeItemId(itemId: number, tab?: RegistrationCommitteeItemTabs) {
  if (tab === RegistrationCommitteeItemTabs.Decision) {
    return AppRoute.RegistrationCommitteeItemDecision.replace(":id", itemId.toString());
  } else return AppRoute.RegistrationCommitteeItem.replace(":id", itemId.toString()).replace("/:tab?", tab ? `/${tab}` : "");
}

const useStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    margin: theme.spacing(-3),
    minHeight: "100vh",
    backgroundColor: theme.palette.common.white,
    "& .MuiTabPanel-root": {
      flexGrow: 1,
      height: "100%"
    }
  },
  content: {
    flexGrow: 1,
    display: "flex",
    alignItems: "stretch"
  },
  tabContent: {
    flexGrow: 1,
    borderLeft: `1px solid ${theme.palette.divider}`,
    minHeight: "100vh"
  },
  tabs: {
    alignSelf: "flex-start",
    position: "fixed",
    top: getFullAppBarHeight(theme),
    left: 0,
    width: "11em",
    minHeight: `calc(100vh - ${getFullAppBarHeight(theme)})`,
    backgroundColor: theme.palette.primary.main,
    flexShrink: 0,
    "& .MuiTabs-flexContainer": {
      alignItems: "stretch"
    },
    "& .MuiTab-root": {
      color: theme.palette.common.white,
      minWidth: "unset",
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      alignItems: "flex-start",
      textAlign: "left",
      "&:hover": {
        backgroundColor: theme.palette.primary.dark
      },
      "&.Mui-selected": {
        backgroundColor: theme.palette.primary.dark
      }
    },
    "& .MuiTabs-indicator": {
      display: "none"
    }
  },
  unpaddedTabPanel: {
    padding: 0
  }
}));

const RegistrationCommitteeItemScreen: React.FunctionComponent = () => {
  const { classes } = useStyles();
  const notifications = useNotifications();
  const history = useHistory();

  const routingParams = useParams<{ id?: string; tab?: string }>();
  const decisionRouteMatch = useRouteMatch(AppRoute.RegistrationCommitteeItemDecision);
  const routeTab = decisionRouteMatch ? RegistrationCommitteeItemTabs.Decision : routingParams.tab;
  const itemId = Number(routingParams.id) || null;

  const [activeTab, setActiveTab] = useState(routeTab ?? RegistrationCommitteeItemTabs.Overview);
  if (routeTab !== undefined && activeTab !== routeTab) {
    setActiveTab(() => routeTab as string);
  }

  const [queryItem, itemQuery] = useLazyQuery<{ registrationCommitteeItemById: RegistrationCommitteeItem }, { id: number }>(
    FetchRegistrationCommitteeItemByIdQuery
  );
  const item = routingParams.id ? itemQuery.data?.registrationCommitteeItemById : undefined;

  useEffect(() => {
    if (itemId) {
      queryItem({ variables: { id: itemId }, fetchPolicy: "cache-and-network" });
    } else if (routingParams.id) {
      notifications.error(`Invalid item id ${routingParams.id}`);
    }
  }, [itemId, routingParams.id]);

  const showDecisionTab =
    item &&
    item.committeeMeeting &&
    item.committeeMeeting.binderGenerated &&
    DateTime.fromISO(item.committeeMeeting.meetingDate) < DateTime.now().startOf("day").plus({ days: 1 });
  const showMonitoringTab = item && item.isCompleted && item.decision?.monitoringRequired;

  return (
    <RegistrationCommitteeItemContext.Provider value={{ registrationCommitteeItem: item }} key={item?.id}>
      <div className={classes.root}>
        <Helmet>
          <title>{item ? `Registration Committee Item ${item.id}` : `Registration Committee Item`} - PRS Online</title>
        </Helmet>

        {(itemQuery.loading && !itemQuery.data) || (!item && itemId) ? (
          <Grid container direction="column" spacing={3} sx={{ p: 3 }}>
            <Grid item xs={6}>
              <Skeleton height="400px" width="100%" sx={{ transform: "unset" }} />
            </Grid>
            <Grid item xs={6}>
              <Skeleton height="400px" width="100%" sx={{ transform: "unset" }} />
            </Grid>
          </Grid>
        ) : (
          <div className={classes.content}>
            {item && (
              <Tabs
                value={activeTab}
                indicatorColor="primary"
                textColor="primary"
                orientation="vertical"
                className={classes.tabs}
                onChange={(e, newTab) => {
                  setActiveTab(newTab);
                  history.push(getRouteForRegistrationCommitteeItem(item, newTab));
                }}>
                <Tab label="Overview" value={RegistrationCommitteeItemTabs.Overview} />
                {showDecisionTab && <Tab label="Decision" value={RegistrationCommitteeItemTabs.Decision} />}
                {showMonitoringTab && <Tab label="Monitoring" value={RegistrationCommitteeItemTabs.Monitoring} />}
              </Tabs>
            )}
            <div className={classes.tabContent} style={{ marginLeft: item ? "11em" : undefined }}>
              <TabContext value={activeTab}>
                <TabPanel value={RegistrationCommitteeItemTabs.Overview}>
                  <EditRegistrationCommitteeItemTab item={item} />
                </TabPanel>
                {showDecisionTab && (
                  <TabPanel value={RegistrationCommitteeItemTabs.Decision}>
                    <Typography variant="h1" mb={1}>
                      Decision
                    </Typography>
                    <Typography variant="h4" mb={2}>
                      {item.itemType.name} ({item.itemType.category.name}) for <strong>{item.registeredName}</strong>
                    </Typography>
                    <RegistrationCommitteeItemDecisionTab item={item!} />
                  </TabPanel>
                )}
                {showMonitoringTab && (
                  <TabPanel value={RegistrationCommitteeItemTabs.Monitoring}>
                    <Typography variant="h1" mb={1}>
                      Monitoring
                    </Typography>
                    <Typography variant="h4" mb={2}>
                      {item.itemType.name} ({item.itemType.category.name}) for <strong>{item.registeredName}</strong>
                    </Typography>
                    <MonitoringTab reviewOrItem={item} containerType="item" />
                  </TabPanel>
                )}
              </TabContext>
            </div>
          </div>
        )}
      </div>
    </RegistrationCommitteeItemContext.Provider>
  );
};

export default RegistrationCommitteeItemScreen;
