export enum MergeFieldType {
  HtmlForReview = "HtmlForReview",
  HtmlForMeeting = "HtmlForMeeting",
  HtmlForProgramTask = "HtmlForProgramTask",
  HtmlForRegComItem = "HtmlForRegComItem",
  DecisionLetter = "DecisionLetter",
  MeetingMinutes = "MeetingMinutes",
  ProgramReview = "ProgramReview",
  RegComItem = "RegComItem",
  RegComMeetingItem = "RegComMeetingItem"
}

export enum MergeFieldBehavior {
  Field = "Field",
  Boolean = "Boolean",
  List = "List",
  Document = "Document",
  RichTextHtml = "RichTextHtml",
  Object = "Object"
}

export interface EnhancedMergeField {
  id?: string;
  field: string;
  type: MergeFieldBehavior;
  description?: string;
  children?: EnhancedMergeField[];
}

export interface HTMLMergeFields {
  htmlMergeReviewSingleFields: string[];
  htmlMergeReviewChildFields: string[];
  htmlMergeReviewRepeatedValueWrappers: string[];
  htmlMergeReviewShowHideWrappers: string[];
  htmlMergeMeetingFields: string[];
  htmlMergeProgramTaskFields: string[];
  htmlMergeRegComItemFields: string[];
  decisionLettersDataFields: EnhancedMergeField[];
  committeeMeetingDataFields: EnhancedMergeField[];
  programReviewDataFields: EnhancedMergeField[];
  regComItemDataFields: EnhancedMergeField[];
  regComMeetingItemDataFields: EnhancedMergeField[];
  htmlMergeFieldOpener: string;
  htmlMergeFieldCloser: string;
  asposeMergeFieldOpener: string;
  asposeMergeFieldCloser: string;
}
