import { useMutation, useQuery } from "@apollo/client";
import {
  Autocomplete,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField
} from "@mui/material";

import { Formik, Form as FormikForm, Field as FormikField } from "formik";
import React, { useMemo } from "react";
import ClosableDialogTitle from "../common/ClosableDialogTitle";
import { EditableEngagementTypeTemplate, EngagementGroup, EngagementTypeTemplate } from "./models";
import * as Yup from "yup";
import { TextField as FmuiTextField, Checkbox as FmuiCheckbox } from "formik-mui";
import { Validations } from "../common/validations/common-yup-validations";
import { LoadingButton } from "@mui/lab";
import { ChecklistSectionTemplate, isContainerTemplate, MasterChecklist, QuestionContainerTemplate } from "../checklists";
import { buildChecklist } from "../checklists/buildChecklist";
import _ from "lodash";
import {
  AddEngagementTypeTemplateMutation,
  EditEngagementTypeTemplateMutation,
  FetchEngagementGroupsQuery,
  FetchEngagementTypeTemplatesQuery,
  FetchMasterChecklistTabsQuery
} from "./queries";

interface Props {
  handleClose: () => void;
  title: string;
  id?: number;
  name?: string;
  engagementGroup?: EngagementGroup;
  isPublic?: boolean;
  isActive?: boolean;
  existingNames: string[];
  questionContainerTemplates?: QuestionContainerTemplate[];
  adding: boolean;
}

interface FormValues {
  name: string;
  engagementGroupId: number | null;
  isPublic: boolean;
  isActive: boolean;
  tabIds: number[];
}

const EditEngagementTypeTemplateDialog: React.FunctionComponent<Props> = (props) => {
  const masterChecklistQuestionContainersQuery =
    useQuery<{ masterChecklistQuestionContainers: QuestionContainerTemplate[] }>(FetchMasterChecklistTabsQuery);
  const masterChecklistQuestionContainers = useMemo(
    () => masterChecklistQuestionContainersQuery.data?.masterChecklistQuestionContainers ?? [],
    [masterChecklistQuestionContainersQuery.data]
  );
  const masterChecklistTabs: ChecklistSectionTemplate[] = masterChecklistQuestionContainers
    .filter((qc) => !qc.parentId)
    .map((qc) => qc as ChecklistSectionTemplate);

  const checklist = useMemo(() => {
    const builtChecklist = buildChecklist(masterChecklistQuestionContainers as any, true) as unknown;
    return builtChecklist as MasterChecklist;
  }, [masterChecklistQuestionContainers]);

  const engagementGroupsQuery = useQuery<{ engagementGroups: EngagementGroup[] }>(FetchEngagementGroupsQuery);
  const engagementGroups = engagementGroupsQuery.data?.engagementGroups ?? [];

  const [addEngagementTypeTemplate, { loading: loadingAdd }] = useMutation<
    { engagementTypeTemplates: { add: EngagementTypeTemplate } },
    { engagementTypeTemplate: EditableEngagementTypeTemplate }
  >(AddEngagementTypeTemplateMutation, {
    refetchQueries: [{ query: FetchEngagementTypeTemplatesQuery }]
  });

  const [editEngagementTypeTemplate, { loading: loadingEdit }] = useMutation<
    { engagementTypeTemplates: { update: EngagementTypeTemplate } },
    { engagementTypeTemplate: EditableEngagementTypeTemplate }
  >(EditEngagementTypeTemplateMutation);

  function getChildContainerIds(section: ChecklistSectionTemplate) {
    const childContainerIds: number[] = [];

    section.children.forEach((child) => {
      if (isContainerTemplate(child)) {
        childContainerIds.push(child.id);
        getChildContainerIds(child as ChecklistSectionTemplate).forEach((childId) => childContainerIds.push(childId));
      }
    });

    return childContainerIds;
  }

  const mutate = Boolean(props.id) ? editEngagementTypeTemplate : addEngagementTypeTemplate;
  const loading = Boolean(props.id) ? loadingEdit : loadingAdd;

  async function save(values: FormValues) {
    const selectedTabs = values.tabIds.map((tabId) => checklist.tabSections.find((ts) => ts.id === tabId)!);
    const childContainerIdsForSelectedTabs = selectedTabs.flatMap((tab) => getChildContainerIds(tab));
    const allContainerIdsForSelectedTabs = values.tabIds.concat(childContainerIdsForSelectedTabs);

    const engagementTypeTemplate: EditableEngagementTypeTemplate = {
      id: props.id,
      name: values.name!,
      engagementGroupId: values.engagementGroupId!,
      isPublic: values.isPublic,
      isActive: values.isActive,
      questionContainerTemplateIds: allContainerIdsForSelectedTabs
    };

    await mutate({
      variables: {
        engagementTypeTemplate
      }
    });

    props.handleClose();
  }

  const initialValues: FormValues = {
    name: props.name ?? "",
    engagementGroupId: props.engagementGroup?.id ?? null,
    isPublic: props.isPublic ?? false,
    isActive: props.isActive ?? false,
    tabIds: props.questionContainerTemplates?.filter((qct) => !qct.parentId).map((qc) => qc.id) ?? []
  };

  const validationSchema = Yup.object().shape({
    name: Validations.requiredUniqueString(props.existingNames),
    engagementGroupId: Validations.requiredNumber(),
    tabIds: Yup.array()
      .of(Yup.number())
      .test("consistent-tab-types", "Tabs must be either all PAF tabs or all PPRP tabs.", function (ids?: (number | undefined)[]) {
        const selectedTabs = masterChecklistTabs.filter((tab) => (ids ?? []).indexOf(tab.id) !== -1);
        return selectedTabs.every((tab) => tab.isForPafPracticeReviews) || selectedTabs.every((tab) => tab.isForPprpProgramReviews);
      })
  });

  return (
    <Dialog open={true} onClose={props.handleClose} fullWidth={true} scroll="paper" maxWidth="md">
      <ClosableDialogTitle onClose={props.handleClose}>{props.title}</ClosableDialogTitle>
      <Formik initialValues={initialValues} onSubmit={save} validationSchema={validationSchema}>
        {(formikProps) => (
          <FormikForm>
            <DialogContent>
              <Grid container>
                <Grid item xs={12}>
                  <FormikField component={FmuiTextField} name="name" label="Name" fullWidth required />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <FormikField component={FmuiTextField} name="engagementGroupId" label="Group" fullWidth required select>
                    {engagementGroups.map((group) => (
                      <MenuItem key={group.id} value={group.id}>
                        {group.name}
                      </MenuItem>
                    ))}
                  </FormikField>
                </Grid>
                <Grid container>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={<FormikField component={FmuiCheckbox} type="checkbox" name="isPublic" />}
                      label="Public Company"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel control={<FormikField component={FmuiCheckbox} type="checkbox" name="isActive" />} label="Active" />
                  </Grid>
                  <Grid item xs={12}>
                    <Autocomplete
                      multiple
                      filterSelectedOptions
                      options={_.orderBy(masterChecklistTabs, (tab) => tab.shortDescription)}
                      getOptionLabel={(tab) => tab.shortDescription}
                      renderTags={(tabs: ChecklistSectionTemplate[], getTagProps: any) =>
                        tabs.map((tab: ChecklistSectionTemplate, index: number) => (
                          <Chip key={tab.id} label={tab.shortDescription} size="small" {...getTagProps({ index })} />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Tabs"
                          error={formikProps.touched.tabIds && Boolean(formikProps.errors.tabIds)}
                          helperText={formikProps.touched.tabIds && formikProps.errors.tabIds}
                        />
                      )}
                      value={_.orderBy(
                        masterChecklistTabs.filter((tab) => formikProps.values.tabIds.some((tabId) => tabId === tab.id)),
                        (tab) => tab.sortOrder
                      )}
                      onChange={(e, newTabs) => {
                        formikProps.setFieldValue(
                          "tabIds",
                          newTabs.map((tab) => tab.id)
                        );
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={props.handleClose}>Cancel</Button>
              <LoadingButton color="primary" variant="contained" loading={loading} onClick={() => formikProps.submitForm()}>
                {props.adding ? "Add" : "Save"}
              </LoadingButton>
            </DialogActions>
          </FormikForm>
        )}
      </Formik>
    </Dialog>
  );
};

export default EditEngagementTypeTemplateDialog;
