import React, { useRef, useMemo } from "react";
import { useMutation } from "@apollo/client";
import { Stack } from "@mui/material";
import { Formik, Form as FormikForm } from "formik";
import MonitoringHeaderStack from "./MonitoringHeaderStack";
import MonitoringCard from "./MonitoringCard";
import { LoadingButton } from "@mui/lab";
import { SaveMonitoringMutation } from "./queries";
import { useNotifications } from "notifications";
import { PracticeReview } from "practice-reviews";
import { DocType } from "common/DocType";
import { Monitoring, MonitoringInput } from "./models";
import { makeStyles } from "makeStyles";
import { useUnsavedChanges } from "UnsavedChangesProvider";
import RichTextEditor from "common/RichTextEditor";
import { RegistrationCommitteeItem } from "registration-committee-items";

const useStyles = makeStyles()({
  headerStack: {
    width: "75%",
    marginTop: "8px"
  }
});

interface Props {
  reviewOrItem: PracticeReview | RegistrationCommitteeItem;
  containerType: "review" | "item";
}

interface FormValues {
  monitoringFrequencyInDays: number;
  monitoringOccurrencesRemaining: number;
  monitorings: Monitoring[];
  containerMonitoringNotes: string | null;
}

export const MonitoringTab: React.FunctionComponent<Props> = (props) => {
  const { classes } = useStyles();
  const { reviewOrItem, containerType } = props;
  const notifications = useNotifications();
  const { unsavedChanges, changesSaved } = useUnsavedChanges();

  const [saveMonitoring, { loading }] = useMutation<
    { monitoring: { save: Monitoring[] } },
    { practiceReviewId: number | null; registrationCommitteeItemId: number | null; monitoringInput: MonitoringInput }
  >(SaveMonitoringMutation, {
    onCompleted: () => {
      notifications.success("Monitoring saved.");
      changesSaved();
    },
    onError: (error) => {
      notifications.error(error.message || "An error occurred.");
    }
  });

  const notesContentRetriever = useRef<{ getContentAsHtml: () => string | null }>({ getContentAsHtml: () => null });

  const currentMonitoringPeriod = reviewOrItem.monitorings?.filter((m) => !m.isCompleted)[0];
  const lastCompletedMonitoringDate = reviewOrItem.monitorings?.filter((m) => m.isCompleted).pop()?.completedDate;
  const monitoringLetters = useMemo(
    () =>
      Object.fromEntries(
        reviewOrItem.attachedDocuments.filter((ad) => ad.type === DocType.MonitoringCompleteLetter).map((ad) => [ad.id, ad])
      ),
    [reviewOrItem.attachedDocuments]
  );

  const initialValues: FormValues = {
    monitoringFrequencyInDays: reviewOrItem.monitoringFrequencyInDays!,
    monitoringOccurrencesRemaining: reviewOrItem.monitoringOccurrencesRemaining!,
    monitorings: reviewOrItem.monitorings ?? [],
    containerMonitoringNotes: reviewOrItem.monitoringNotes
  };

  async function save(values: FormValues) {
    const monitoringToEdit = values.monitorings[values.monitorings.length - 1];
    const input = {
      practiceReviewId: containerType === "review" ? reviewOrItem.id : null,
      registrationCommitteeItemId: containerType === "item" ? reviewOrItem.id : null,
      monitoringInput: {
        monitoringFrequencyInDays: values.monitoringFrequencyInDays,
        monitoringOccurrencesRemaining: Number(values.monitoringOccurrencesRemaining),
        id: monitoringToEdit?.id,
        isPass: monitoringToEdit?.isPass,
        sendToCommitteeOnFail: monitoringToEdit?.sendToCommitteeOnFail,
        monitoringNotes: monitoringToEdit?.monitoringNotes,
        containerMonitoringNotes: notesContentRetriever.current?.getContentAsHtml()
      } as MonitoringInput
    };

    await saveMonitoring({ variables: input });
  }

  return (
    <Formik initialValues={initialValues} enableReinitialize onSubmit={save}>
      {({ values, handleSubmit, setFieldValue }) => (
        <FormikForm onSubmit={handleSubmit} onChange={() => unsavedChanges()}>
          <Stack gap={4}>
            <MonitoringHeaderStack
              direction="row"
              justifyContent="start"
              alignItems="center"
              gap={4}
              monitoringFrequencyInDays={values.monitoringFrequencyInDays}
              monitoringOccurrencesRemaining={values.monitoringOccurrencesRemaining}
              currentMonitoringPeriodDate={currentMonitoringPeriod?.monitoringDate}
              createdDate={reviewOrItem.completedDate}
              monitoringCompleted={!!reviewOrItem.monitoringCompleted}
              monitoringCompletedOnDate={lastCompletedMonitoringDate}
              setFieldValue={setFieldValue}
              className={classes.headerStack}
            />
            <Stack gap={4}>
              {values.monitorings.map((monitoring, index) => (
                <MonitoringCard
                  key={monitoring.id}
                  index={index}
                  monitoring={monitoring}
                  setFieldValue={setFieldValue}
                  monitoringCompletedLetter={
                    monitoring.monitoringCompletedLetterId && monitoringLetters.hasOwnProperty(monitoring.monitoringCompletedLetterId)
                      ? monitoringLetters[monitoring.monitoringCompletedLetterId]
                      : undefined
                  }
                  monitoringFailedWorkflowReadOnly={!!reviewOrItem.monitoringCompleted || index !== values.monitorings.length - 1}
                  containerType={containerType}
                />
              ))}
            </Stack>
            <RichTextEditor
              label="Notes"
              minHeight="6em"
              html={values.containerMonitoringNotes}
              passContentRetriever={(getContentAsHtml) => {
                notesContentRetriever.current = { getContentAsHtml };
              }}
              reportUnsavedChanges
              hideToolbar
            />
            <Stack justifyContent="end" direction="row">
              <LoadingButton color="primary" variant="contained" type="submit" loading={loading}>
                Save
              </LoadingButton>
            </Stack>
          </Stack>
        </FormikForm>
      )}
    </Formik>
  );
};
