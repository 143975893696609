import React from "react";
import { Button, Dialog, DialogActions, DialogContent, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import ClosableDialogTitle from "common/ClosableDialogTitle";
import { gql, useMutation } from "@apollo/client";
import { InaType, InaTypeInput } from "./models";
import * as Yup from "yup";
import { Field as FormikField, Form as FormikForm, Formik } from "formik";
import { Select as FmuiSelect, TextField as FmuiTextField, Autocomplete, AutocompleteRenderInputParams } from "formik-mui";
import { Validations } from "common/validations/common-yup-validations";
import { UserRole } from "users";
import { LoadingButton } from "@mui/lab";
import { useNotifications } from "notifications";

const EditInaTypeMutation = gql`
  mutation EditInaType($inaType: InaTypeInput!) {
    inaTypes {
      update(inaType: $inaType) {
        id
        friendlyName
        assignedToRoleNormally {
          id
          name
        }
        assignedToRoleForNonAssuranceReviews {
          id
          name
        }
        assignedToRoleForPprpProgramReviews {
          id
          name
        }
        reassignableToRoles {
          id
          name
        }
      }
    }
  }
`;

interface Props {
  handleClose: () => void;
  inaType: InaType;
  userRoles: UserRole[];
}

interface FormValues {
  friendlyName: string;
  assignedToRoleIdNormally: number | null;
  assignedToRoleIdForNonAssuranceReviews: number | null;
  assignedToRoleIdForPprpProgramReviews: number | null;
  reassignableToRoleIds: number[];
}

const EditWorkflowInaDialog = (props: Props) => {
  const notifications = useNotifications();

  const validationSchema = Yup.object({
    friendlyName: Validations.requiredText()
  });

  const [mutate, { loading }] = useMutation<{ inaTypes: { update: InaType } }, { inaType: InaTypeInput }>(EditInaTypeMutation);

  const listUserRoles = (userRole: UserRole) => (
    <MenuItem value={userRole.id} key={userRole.id}>
      {userRole.name}
    </MenuItem>
  );

  const initialFormValues: FormValues = {
    friendlyName: props.inaType.friendlyName,
    assignedToRoleIdNormally: props.inaType.assignedToRoleNormally?.id ?? null,
    assignedToRoleIdForNonAssuranceReviews: props.inaType.assignedToRoleForNonAssuranceReviews?.id ?? null,
    assignedToRoleIdForPprpProgramReviews: props.inaType.assignedToRoleForPprpProgramReviews?.id ?? -1,
    reassignableToRoleIds: props.inaType.reassignableToRoles?.map((ur) => ur.id) ?? []
  };

  return (
    <Dialog open={true} onClose={props.handleClose} fullWidth={true} scroll="paper" maxWidth="sm">
      <ClosableDialogTitle onClose={props.handleClose}>Edit Workflow INA Type</ClosableDialogTitle>
      <Formik
        initialValues={initialFormValues}
        onSubmit={async (values) => {
          let inaType: InaTypeInput = {
            id: props.inaType.id,
            friendlyName: values.friendlyName,
            assignedToRoleIdNormally: Number(values.assignedToRoleIdNormally),
            assignedToRoleIdForNonAssuranceReviews: Number(values.assignedToRoleIdForNonAssuranceReviews),
            assignedToRoleIdForPprpProgramReviews:
              values.assignedToRoleIdForPprpProgramReviews !== -1 ? Number(values.assignedToRoleIdForPprpProgramReviews) : null,
            reassignableToRoleIds: values.reassignableToRoleIds
          };

          const result = await mutate({
            variables: { inaType }
          });

          if (result.data?.inaTypes.update) {
            notifications.success("Saved INA type.");
          }

          props.handleClose();
        }}
        validationSchema={validationSchema}>
        {(formikProps) => (
          <>
            <DialogContent>
              <FormikForm>
                <Grid container direction="column">
                  <Grid item>
                    <FormikField component={FmuiTextField} name="friendlyName" label="Description" fullWidth required />
                  </Grid>
                  <Grid item>
                    <FormControl variant="outlined" fullWidth size="small">
                      <FormikField component={FmuiTextField} select name="assignedToRoleIdNormally" label="Assign To (Default)">
                        {props.userRoles.map(listUserRoles)}
                      </FormikField>
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <FormControl variant="outlined" fullWidth size="small">
                      <FormikField
                        component={FmuiTextField}
                        select
                        name="assignedToRoleIdForNonAssuranceReviews"
                        label="Assign To (Non-assurance PAF Reviews)">
                        {props.userRoles.map(listUserRoles)}
                      </FormikField>
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <FormControl variant="outlined" fullWidth size="small">
                      <FormikField
                        component={FmuiTextField}
                        select
                        name="assignedToRoleIdForPprpProgramReviews"
                        label="Assign To (PPRP Program Reviews)">
                        {[<MenuItem value={-1}>(none)</MenuItem>].concat(props.userRoles.map(listUserRoles))}
                      </FormikField>
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <FormikField
                      component={Autocomplete}
                      multiple
                      name="reassignableToRoleIds"
                      options={props.userRoles.map((ur) => ur.id)}
                      getOptionLabel={(option: number) => props.userRoles.find((ur) => ur.id === option)?.name}
                      renderInput={(params: AutocompleteRenderInputParams) => (
                        <TextField {...params} label="Reassignable To" variant="outlined" />
                      )}
                    />
                  </Grid>
                </Grid>
              </FormikForm>
            </DialogContent>
            <DialogActions>
              <Button onClick={props.handleClose}>Cancel</Button>
              <LoadingButton color="primary" variant="contained" loading={loading} onClick={() => formikProps.submitForm()}>
                Save
              </LoadingButton>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};

export default EditWorkflowInaDialog;
