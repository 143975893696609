import { useMutation } from "@apollo/client";
import { ConfirmationDialog } from "../common/ConfirmationDialog";
import { useNotifications } from "../notifications";
import { Alert, DialogContentText, Link, Stack } from "@mui/material";
import { BinderJob, CommitteeMeeting } from "./models";
import { FetchBinderJobsQuery, AssembleBinderMutation, FetchBinderJobByMeetingIdQuery } from ".";

interface Props {
  onClose: () => void;
  committeeMeeting: CommitteeMeeting;
}

const ConfirmAssembleBinderDialog = (props: Props) => {
  const notifications = useNotifications();
  const [assembleMutate, assembleMutation] = useMutation<{ committeeMeeting: { assembleBinder: BinderJob } }, { meetingId: number }>(
    AssembleBinderMutation,
    {
      refetchQueries: [
        { query: FetchBinderJobsQuery },
        { query: FetchBinderJobByMeetingIdQuery, variables: { meetingId: props.committeeMeeting.id } }
      ]
    }
  );

  const autoGeneratedDocumentsList = props.committeeMeeting.isRegistrationCommittee
    ? "Meeting Agenda document"
    : "Meeting Agenda, Office Listing, or Standard Motions Summary documents";
  const reviewTitlePageDocumentsName = props.committeeMeeting.isRegistrationCommittee
    ? "Program Review Summary Data Sheet documents"
    : "Presentation Format documents";

  return (
    <>
      <ConfirmationDialog
        noDanger
        open={true}
        title={`${props.committeeMeeting.assembledBinders?.length ? "Reassemble" : "Assemble"} Binder`}
        body={
          <>
            <DialogContentText>
              Assemble a new binder PDF for {props.committeeMeeting.name}? <br /> This will:
            </DialogContentText>
            <DialogContentText>
              <ul>
                <li>
                  Get the latest version of each document from the libraries in SharePoint and compile them into a freshly assembled PDF
                  file,
                </li>
                <li>Save a timestamped copy of this assembled binder in the meeting's library folder in SharePoint.</li>
              </ul>
            </DialogContentText>
            <DialogContentText>
              This fresh binder file will include any changes that have been made directly (i.e. via Word) to the{" "}
              {autoGeneratedDocumentsList} in this meeting's folder or the {reviewTitlePageDocumentsName} included from each assigned{" "}
              {props.committeeMeeting.isRegistrationCommittee ? "PPRP program" : "practice"} review's folders.
            </DialogContentText>
            {(props.committeeMeeting.assembledBinders?.length ?? 0) > 0 && (
              <Alert color="warning">
                It will not include any changes that may have been made directly (i.e. via Acrobat) to previously assembled copies of this
                meeting's binder:
                <ul>
                  {props.committeeMeeting.assembledBinders?.map((binder) => {
                    return (
                      <li>
                        <Link href={`${props.committeeMeeting.sharePointUrl}/${binder.fileName}`}>{binder.fileName}</Link>
                      </li>
                    );
                  })}
                </ul>
              </Alert>
            )}
          </>
        }
        confirm={async () => {
          const result = await assembleMutate({
            variables: { meetingId: props.committeeMeeting.id }
          });

          if (result.data?.committeeMeeting.assembleBinder) {
            notifications.success("Started assembling a new binder.");
          }

          props.onClose();
        }}
        loading={assembleMutation.loading}
        cancel={props.onClose}
      />
    </>
  );
};

export default ConfirmAssembleBinderDialog;
