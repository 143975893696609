import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import { Button, DialogContentText, FormControlLabel, Grid, MenuItem, Radio, Stack, TextField, Typography } from "@mui/material";
import StackedStaticDataDisplay from "common/StackedStaticDataDisplay";
import { DecisionAdditionalParagraph, FetchDecisionAdditionalParagraphsQuery } from "decisions";
import { Formik, Form as FormikForm, Field as FormikField } from "formik";
import { RadioGroup as FmuiRadioGroup, CheckboxWithLabel as FmuiCheckboxWithLabel, TextField as FmuiTextField } from "formik-mui";
import { makeStyles } from "makeStyles";
import React, { useRef, useState } from "react";
import { meetingStyles, staticDataStyles } from "styles/common";
import { useUnsavedChanges } from "UnsavedChangesProvider";
import { RegistrationCommitteeItem, RegistrationCommitteeItemDecision, RegistrationCommitteeItemDecisionInput } from ".";
import _ from "lodash";
import { LoadingButton } from "@mui/lab";
import { getHtmlForField } from "util/utilities";
import RichTextEditor from "common/RichTextEditor";
import { MergeFieldType } from "common/HtmlMergeFields/models";
import { DeleteFileFromRegistrationCommitteeItemMutation, SaveRegistrationCommitteeItemDecisionMutation } from "./queries";
import { useNotifications } from "notifications";
import ReadOnlyWrapper from "common/ReadOnlyWrapper";
import { Permissions, useCurrentUser } from "users";
import { NumberedTextFieldEndAdornment } from "common/NumberedTextfield";
import { DocType } from "common/DocType";
import ReplaceableDocumentLink from "common/ReplaceableDocumentLink";
import { AxiosResponse } from "axios";
import { useAppConfig } from "util/AppConfig";
import { useAxios } from "auth/SecureAxios";
import { ConfirmationDialog } from "common/ConfirmationDialog";
import { FetchCommitteeMeetingToEnterDecisionsQuery } from "committee-meetings";
import { DateTime } from "luxon";

const useStyles = makeStyles()((theme) => ({
  ...meetingStyles(theme),
  ...staticDataStyles(theme),
  independentHeight: {
    height: "fit-content"
  }
}));

interface Props {
  item: RegistrationCommitteeItem;
}

interface DecisionFormValues {
  id?: number;
  itemId: number;

  committeeDecision: "approve" | "defer" | "refuse" | null;

  additionalCommentsForMinutes: string | null;
  additionalCommentsForDecisionLetter: string | null;
  generalNotes: string | null;

  monitoringRequired: boolean;
  monitoringFrequencyInDays: number | null;
  monitoringCount: number | null;
}

const RegistrationCommitteeItemDecisionTab: React.FunctionComponent<Props> = (props) => {
  const { classes } = useStyles();
  const notifications = useNotifications();
  const user = useCurrentUser();
  const appConfig = useAppConfig();
  const { secureAxios } = useAxios();
  const apolloClient = useApolloClient();
  const { unsavedChanges, changesSaved, setSaveFunction } = useUnsavedChanges();

  const [displayNoVerbiageWarning, setDisplayNoVerbiageWarning] = useState(false);

  const additionalParagraphsQuery = useQuery<
    { decisionAdditionalParagraphs: DecisionAdditionalParagraph[] },
    { forRegistrationCommitteeItems: boolean; activeOnly: boolean }
  >(FetchDecisionAdditionalParagraphsQuery, { variables: { forRegistrationCommitteeItems: true, activeOnly: true } });
  const additionalParagraphs = additionalParagraphsQuery.data?.decisionAdditionalParagraphs ?? [];

  const [selectedAdditionalParagraphId, setSelectedAdditionalParagraphId] = useState<number | null>(null);

  const additionalCommentsForMinutesContentRetriever = useRef<{ getContentAsHtml: () => string | null }>({ getContentAsHtml: () => null });
  const additionalCommentsForDecisionLetterContentRetriever = useRef<{ getContentAsHtml: () => string | null }>({
    getContentAsHtml: () => null
  });

  const [legalCounselToAttach, setLegalCounselToAttach] = useState<File | undefined>();
  const [removingLegalCounsel, setRemovingLegalCounsel] = useState(false);

  const [deleteFile] = useMutation<
    { registrationCommitteeItem: { deleteFile: RegistrationCommitteeItem } },
    { itemId: number; attachedDocumentId: number }
  >(DeleteFileFromRegistrationCommitteeItemMutation);

  const [saveMutate] = useMutation<
    { registrationCommiteeItem: { saveDecision: RegistrationCommitteeItemDecision } },
    { decisionInput: RegistrationCommitteeItemDecisionInput }
  >(SaveRegistrationCommitteeItemDecisionMutation, {
    refetchQueries: [
      {
        query: FetchCommitteeMeetingToEnterDecisionsQuery,
        variables: {
          meetingId: props.item.committeeMeeting?.id ?? 0,
          meetingDate: props.item.committeeMeeting?.meetingDate ?? DateTime.now().toISODate()
        }
      }
    ]
  });

  const decision = props.item.decision;
  const userCanEditDecision = user.userHasPermission(Permissions.AdminTasksPprp) && !props.item.isCompleted;

  const initialValues: DecisionFormValues = {
    id: decision?.id,
    itemId: props.item.id,

    committeeDecision: decision?.isApproved ? "approve" : decision?.isDeferred ? "defer" : decision?.isRefused ? "refuse" : null,

    additionalCommentsForMinutes: decision?.additionalCommentsForMinutes ?? null,
    additionalCommentsForDecisionLetter: decision?.additionalCommentsForDecisionLetter ?? null,
    generalNotes: decision?.generalNotes ?? null,

    monitoringRequired: decision?.monitoringRequired ?? false,
    monitoringFrequencyInDays: decision?.monitoringFrequencyInDays ?? 365,
    monitoringCount: decision?.monitoringCount ?? 1
  };

  const updateRegistrationCommitteeItemInCache = (updatedItem: RegistrationCommitteeItem) => {
    const cacheId = `RegistrationCommitteeItem:${updatedItem.id}`;

    apolloClient.cache.modify({
      id: cacheId,
      fields: {
        attachedDocuments() {
          return updatedItem.attachedDocuments;
        }
      }
    });
  };

  async function saveDecision(values: DecisionFormValues) {
    if (legalCounselToAttach) {
      const formData = new FormData();
      formData.append("RegistrationCommitteeItemId", props.item.id.toString());
      formData.append("Document", legalCounselToAttach);

      let postResult: AxiosResponse<RegistrationCommitteeItem>;
      const postRequestEndpoint = `${appConfig.apiEndpoint}/api/registration-committee-item-document/upload-legal-counsel-verbiage`;
      try {
        postResult = await secureAxios.post(postRequestEndpoint, formData);
        if (postResult === undefined || postResult?.status === 401) {
          postResult = await secureAxios.post(postRequestEndpoint, formData);
        }
      } catch (e: any) {
        notifications.serverError(e.message);
        return;
      }

      const updatedItem = postResult.data;
      updateRegistrationCommitteeItemInCache(updatedItem);
      setLegalCounselToAttach(undefined);
    }
    const input: RegistrationCommitteeItemDecisionInput = {
      id: decision?.id,
      registrationCommitteeItemId: props.item.id,
      isApproved: values.committeeDecision === "approve",
      isDeferred: values.committeeDecision === "defer",
      isRefused: values.committeeDecision === "refuse",
      additionalCommentsForMinutes: additionalCommentsForMinutesContentRetriever.current.getContentAsHtml(),
      additionalCommentsForDecisionLetter: additionalCommentsForDecisionLetterContentRetriever.current.getContentAsHtml(),
      generalNotes: values.generalNotes,
      monitoringRequired: values.monitoringRequired,
      monitoringFrequencyInDays: values.monitoringRequired ? values.monitoringFrequencyInDays : null,
      monitoringCount: values.monitoringRequired ? Number(values.monitoringCount) : null
    };
    var result = await saveMutate({ variables: { decisionInput: input } });
    if (result.data) {
      notifications.success("Decision saved.");
      changesSaved();
      setDisplayNoVerbiageWarning(false);
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values, helpers) => {
        if (
          (values.committeeDecision === "defer" || values.committeeDecision === "refuse") &&
          !(
            legalCounselToAttach ||
            props.item.attachedDocuments.some((ad) => ad.type === DocType.RegistrationCommitteeLegalCounselDecisionVerbiage)
          )
        ) {
          setDisplayNoVerbiageWarning(true);
          helpers.setSubmitting(false);
        } else {
          await saveDecision(values);
        }
      }}>
      {(formikProps) => {
        setSaveFunction(async () => {
          await formikProps.submitForm();
          return true;
        });
        return (
          <FormikForm onChange={() => unsavedChanges()}>
            <Grid container columnSpacing={3} sx={{ height: "100%" }}>
              <Grid container item xs={10} rowSpacing={2} className={classes.independentHeight}>
                <Grid item xs={12}>
                  <StackedStaticDataDisplay label="Committee Meeting" value={props.item.committeeMeeting?.name ?? "--"} />
                </Grid>
                <Grid container item columnSpacing={2} xs={12}>
                  <Grid item xs={2}>
                    <div style={{ height: "100%", display: "flex", alignItems: "center" }}>
                      <ReadOnlyWrapper
                        readOnly={!userCanEditDecision}
                        title={
                          props.item.isCompleted ? "The item has been completed." : "Only the registrar may edit the committee decision."
                        }>
                        <FormikField
                          component={FmuiCheckboxWithLabel}
                          type="checkbox"
                          name="monitoringRequired"
                          Label={{ label: "Monitoring Required?" }}
                          disabled={formikProps.isSubmitting || !userCanEditDecision}
                        />
                      </ReadOnlyWrapper>
                    </div>
                  </Grid>
                  {formikProps.values.monitoringRequired && (
                    <>
                      <Grid item xs={2}>
                        <ReadOnlyWrapper
                          readOnly={!userCanEditDecision}
                          title={
                            props.item.isCompleted ? "The item has been completed." : "Only the registrar may edit the committee decision."
                          }>
                          <FormikField
                            component={FmuiTextField}
                            disabled={formikProps.isSubmitting || !userCanEditDecision}
                            select
                            name="monitoringFrequencyInDays"
                            label="Frequency"
                            fullWidth>
                            <MenuItem value={90}>Quarterly</MenuItem>
                            <MenuItem value={180}>Semi-annually</MenuItem>
                            <MenuItem value={365}>Annually</MenuItem>
                          </FormikField>
                        </ReadOnlyWrapper>
                      </Grid>
                      <Grid item xs={2}>
                        <ReadOnlyWrapper
                          readOnly={!userCanEditDecision}
                          title={
                            props.item.isCompleted ? "The item has been completed." : "Only the registrar may edit the committee decision."
                          }>
                          <FormikField
                            component={FmuiTextField}
                            name="monitoringCount"
                            label="Number of Occurrences"
                            fullWidth
                            required
                            InputProps={{
                              inputProps: { min: 1 },
                              endAdornment: (
                                <NumberedTextFieldEndAdornment
                                  value={formikProps.values.monitoringCount ?? 0}
                                  onChange={(newValue) => formikProps.setFieldValue("monitoringCount", newValue)}
                                  min={1}
                                />
                              )
                            }}
                            disabled={formikProps.isSubmitting || !userCanEditDecision}
                          />
                        </ReadOnlyWrapper>
                      </Grid>
                    </>
                  )}
                </Grid>

                <Grid item xs={12}>
                  <Stack direction="row" spacing={1}>
                    <Typography variant="h4" sx={{ mb: 0, mr: 3, alignSelf: "center" }}>
                      Paragraphs
                    </Typography>
                    <TextField
                      label="Paragraph to Append"
                      select
                      value={selectedAdditionalParagraphId}
                      onChange={(e) => setSelectedAdditionalParagraphId(Number(e.target.value))}
                      sx={{ flex: 1 }}
                      disabled={formikProps.isSubmitting || !userCanEditDecision}>
                      {_.orderBy(additionalParagraphs, (ap) => ap.name).map((ap) => (
                        <MenuItem value={ap.id} key={ap.id}>
                          {ap.name}
                        </MenuItem>
                      ))}
                    </TextField>
                    <Button
                      variant="outlined"
                      disabled={formikProps.isSubmitting || !userCanEditDecision}
                      onClick={() => {
                        const additionalParagraphToAppend = additionalParagraphs.find((ac) => ac.id === selectedAdditionalParagraphId)!;

                        const currentHtmlForMinutes = getHtmlForField(additionalCommentsForMinutesContentRetriever);
                        const updatedHtmlForMinutes = (currentHtmlForMinutes ?? "") + additionalParagraphToAppend.paragraphForMinutes;
                        formikProps.setFieldValue("additionalCommentsForMinutes", updatedHtmlForMinutes);

                        const currentHtmlForDecisionLetter = getHtmlForField(additionalCommentsForDecisionLetterContentRetriever);
                        const updatedHtmlForDecisionLetter =
                          (currentHtmlForDecisionLetter ?? "") + additionalParagraphToAppend.paragraphForDecisionLetter;
                        formikProps.setFieldValue("additionalCommentsForDecisionLetter", updatedHtmlForDecisionLetter);
                      }}>
                      Append
                    </Button>
                  </Stack>
                </Grid>

                <Grid item xs={12}>
                  <RichTextEditor
                    label="Contents for Minutes"
                    minHeight="6em"
                    html={formikProps.values.additionalCommentsForMinutes}
                    passContentRetriever={(getContentAsHtml) => {
                      additionalCommentsForMinutesContentRetriever.current = { getContentAsHtml };
                    }}
                    reportUnsavedChanges
                    showMergeFields
                    mergeFieldType={MergeFieldType.RegComMeetingItem}
                    templateMarkup
                    readOnly={formikProps.isSubmitting || !userCanEditDecision}
                  />
                </Grid>
                <Grid item xs={12}>
                  <RichTextEditor
                    label="Contents for Decision Letter"
                    minHeight="6em"
                    html={formikProps.values.additionalCommentsForDecisionLetter}
                    passContentRetriever={(getContentAsHtml) => {
                      additionalCommentsForDecisionLetterContentRetriever.current = { getContentAsHtml };
                    }}
                    reportUnsavedChanges
                    showMergeFields
                    mergeFieldType={MergeFieldType.RegComItem}
                    templateMarkup
                    readOnly={formikProps.isSubmitting || !userCanEditDecision}
                  />
                </Grid>
                {(formikProps.values.committeeDecision === "defer" || formikProps.values.committeeDecision === "refuse") && (
                  <Grid item xs={12}>
                    <Typography variant="h3" mb={1}>
                      Legal Counsel Decision Verbiage
                    </Typography>
                    <ReplaceableDocumentLink
                      documents={props.item.attachedDocuments}
                      documentName="Legal Counsel Decision Verbiage"
                      documentType={DocType.RegistrationCommitteeLegalCounselDecisionVerbiage}
                      documentToAttach={legalCounselToAttach}
                      attachDocument={setLegalCounselToAttach}
                      clearAttachedDocument={() => setLegalCounselToAttach(undefined)}
                      removeDocument={() => setRemovingLegalCounsel(true)}
                      acceptableFileTypes=".doc,.docx,.pdf"
                      disabled={formikProps.isSubmitting || !userCanEditDecision}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <ReadOnlyWrapper
                    readOnly={!userCanEditDecision}
                    title={props.item.isCompleted ? "The item has been completed." : "Only the registrar may edit the committee decision."}>
                    <FormikField
                      component={FmuiTextField}
                      name="generalNotes"
                      label="General Notes"
                      multiline
                      fullWidth
                      minRows={3}
                      disabled={formikProps.isSubmitting || !userCanEditDecision}
                    />
                  </ReadOnlyWrapper>
                </Grid>
              </Grid>
              <Grid item xs={2}>
                <Stack spacing={2} className={classes.independentHeight}>
                  <LoadingButton
                    loading={formikProps.isSubmitting}
                    color="primary"
                    variant="contained"
                    onClick={formikProps.submitForm}
                    disabled={!userCanEditDecision}
                    sx={{ pt: 1.5, pb: 1.5 }}>
                    Save
                  </LoadingButton>
                  <span>
                    <Typography variant="h3">Committee Decision</Typography>
                    <ReadOnlyWrapper
                      readOnly={!userCanEditDecision}
                      title={
                        props.item.isCompleted ? "The item has been completed." : "Only the registrar may edit the committee decision."
                      }>
                      <FormikField component={FmuiRadioGroup} name="committeeDecision" sx={{ pl: 2 }} disabled={!userCanEditDecision}>
                        <FormControlLabel
                          label="Approve"
                          value="approve"
                          control={<Radio disabled={formikProps.isSubmitting} />}
                          disabled={formikProps.isSubmitting || !userCanEditDecision}
                        />
                        <FormControlLabel
                          label="Defer"
                          value="defer"
                          control={<Radio disabled={formikProps.isSubmitting} />}
                          disabled={formikProps.isSubmitting || !userCanEditDecision}
                        />
                        <FormControlLabel
                          label="Refuse"
                          value="refuse"
                          control={<Radio disabled={formikProps.isSubmitting} />}
                          disabled={formikProps.isSubmitting || !userCanEditDecision}
                        />
                      </FormikField>
                    </ReadOnlyWrapper>
                  </span>
                </Stack>
              </Grid>
            </Grid>

            {removingLegalCounsel && (
              <ConfirmationDialog
                open={true}
                title="Remove Legal Counsel Decision Verbiage"
                body={<DialogContentText>Are you sure you want to remove the Legal Counsel Decision Verbiage?</DialogContentText>}
                cancel={() => setRemovingLegalCounsel(false)}
                loading={formikProps.isSubmitting}
                confirm={async () => {
                  formikProps.setSubmitting(true);
                  await deleteFile({
                    variables: {
                      itemId: props.item.id,
                      attachedDocumentId: props.item.attachedDocuments.filter(
                        (ad) => ad.type === DocType.RegistrationCommitteeLegalCounselDecisionVerbiage
                      )[0]!.id
                    }
                  });
                  formikProps.setSubmitting(false);
                  setRemovingLegalCounsel(false);
                }}
              />
            )}
            {displayNoVerbiageWarning && (
              <ConfirmationDialog
                open={true}
                title="Save Without Legal Counsel Decision Verbiage?"
                body={
                  <DialogContentText>
                    Are you sure you want to save the decision without uploading any Legal Counsel Decision Verbiage?
                  </DialogContentText>
                }
                cancel={() => setDisplayNoVerbiageWarning(false)}
                loading={formikProps.isSubmitting}
                confirm={async () => await saveDecision(formikProps.values)}
              />
            )}
          </FormikForm>
        );
      }}
    </Formik>
  );
};

export default RegistrationCommitteeItemDecisionTab;
