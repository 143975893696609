import React, { useEffect, useRef, useState } from "react";
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Card,
  Checkbox,
  Chip,
  DialogContentText,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Tooltip,
  Typography
} from "@mui/material";

import {
  PdCoursesQuery,
  ComplianceZone,
  Decision,
  DecisionInput,
  DecisionTypeCode,
  MotionParagraph,
  MotionTypeCode,
  SaveDecisionMutation,
  SignOffChecklistItem,
  SignOffChecklistItemsQuery,
  SignOffDecisionMutation,
  ToggleSignOffChecklistItemMutation,
  YesNoNa
} from "decisions";

import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";
import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import { PdCourse } from "pd-courses/models";
import StackedStaticDataDisplay from "common/StackedStaticDataDisplay";
import ReadOnlyNotes from "chrome/ReadOnlyNotes";
import RichTextEditor from "common/RichTextEditor";
import { useNotifications } from "../notifications";
import { Formik, setNestedObjectValues, Field as FormikField, useFormik, FormikProvider } from "formik";
import * as Yup from "yup";
import _ from "lodash";
import { YesNoNaQuestion } from "./YesNoNaQuestion";
import { getHtmlForField, getOpenableUrl } from "../util/utilities";
import { GeneratePrReportMutation, PracticeReview, PrScreenQuery } from "../practice-reviews";
import { useCurrentUser, Permissions } from "../users";
import { useUnsavedChanges } from "../UnsavedChangesProvider";
import { formatDate } from "../util/formats";
import { YesNoIcon } from "../common/YesNoIcon";
import { LoadingButton } from "@mui/lab";
import { makeStyles } from "../makeStyles";
import { getIncompleteDecisionMessages, getSignoffPreconditionsForReview } from "./signOffChecklistLogic";
import { ConfirmationDialog } from "../common/ConfirmationDialog";
import { FetchDecisionAdditionalParagraphsQuery, GetComplianceZonesQuery, FetchActiveMotionsQuery, TablePrMutation } from "./queries";
import PrsDatePickerField from "../common/FormikFields/PrsDatePickerField";
import { DateTime } from "luxon";
import { shouldConfirmDecisionChange, shouldCancelDecisionChange } from "./decisionLogic";
import { TextField as FmuiTextField } from "formik-mui";
import {
  AvailableMeetingsQuery,
  CommitteeMeeting,
  FetchCommitteeMeetingsQuery,
  FetchCommitteeMeetingToEnterDecisionsQuery
} from "../committee-meetings";
import { meetingStyles, staticDataStyles } from "styles/common";
import { DecisionAdditionalParagraph } from "./models";
import { MergeFieldType } from "../common/HtmlMergeFields/models";
import { SignOffChecklistItemTypeCode } from "sign-off-checklist-items/models";
import ReadOnlyWrapper from "common/ReadOnlyWrapper";

const useStyles = makeStyles()((theme) => ({
  ...meetingStyles(theme),
  ...staticDataStyles(theme),
  complianceButtons: {
    display: "flex",
    flexDirection: "column",
    "& :not(:first-child)": {
      marginTop: theme.spacing(1)
    }
  },
  complianceButton: {
    height: theme.spacing(6),
    width: "100%",
    maxWidth: "20em",
    "& svg": {
      marginRight: theme.spacing(1)
    }
  },
  unselectedComplianceButton: {
    backgroundColor: theme.palette.common.white
  },
  selectedComplianceButton: {
    color: theme.palette.common.white
  },
  comply: {
    backgroundColor: theme.palette.success.main,
    "&:hover": {
      backgroundColor: theme.palette.success.dark
    }
  },
  nonComply: {
    backgroundColor: theme.palette.error.main,
    "&:hover": {
      backgroundColor: theme.palette.error.dark
    }
  },
  areasOfNonCompliance: {
    "& .MuiCheckbox-root": {
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5)
    }
  },
  detailsField: {
    maxWidth: "20em"
  },
  pdCourseSelector: {
    "& .MuiChip-root": {
      fontFamily: theme.typography.body1.fontFamily
    }
  },
  signOffChecklistContainer: {
    backgroundColor: theme.palette.highlight,
    padding: theme.spacing(2),
    "& > :not(:last-child)": {
      marginBottom: theme.spacing(3)
    },
    position: "sticky",
    top: theme.spacing(3)
  },
  signOffChecklistItem: {
    display: "flex",
    alignItems: "flex-start",
    "&:not(:last-child)": {
      marginBottom: theme.spacing(1)
    },
    "& .MuiCheckbox-root": {
      position: "relative",
      top: theme.spacing(-0.4),
      padding: 0,
      marginRight: theme.spacing(1)
    },
    "& .MuiTypography-root": {
      lineHeight: 1.25
    }
  },
  signOffChecklistItemButton: {
    padding: 0,
    fontFamily: theme.typography.body1.fontFamily,
    fontSize: theme.typography.body1.fontSize,
    textTransform: "unset",
    lineHeight: 1.25
  },
  signOffChecklistItemState: {
    marginRight: theme.spacing(1),
    width: "1em"
  },
  checkedItem: {
    color: theme.palette.success.main
  },
  complianceZoneButton: {
    paddingTop: "6px",
    paddingBottom: "6px"
  }
}));

interface Props {
  practiceReview: PracticeReview;
  decisionTypeCode: DecisionTypeCode;
}

export interface DecisionFormValues {
  id?: number;
  practiceReviewId: number;
  decisionTypeCode: DecisionTypeCode;

  isComply: boolean | null;
  nonComplyFinancial: boolean;
  nonComplyAudit: boolean;
  nonComplyReview: boolean;
  nonComplyQualityManagement: boolean;
  nonComplyTax: boolean;
  nonComplyCompilation: boolean;
  nonComplyOther: boolean;
  nonComplyOtherSpecified: string | null;

  complianceZoneId: number | null;
  followUpDate: DateTime | null;
  nonComplyZoneNote: string | null;

  pprpProgramContinuance: YesNoNa | null;
  pprpProgramContinuanceExplanation: string | null;
  cpabParticipating: YesNoNa | null;
  cpdCompliance: YesNoNa | null;
  cpdComplianceExplanation: string | null;
  pliCompliance: YesNoNa | null;
  pliComplianceExplanation: string | null;

  recommendedPdCourseIds: number[];
  directedPdCourses: { courseId: number; isCompleted: boolean }[];
  directedPdDueDate: DateTime | null;

  motionStandardId: number;
  motionPprpProgramReviewId: number;
  motionPdId: number;
  motionCpabId: number;
  motionCicId: number;

  reviewerMemo: string | null;
  managerMemo: string | null;
  descriptionOfIntendedAction: string | null;
  additionalCommentsForMinutes: string | null;
  additionalCommentsForDecisionLetter: string | null;
  directorsHighlights: string | null;
  directorsSummaryOfFirmResponse: string | null;

  discussDecisionChange: boolean;
  cancelDecisionChange: boolean;

  requiresFullScopeFollowUp: string;
  fullScopeFollowUpComments: string | null;

  monitoringRequired: boolean;
  monitoringFrequencyInDays: number | null;
  monitoringCount: number | null;

  recommendationsToCommittee: string | null;
}

export interface TableFormValues {
  committeeMeetingId: number | null;
  reason: string;
}

export const DecisionStageTab: React.FunctionComponent<Props> = (props) => {
  const { classes, cx } = useStyles();
  const notifications = useNotifications();
  const apolloClient = useApolloClient();
  const { user, userIsLeadReviewer: userIsLead, userHasPermission } = useCurrentUser();
  const { unsavedChanges, changesSaved, unsavedChangesExist, setSaveFunction } = useUnsavedChanges();

  const nonComplyZoneNoteContentRetriever = useRef<{ getContentAsHtml: () => string | null }>({ getContentAsHtml: () => null });
  const reviewerMemoContentRetriever = useRef<{ getContentAsHtml: () => string | null }>({ getContentAsHtml: () => null });
  const managerMemoContentRetriever = useRef<{ getContentAsHtml: () => string | null }>({ getContentAsHtml: () => null });
  const descriptionOfIntendedActionContentRetriever = useRef<{ getContentAsHtml: () => string | null }>({ getContentAsHtml: () => null });
  const additionalCommentsForMinutesContentRetriever = useRef<{ getContentAsHtml: () => string | null }>({ getContentAsHtml: () => null });
  const additionalCommentsForDecisionLetterContentRetriever = useRef<{ getContentAsHtml: () => string | null }>({
    getContentAsHtml: () => null
  });
  const directorsHighlightsContentRetriever = useRef<{ getContentAsHtml: () => string | null }>({ getContentAsHtml: () => null });
  const directorsSummaryOfFirmResponseContentRetriever = useRef<{ getContentAsHtml: () => string | null }>({
    getContentAsHtml: () => null
  });
  const recommendationsToCommitteeContentRetriever = useRef<{ getContentAsHtml: () => string | null }>({
    getContentAsHtml: () => null
  });
  const fullScopeFollowUpCommentsContentRetriever = useRef<{ getContentAsHtml: () => string | null }>({
    getContentAsHtml: () => null
  });

  const noneValue = -1;

  const isPprpProgramReview = props.practiceReview.isPprpProgramReview;
  const decision = props.practiceReview.decisions.find((d) => d.decisionType.typeCode === props.decisionTypeCode) ?? null;
  const reviewerDecision = props.practiceReview.decisions.find((d) => d.decisionType.typeCode === DecisionTypeCode.Reviewer) ?? null;
  const managerDecision = props.practiceReview.decisions.find((d) => d.decisionType.typeCode === DecisionTypeCode.Manager) ?? null;
  const directorDecision = props.practiceReview.decisions.find((d) => d.decisionType.typeCode === DecisionTypeCode.Director) ?? null;
  const committeeDecision = props.practiceReview.decisions.find((d) => d.decisionType.typeCode === DecisionTypeCode.Committee) ?? null;

  const pdCoursesQuery = useQuery<{ pdCourses: PdCourse[] }>(PdCoursesQuery);
  const allPdCourses = pdCoursesQuery.data?.pdCourses ?? [];
  const complianceZonesQuery = useQuery<{ complianceZones: ComplianceZone[] }, { forPprpProgramReviews: boolean }>(
    GetComplianceZonesQuery,
    {
      variables: { forPprpProgramReviews: isPprpProgramReview }
    }
  );
  const complianceZones = complianceZonesQuery.data?.complianceZones ?? [];

  const motionParagraphsQuery = useQuery<{ motionParagraphs: MotionParagraph[] }>(FetchActiveMotionsQuery);
  const motionParagraphs = motionParagraphsQuery.data?.motionParagraphs ?? [];

  const additionalParagraphsQuery = useQuery<
    { decisionAdditionalParagraphs: DecisionAdditionalParagraph[] },
    { forRegistrationCommitteeItems: boolean; activeOnly: boolean }
  >(FetchDecisionAdditionalParagraphsQuery, { variables: { forRegistrationCommitteeItems: false, activeOnly: true } });
  const additionalParagraphs = additionalParagraphsQuery.data?.decisionAdditionalParagraphs ?? [];

  const availableMeetingsQuery = useQuery<{ availableMeetings: CommitteeMeeting[] }>(AvailableMeetingsQuery, {
    skip: decision?.decisionType.typeCode !== DecisionTypeCode.Committee
  });
  const meetingsThatPrCanBeTabledTo =
    availableMeetingsQuery.data?.availableMeetings.filter((m) => m.id !== props.practiceReview.committeeMeeting?.id) ?? [];

  const initialFormValues: DecisionFormValues = {
    id: decision?.id,
    practiceReviewId: props.practiceReview.id,
    decisionTypeCode: decision?.decisionType.typeCode ?? props.decisionTypeCode,

    isComply: decision?.isComply ?? null,
    nonComplyFinancial: decision?.nonComplyFinancial ?? false,
    nonComplyAudit: decision?.nonComplyAudit ?? false,
    nonComplyReview: decision?.nonComplyReview ?? false,
    nonComplyQualityManagement: decision?.nonComplyQualityManagement ?? false,
    nonComplyTax: decision?.nonComplyTax ?? false,
    nonComplyCompilation: decision?.nonComplyCompilation ?? false,
    nonComplyOther: decision?.nonComplyOther ?? false,
    nonComplyOtherSpecified: decision?.nonComplyOtherSpecified ?? null,

    complianceZoneId: decision?.complianceZone?.id ?? null,
    followUpDate: decision?.followUpDate ? DateTime.fromISO(decision?.followUpDate) : null,
    nonComplyZoneNote: decision?.nonComplyZoneNote ?? null,

    pprpProgramContinuance: decision?.pprpProgramContinuance ?? null,
    pprpProgramContinuanceExplanation: decision?.pprpProgramContinuanceExplanation ?? null,
    cpabParticipating: decision?.cpabParticipating ?? null,
    cpdCompliance: decision?.cpdCompliance ?? null,
    cpdComplianceExplanation: decision?.cpdComplianceExplanation ?? null,
    pliCompliance: decision?.pliCompliance ?? null,
    pliComplianceExplanation: decision?.pliComplianceExplanation ?? null,

    recommendedPdCourseIds: decision?.assignedPdCourses.filter((c) => !c.isDirected).map((ac) => ac.pdCourse.id) ?? [],
    directedPdCourses:
      decision?.assignedPdCourses.filter((c) => c.isDirected).map((ac) => ({ courseId: ac.pdCourse.id, isCompleted: ac.isCompleted })) ??
      [],
    directedPdDueDate: decision?.directedPdDueDate ? DateTime.fromISO(decision?.directedPdDueDate) : null,

    motionStandardId: decision?.motionStandard?.id ?? noneValue,
    motionPprpProgramReviewId: decision?.motionPprpProgramReview?.id ?? noneValue,
    motionPdId: decision?.motionPd?.id ?? noneValue,
    motionCpabId: decision?.motionCpab?.id ?? noneValue,
    motionCicId: decision?.motionCic?.id ?? noneValue,

    descriptionOfIntendedAction: decision?.descriptionOfIntendedAction ?? null,
    additionalCommentsForMinutes: decision?.additionalCommentsForMinutes ?? null,
    additionalCommentsForDecisionLetter: decision?.additionalCommentsForDecisionLetter ?? null,

    reviewerMemo: reviewerDecision?.reviewerMemo ?? null,
    managerMemo: managerDecision?.managerMemo ?? null,
    directorsHighlights: directorDecision?.directorsHighlights ?? null,
    directorsSummaryOfFirmResponse: directorDecision?.directorsSummaryOfFirmResponse ?? null,

    discussDecisionChange: false,
    cancelDecisionChange: false,

    requiresFullScopeFollowUp:
      decision?.requiresFullScopeFollowUp === true ? "true" : decision?.requiresFullScopeFollowUp === false ? "false" : "",
    fullScopeFollowUpComments: decision?.fullScopeFollowUpComments ?? null,

    monitoringRequired: decision?.monitoringRequired ?? false,
    monitoringFrequencyInDays: decision?.monitoringFrequencyInDays ?? 365,
    monitoringCount: decision?.monitoringCount ?? null,

    recommendationsToCommittee: directorDecision?.recommendationsToCommittee ?? null
  };

  function buildDecisionInput(values: DecisionFormValues) {
    const selectedComplianceZone = complianceZones.filter((z) => z.id === values.complianceZoneId)[0];

    return {
      id: values.id,
      practiceReviewId: props.practiceReview.id,
      decisionTypeCode: props.decisionTypeCode,
      isComply: values.isComply,
      nonComplyFinancial: values.nonComplyFinancial,
      nonComplyAudit: values.nonComplyAudit,
      nonComplyReview: values.nonComplyReview,
      nonComplyQualityManagement: values.nonComplyQualityManagement,
      nonComplyTax: values.nonComplyTax,
      nonComplyCompilation: values.nonComplyCompilation,
      nonComplyOther: values.nonComplyOther,
      nonComplyOtherSpecified: values.nonComplyOtherSpecified,

      complianceZoneId: values.complianceZoneId,
      followUpDate: values.followUpDate ? values.followUpDate.toISODate() : null,
      nonComplyZoneNote: getHtmlForField(nonComplyZoneNoteContentRetriever),

      pprpProgramContinuance: values.pprpProgramContinuance,
      pprpProgramContinuanceExplanation: values.pprpProgramContinuanceExplanation,
      cpabParticipating: values.cpabParticipating,
      cpdCompliance: values.cpdCompliance,
      cpdComplianceExplanation: values.cpdComplianceExplanation,
      pliCompliance: values.pliCompliance,
      pliComplianceExplanation: values.pliComplianceExplanation,

      recommendedPdCourseIds: values.recommendedPdCourseIds,
      directedPdCourses: values.directedPdCourses,
      directedPdDueDate: values.directedPdDueDate ? values.directedPdDueDate.toISODate() : null,

      motionStandardId: values.motionStandardId !== noneValue ? values.motionStandardId : null,
      motionPprpProgramReviewId: values.motionPprpProgramReviewId !== noneValue ? values.motionPprpProgramReviewId : null,
      motionPdId: values.motionPdId !== noneValue ? values.motionPdId : null,
      motionCpabId: values.motionCpabId !== noneValue ? values.motionCpabId : null,
      motionCicId: values.motionCicId !== noneValue ? values.motionCicId : null,

      reviewerMemo: getHtmlForField(reviewerMemoContentRetriever),
      managerMemo: getHtmlForField(managerMemoContentRetriever),
      descriptionOfIntendedAction: getHtmlForField(descriptionOfIntendedActionContentRetriever),
      additionalCommentsForMinutes: getHtmlForField(additionalCommentsForMinutesContentRetriever),
      additionalCommentsForDecisionLetter: getHtmlForField(additionalCommentsForDecisionLetterContentRetriever),
      directorsHighlights: getHtmlForField(directorsHighlightsContentRetriever),
      directorsSummaryOfFirmResponse: getHtmlForField(directorsSummaryOfFirmResponseContentRetriever),

      requiresFullScopeFollowUp: selectedComplianceZone?.requiresFollowUpScope
        ? values.requiresFullScopeFollowUp === "true"
          ? true
          : values.requiresFullScopeFollowUp === "false"
          ? false
          : null
        : null,
      fullScopeFollowUpComments:
        selectedComplianceZone?.requiresFollowUpScope && values.requiresFullScopeFollowUp === "true"
          ? getHtmlForField(fullScopeFollowUpCommentsContentRetriever)
          : null,

      monitoringRequired: values.monitoringRequired,
      monitoringFrequencyInDays: values.monitoringFrequencyInDays,
      monitoringCount: values.monitoringCount,
      recommendationsToCommittee: getHtmlForField(recommendationsToCommitteeContentRetriever)
    };
  }

  /* eslint-disable react-hooks/rules-of-hooks */
  const [workingDecision, setWorkingDecision] = useState<DecisionInput>(buildDecisionInput(initialFormValues));
  const [signoffPreconditionMessages, setSignoffPreconditionMessages] = useState<string[]>([]);
  const [confirmingDecisionChange, setConfirmingDecisionChange] = useState(false);
  const [confirmingDecisionChangeWithoutSaveButton, setConfirmingDecisionChangeWithoutSaveButton] = useState({
    confirmChange: null as boolean | null,
    signOffChecklistItem: {} as SignOffChecklistItem | null
  });
  const [selectedAdditionalParagraphId, setSelectedAdditionalParagraphId] = useState<number | null>(null);
  /* eslint-enable react-hooks/rules-of-hooks */

  let signOffChecklistItemTypeCode: SignOffChecklistItemTypeCode | null;
  switch (props.decisionTypeCode) {
    case DecisionTypeCode.Reviewer:
      signOffChecklistItemTypeCode = SignOffChecklistItemTypeCode.Reviewer;
      break;
    case DecisionTypeCode.Manager:
      signOffChecklistItemTypeCode = SignOffChecklistItemTypeCode.Manager;
      break;
    case DecisionTypeCode.Director:
      signOffChecklistItemTypeCode = SignOffChecklistItemTypeCode.Director;
      break;
    case DecisionTypeCode.Committee:
      signOffChecklistItemTypeCode = null;
      break;
  }

  const signOffChecklistQuery = useQuery<
    { signOffChecklistItems: SignOffChecklistItem[] },
    { practiceReviewId: number; signOffChecklistItemTypeCode: SignOffChecklistItemTypeCode | null }
  >(SignOffChecklistItemsQuery, {
    variables: { practiceReviewId: props.practiceReview.id, signOffChecklistItemTypeCode: signOffChecklistItemTypeCode },
    skip: signOffChecklistItemTypeCode === null
  });
  const signOffChecklistItems = _.orderBy(signOffChecklistQuery.data?.signOffChecklistItems ?? [], (item) => item.sortOrder);

  useEffect(() => {
    const selectedComplianceZone = complianceZones.filter((z) => z.id === formik.values.complianceZoneId)[0] ?? null;
    try {
      const messages = getSignoffPreconditionsForReview(props.practiceReview, props.decisionTypeCode).concat(
        getIncompleteDecisionMessages(
          workingDecision,
          decision?.decisionType.typeCode ?? DecisionTypeCode.Reviewer,
          selectedComplianceZone,
          isPprpProgramReview
        )
      );
      setSignoffPreconditionMessages(messages);
    } catch (e) {
      notifications.error(e);
    }
  }, [workingDecision]);

  const [saveMutate, saveMutation] = useMutation<
    { decision: { saveDecision: Decision } },
    {
      decision: DecisionInput;
      discussDecisionChange: boolean;
      cancelDecisionChange: boolean;
    }
  >(SaveDecisionMutation, {
    refetchQueries: [
      { query: PrScreenQuery, variables: { prNumber: props.practiceReview.prNumber } },
      { query: FetchCommitteeMeetingsQuery, variables: { registrationCommittee: props.practiceReview.isPprpProgramReview } },
      {
        query: FetchCommitteeMeetingToEnterDecisionsQuery,
        variables: {
          meetingId: props.practiceReview.committeeMeeting?.id ?? 0,
          meetingDate: props.practiceReview.committeeMeeting?.meetingDate ?? DateTime.now().toISODate()
        }
      }
    ]
  });

  async function saveDecision(values: DecisionFormValues) {
    const decisionInput = buildDecisionInput(values);

    const response = await saveMutate({
      variables: {
        decision: decisionInput,
        discussDecisionChange: values.discussDecisionChange,
        cancelDecisionChange: values.cancelDecisionChange
      }
    });

    const savedDecision = response.data?.decision.saveDecision;
    if (savedDecision?.id) {
      changesSaved();
      notifications.success("Decision saved.");

      if (decision === null) {
        const prCacheId = `PracticeReview:${props.practiceReview.id}`;

        apolloClient.cache.modify({
          id: prCacheId,
          fields: {
            decisions(existingDecisions: Decision[]) {
              return existingDecisions.concat([savedDecision]);
            }
          }
        });
      }

      return true;
    }

    return false;
  }

  // Hook up for unsaved changes
  setSaveFunction(() => saveDecision(formik.values));

  const [toggleSignOffChecklistItemMutate] = useMutation<
    {
      decision: {
        toggleSignOffChecklistItem: Partial<SignOffChecklistItem>;
        __typename?: string;
      };
    },
    {
      signOffChecklistItemId: number;
    }
  >(ToggleSignOffChecklistItemMutation);

  async function toggleSignOffChecklistItem(checklistItem: SignOffChecklistItem) {
    await toggleSignOffChecklistItemMutate({
      variables: {
        signOffChecklistItemId: checklistItem.id
      },
      optimisticResponse: {
        decision: {
          toggleSignOffChecklistItem: {
            ...checklistItem,
            isChecked: !checklistItem.isChecked
          },
          __typename: ""
        }
      }
    });
  }

  const [signOffDecisionMutate, signOffDecisionMutation] = useMutation<
    {
      decision: {
        signOffDecision: Partial<Decision>;
      };
    },
    {
      decisionId: number;
    }
  >(SignOffDecisionMutation, {
    refetchQueries: [{ query: PrScreenQuery, variables: { prNumber: props.practiceReview.prNumber } }]
  });

  async function signOffDecision() {
    if (unsavedChangesExist()) {
      await formik.submitForm();

      if (!formik.isValid) {
        return;
      }
    }

    const mutationResult = await signOffDecisionMutate({
      variables: {
        decisionId: decision!.id!
      }
    });

    if (mutationResult.data?.decision.signOffDecision.id) {
      notifications.success("Decision signed off.");
    }
  }

  const [generatePrReportMutate, generatePrReportMutation] = useMutation<
    { practiceReview: { generateReport: PracticeReview } },
    { practiceReviewId: number }
  >(GeneratePrReportMutation);

  async function openOrGenerateDraftPrReport() {
    if (props.practiceReview.practiceReviewReportUrl) {
      window.open(getOpenableUrl(props.practiceReview.practiceReviewReportUrl));
    } else {
      if (unsavedChangesExist()) {
        notifications.warning("You have unsaved changes to your decision.");
        return;
      }

      const result = await generatePrReportMutate({
        variables: { practiceReviewId: props.practiceReview.id }
      });

      if (result.data?.practiceReview.generateReport.practiceReviewReportUrl) {
        window.open(getOpenableUrl(result.data.practiceReview.generateReport.practiceReviewReportUrl!));
      }
    }
  }

  const [tablePrMutate, tablePrMutation] = useMutation<
    {
      practiceReview: {
        table: PracticeReview;
      };
    },
    {
      practiceReviewId: number;
      committeeMeetingId: number;
      reason: string;
    }
  >(TablePrMutation, {
    refetchQueries: [
      {
        query: FetchCommitteeMeetingToEnterDecisionsQuery,
        variables: {
          meetingId: props.practiceReview.committeeMeeting?.id ?? 0,
          meetingDate: props.practiceReview.committeeMeeting?.meetingDate ?? DateTime.now().toISODate()
        }
      }
    ]
  });

  async function tablePr(values: TableFormValues) {
    const mutationResult = await tablePrMutate({
      variables: {
        practiceReviewId: props.practiceReview.id,
        committeeMeetingId: values.committeeMeetingId!,
        reason: values.reason
      }
    });

    if (mutationResult.data?.practiceReview.table.id) {
      notifications.success("PR tabled.");
    }
  }

  const mainValidationSchema = Yup.object({
    isComply: Yup.boolean()
      .nullable()
      .when(
        [
          "nonComplyFinancial",
          "nonComplyAudit",
          "nonComplyReview",
          "nonComplyQualityManagement",
          "nonComplyTax",
          "nonComplyCompilation",
          "nonComplyOther"
        ],
        {
          is: false,
          then: Yup.boolean()
            .nullable()
            .oneOf([true, null].concat(isPprpProgramReview ? [false] : []), "Select at least one area of non-compliance.")
        }
      ),
    nonComplyOtherSpecified: Yup.string()
      .nullable()
      .when("nonComplyOther", {
        is: true,
        then: Yup.string().nullable().required("Specify the other area of non-compliance.")
      }),
    pprpProgramContinuanceExplanation: Yup.string()
      .nullable()
      .when("pprpProgramContinuance", {
        is: YesNoNa.No,
        then: Yup.string().nullable().required("Enter an explanation for PPRP continuance.")
      }),
    cpdComplianceExplanation: Yup.string()
      .nullable()
      .when("cpdCompliance", {
        is: YesNoNa.No,
        then: Yup.string().nullable().required("Enter an explanation for CPD compliance.")
      }),
    pliComplianceExplanation: Yup.string()
      .nullable()
      .when("pliCompliance", {
        is: YesNoNa.No,
        then: Yup.string().nullable().required("Enter an explanation for PLI compliance.")
      }),
    complianceZoneId: Yup.number().nullable(),
    requiresFullScopeFollowUp: Yup.string()
      .nullable()
      .when("complianceZoneId", {
        is: (complianceZoneId: number | null) => {
          const selectedComplianceZone = complianceZones.filter((z) => z.id === complianceZoneId)[0];
          return selectedComplianceZone?.requiresFollowUpScope;
        },
        then: Yup.string().required("Select the follow-up scope.")
      }),
    monitoringCount: Yup.number()
      .nullable()
      .when("monitoringRequired", {
        is: (monitoringRequired: boolean) =>
          monitoringRequired &&
          (decision?.decisionType.typeCode === DecisionTypeCode.Director || decision?.decisionType.typeCode === DecisionTypeCode.Committee),
        then: Yup.number().required("Enter the number of monitoring occurrences.")
      })
  });

  const formik = useFormik({
    initialValues: initialFormValues,
    onSubmit: (values) => saveDecision(values),
    validationSchema: mainValidationSchema,
    enableReinitialize: true
  });

  useEffect(() => {
    setWorkingDecision(buildDecisionInput(formik.values));
  }, [formik.values]);

  function onRichTextChange() {
    setWorkingDecision(buildDecisionInput(formik.values));
  }

  const tablingFormValidationSchema = Yup.object({
    committeeMeetingId: Yup.mixed().required("Select a meeting."),
    reason: Yup.string().trim().required("Enter the reason the PR was tabled.")
  });

  const MotionSelector: React.FunctionComponent<{
    fieldName: string;
    label: string;
    motionType: MotionTypeCode;
    showPprpProgramReviewMotions?: boolean;
  }> = (props) => {
    const applicableMotions = motionParagraphs.filter(
      (mp) =>
        mp.motionType.typeCode === props.motionType &&
        (props.showPprpProgramReviewMotions || mp.motionPprpStatusRecommendedId === null) &&
        (formik.values.isComply || mp.isNonComply) &&
        (!formik.values.isComply || mp.isComply)
    );

    return (
      <Grid item xs={12} lg={3}>
        <ReadOnlyWrapper title={userCanEditReason} readOnly={!userCanEditNormalFields}>
          <FormikField
            component={FmuiTextField}
            select
            name={props.fieldName}
            label={props.label}
            fullWidth
            disabled={formik.isSubmitting}
            onChange={(e: SelectChangeEvent<number>) => {
              formik.setFieldValue(props.fieldName, e.target.value);
              unsavedChanges();
            }}>
            {[
              <MenuItem value={noneValue} key={noneValue}>
                (none)
              </MenuItem>
            ].concat(
              _.orderBy(applicableMotions, [
                (m) => Number(/^\d+/.exec(m.shortDescription)?.[0] ?? "0"), // sort numerically first, then alphabetically on the short description
                (m) => m.shortDescription
              ]).map((motion) => (
                <MenuItem value={motion.id} key={motion.id}>
                  {motion.shortDescription}
                </MenuItem>
              ))
            )}
          </FormikField>
        </ReadOnlyWrapper>
      </Grid>
    );
  };

  function handleCheckboxChange(e: React.ChangeEvent<HTMLInputElement>) {
    formik.setValues({ ...formik.values, [e.target.name]: e.target.checked });
    unsavedChanges();
  }

  function confirmDecisionChange(discussChange: boolean) {
    setDecisionChange(discussChange);
    setConfirmingDecisionChange(false);
  }

  function setDecisionChange(discussChange: boolean) {
    formik.setFieldValue("discussDecisionChange", discussChange, false);
    formik.setFieldValue("cancelDecisionChange", false, false);
    formik.submitForm();
  }

  function confirmDecisionChangeWithoutSaveButton(discussChange: boolean, item: SignOffChecklistItem) {
    setDecisionChange(discussChange);
    setConfirmingDecisionChangeWithoutSaveButton({ confirmChange: false, signOffChecklistItem: null });
  }

  function moveRecommendedCoursesToDirected() {
    formik.setValues({
      ...formik.values,
      recommendedPdCourseIds: [],
      directedPdCourses: formik.values.directedPdCourses.concat(
        formik.values.recommendedPdCourseIds.map((courseId) => ({ courseId: courseId, isCompleted: false }))
      )
    });
  }

  function appendSelectedAdditionalParagraph() {
    const additionalParagraphToAppend = additionalParagraphs.find((ac) => ac.id === selectedAdditionalParagraphId)!;

    const currentHtmlForMinutes = getHtmlForField(additionalCommentsForMinutesContentRetriever);
    const updatedHtmlForMinutes = (currentHtmlForMinutes ?? "") + additionalParagraphToAppend.paragraphForMinutes;
    formik.setFieldValue("additionalCommentsForMinutes", updatedHtmlForMinutes);

    const currentHtmlForDecisionLetter = getHtmlForField(additionalCommentsForDecisionLetterContentRetriever);
    const updatedHtmlForDecisionLetter = (currentHtmlForDecisionLetter ?? "") + additionalParagraphToAppend.paragraphForDecisionLetter;
    formik.setFieldValue("additionalCommentsForDecisionLetter", updatedHtmlForDecisionLetter);

    unsavedChanges();
  }

  function getSignoffChecklistItemText(item: SignOffChecklistItem) {
    if (
      item.displayTemplateName === "DraftReviewReportSignOffChecklistItemTemplate" &&
      (props.practiceReview.practiceReviewReportUrl || (userIsLeadReviewer && userHasPermission(Permissions.PrUpdateFiles)))
    ) {
      return (
        <Tooltip title={props.practiceReview.practiceReviewReportUrl ? "Open" : "Generate"}>
          <LoadingButton
            color="primary"
            className={classes.signOffChecklistItemButton}
            loading={generatePrReportMutation.loading}
            onClick={() => openOrGenerateDraftPrReport()}>
            {item.questionText}
          </LoadingButton>
        </Tooltip>
      );
    } else return <Typography variant="body1">{item.questionText}</Typography>;
  }

  const decisionIsComply = formik.values.isComply !== null && formik.values.isComply;
  const decisionIsNonComply = formik.values.isComply !== null && !formik.values.isComply;

  const validComplianceZones = decisionIsComply
    ? complianceZones.filter((zone) => zone.id !== 0 && !zone.isNonComplyZone)
    : complianceZones.filter((zone) => zone.id !== 0 && zone.isNonComplyZone);

  const zone = complianceZones.find((z) => z.id === formik.values.complianceZoneId) ?? null;
  const nonComplyZone = zone?.isNonComplyZone ?? false;
  const consequencesZone = zone?.requiresResponse ?? false;

  const signOffChecklistComplete = signOffChecklistItems.every((item) => item.isChecked);
  const userCanSignOffDecision =
    props.decisionTypeCode === DecisionTypeCode.Manager
      ? userHasPermission(Permissions.PrManagerDecisionSignOff)
      : props.decisionTypeCode === DecisionTypeCode.Director
      ? userHasPermission(Permissions.PrDirectorDecisionSignOff)
      : true;
  const signedOff = decision?.signedOff ?? false;

  const userIsLeadReviewer = userIsLead(props.practiceReview);
  const userIsNonLeadReviewer = props.practiceReview.otherReviewers.some((r) => r.userId === user.id);
  const userCanEditReviewerFields = props.decisionTypeCode === DecisionTypeCode.Reviewer && userIsLeadReviewer;

  const userCanEditManagerFields =
    props.decisionTypeCode === DecisionTypeCode.Manager && userHasPermission(Permissions.PrManagerDecisionUpdate);
  const userCanEditDirectorFields =
    props.decisionTypeCode === DecisionTypeCode.Director && userHasPermission(Permissions.PrDirectorDecisionUpdate);
  const userCanEditDirectorCommitteeFields =
    props.decisionTypeCode === DecisionTypeCode.Director || props.decisionTypeCode === DecisionTypeCode.Committee;
  const userCanSaveDecision =
    (props.decisionTypeCode === DecisionTypeCode.Reviewer ? userIsLeadReviewer : true) &&
    (props.decisionTypeCode === DecisionTypeCode.Manager ? userHasPermission(Permissions.PrManagerDecisionUpdate) : true) &&
    (props.decisionTypeCode === DecisionTypeCode.Director ? userHasPermission(Permissions.PrDirectorDecisionUpdate) : true);
  const userCanEditNormalFields = !signedOff && userCanSaveDecision;
  const userCanEditFieldsEditableAfterSignoff = userCanSaveDecision;
  const showFollowUp = props.decisionTypeCode === DecisionTypeCode.Director || props.decisionTypeCode === DecisionTypeCode.Committee;

  const showReviewerFields = props.decisionTypeCode === DecisionTypeCode.Reviewer;
  const showManagerMemo = props.decisionTypeCode !== DecisionTypeCode.Reviewer;

  const showDirectorCommitteeFields =
    props.decisionTypeCode === DecisionTypeCode.Director || props.decisionTypeCode === DecisionTypeCode.Committee;
  const showCommitteeFields = props.decisionTypeCode === DecisionTypeCode.Committee;
  const hideFieldsToReviewer = props.decisionTypeCode !== DecisionTypeCode.Reviewer;

  const firmAcknowledgesReports = props.practiceReview.firmResponse?.firmAcknowledgesReports ?? false;
  const noFirmResponse = props.practiceReview.firmResponse?.noFirmResponse ?? false;
  const requiredFullScopeFollowUpValidation = formik.touched.requiresFullScopeFollowUp && formik.errors.requiresFullScopeFollowUp;

  const userCanEditReason = "You are not permitted to change this value at this time";
  const showMonitoringFields = showDirectorCommitteeFields && formik.values.monitoringRequired;
  const showApproxMonitoringDates = props.practiceReview.committeeMeeting && formik.values.monitoringCount;
  return (
    <Grid container columnSpacing={3} sx={{ height: "100%" }}>
      <FormikProvider value={formik}>
        <Grid item xs={12} lg={9} sx={{ display: "flex", flexDirection: "column" }}>
          {userIsNonLeadReviewer && props.decisionTypeCode === DecisionTypeCode.Reviewer && (
            <Alert severity="info" sx={{ mb: 2 }}>
              Only the lead reviewer can make changes to the reviewer decision.
            </Alert>
          )}

          {props.decisionTypeCode === DecisionTypeCode.Committee && (
            <Alert severity={firmAcknowledgesReports ? "success" : "warning"} sx={{ mb: 2 }}>
              {firmAcknowledgesReports
                ? "The firm has acknowledged receiving the reports."
                : "The firm has not acknowledged receiving the reports."}
            </Alert>
          )}

          {props.decisionTypeCode === DecisionTypeCode.Committee && noFirmResponse && (
            <Alert severity="info" sx={{ mb: 2 }}>
              No firm response has been provided.
            </Alert>
          )}

          <Card variant="outlined" sx={{ p: 2, flexGrow: 1 }}>
            <Grid container columnSpacing={5} rowSpacing={3}>
              <Grid item xs={12} sm={6}>
                <FormControl className={classes.complianceButtons}>
                  <ReadOnlyWrapper title={userCanEditReason} readOnly={!userCanEditNormalFields}>
                    <Button
                      className={cx(classes.complianceButton, {
                        [classes.unselectedComplianceButton]: decisionIsNonComply,
                        [classes.selectedComplianceButton]: decisionIsComply,
                        [classes.comply]: decisionIsComply
                      })}
                      disableElevation
                      variant={decisionIsComply ? "contained" : "outlined"}
                      onClick={() => {
                        if (!formik.values.isComply) {
                          formik.setValues({
                            ...formik.values,
                            complianceZoneId: null,
                            motionStandardId: noneValue,
                            motionPprpProgramReviewId: noneValue,
                            motionPdId: noneValue,
                            motionCpabId: noneValue,
                            motionCicId: noneValue,
                            directedPdCourses: []
                          });
                        }
                        formik.setFieldValue("isComply", true);
                        unsavedChanges();
                      }}>
                      {decisionIsComply && <FontAwesomeIcon icon={faCheck} />}
                      Comply
                    </Button>
                  </ReadOnlyWrapper>
                  <ReadOnlyWrapper title={userCanEditReason} readOnly={!userCanEditNormalFields}>
                    <Button
                      className={cx(classes.complianceButton, {
                        [classes.unselectedComplianceButton]: decisionIsComply,
                        [classes.selectedComplianceButton]: decisionIsNonComply,
                        [classes.nonComply]: decisionIsNonComply
                      })}
                      disableElevation
                      variant={decisionIsNonComply ? "contained" : "outlined"}
                      onClick={() => {
                        if (formik.values.isComply) {
                          formik.setValues({
                            ...formik.values,
                            complianceZoneId: null,
                            motionStandardId: noneValue,
                            motionPprpProgramReviewId: noneValue,
                            motionPdId: noneValue,
                            motionCpabId: noneValue,
                            motionCicId: noneValue
                          });
                        }
                        formik.setFieldValue("isComply", false);
                        unsavedChanges();
                      }}>
                      {decisionIsNonComply && <FontAwesomeIcon icon={faTimes} />}
                      Non-comply
                    </Button>
                  </ReadOnlyWrapper>
                </FormControl>
                {decisionIsNonComply && !isPprpProgramReview && (
                  <div style={{ width: "100%" }}>
                    <FormControl
                      sx={{ mt: 3 }}
                      component="fieldset"
                      className={classes.areasOfNonCompliance}
                      error={formik.touched.isComply && Boolean(formik.errors.isComply)}>
                      <FormLabel component="legend" sx={{ mb: 1 }}>
                        Areas of Non-compliance
                      </FormLabel>
                      <FormGroup>
                        <ReadOnlyWrapper title={userCanEditReason} readOnly={!userCanEditNormalFields}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={formik.values.nonComplyFinancial}
                                onChange={handleCheckboxChange}
                                name="nonComplyFinancial"
                              />
                            }
                            label="Financial Statements"
                          />
                        </ReadOnlyWrapper>
                        <ReadOnlyWrapper title={userCanEditReason} readOnly={!userCanEditNormalFields}>
                          <FormControlLabel
                            control={
                              <Checkbox checked={formik.values.nonComplyAudit} onChange={handleCheckboxChange} name="nonComplyAudit" />
                            }
                            label="Audit"
                          />
                        </ReadOnlyWrapper>
                        <ReadOnlyWrapper title={userCanEditReason} readOnly={!userCanEditNormalFields}>
                          <FormControlLabel
                            control={
                              <Checkbox checked={formik.values.nonComplyReview} onChange={handleCheckboxChange} name="nonComplyReview" />
                            }
                            label="Review"
                          />
                        </ReadOnlyWrapper>
                        <ReadOnlyWrapper title={userCanEditReason} readOnly={!userCanEditNormalFields}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={formik.values.nonComplyCompilation}
                                onChange={handleCheckboxChange}
                                name="nonComplyCompilation"
                              />
                            }
                            label="Compilation"
                          />
                        </ReadOnlyWrapper>
                        <ReadOnlyWrapper title={userCanEditReason} readOnly={!userCanEditNormalFields}>
                          <FormControlLabel
                            control={
                              <Checkbox checked={formik.values.nonComplyOther} onChange={handleCheckboxChange} name="nonComplyOther" />
                            }
                            label="Other (please specify)"
                          />
                        </ReadOnlyWrapper>
                        {formik.values.nonComplyOther && (
                          <ReadOnlyWrapper title={userCanEditReason} readOnly={!userCanEditNormalFields}>
                            <TextField
                              multiline
                              required
                              className={classes.detailsField}
                              margin="none"
                              value={formik.values.nonComplyOtherSpecified || ""}
                              onChange={(e) => {
                                formik.setFieldValue("nonComplyOtherSpecified", e.target.value);
                                unsavedChanges();
                              }}
                              error={formik.touched.nonComplyOtherSpecified && formik.errors.nonComplyOtherSpecified !== undefined}
                              helperText={formik.touched.nonComplyOtherSpecified && formik.errors.nonComplyOtherSpecified}
                              onBlur={() => formik.setFieldTouched("nonComplyOtherSpecified", true)}
                            />
                          </ReadOnlyWrapper>
                        )}
                        <ReadOnlyWrapper title={userCanEditReason} readOnly={!userCanEditNormalFields}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={formik.values.nonComplyQualityManagement}
                                onChange={handleCheckboxChange}
                                name="nonComplyQualityManagement"
                              />
                            }
                            label="Quality Management"
                          />
                        </ReadOnlyWrapper>
                        <ReadOnlyWrapper title={userCanEditReason} readOnly={!userCanEditNormalFields}>
                          <FormControlLabel
                            control={<Checkbox checked={formik.values.nonComplyTax} onChange={handleCheckboxChange} name="nonComplyTax" />}
                            label="Tax"
                          />
                        </ReadOnlyWrapper>
                        <FormHelperText sx={{ ml: 0 }}>{formik.touched.isComply && formik.errors.isComply}</FormHelperText>
                      </FormGroup>
                    </FormControl>
                  </div>
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                <Stack spacing={1}>
                  <StackedStaticDataDisplay
                    label={`${
                      !decision ||
                      decision.decisionType.typeCode === DecisionTypeCode.Reviewer ||
                      decision.decisionType.typeCode === DecisionTypeCode.Manager
                        ? "Tentative "
                        : ""
                    }Committee Meeting`}
                    value={
                      props.practiceReview.committeeMeeting
                        ? `${props.practiceReview.committeeMeeting.name}${
                            props.practiceReview.mostRecentlyTabled
                              ? ` (tabled from ${props.practiceReview.mostRecentlyTabled.committeeMeeting.name})`
                              : ""
                          }`
                        : "--"
                    }
                  />

                  {showCommitteeFields && (
                    <StackedStaticDataDisplay
                      label="Committee Decision Entered"
                      value={decision!.signedOffDate ? formatDate(decision!.signedOffDate) : "--"}
                    />
                  )}
                </Stack>

                {showDirectorCommitteeFields && !isPprpProgramReview && (decisionIsNonComply || consequencesZone) && (
                  <Box
                    sx={{
                      mt: 3
                    }}>
                    <ReadOnlyWrapper title={userCanEditReason} readOnly={!userCanEditNormalFields}>
                      <Autocomplete
                        className={classes.pdCourseSelector}
                        multiple
                        disableClearable
                        filterSelectedOptions
                        options={allPdCourses.filter((d) => d.isActive)}
                        getOptionLabel={(course) => course?.name}
                        getOptionDisabled={(course) => formik.values.recommendedPdCourseIds.indexOf(course.id) !== -1}
                        renderTags={(courses: PdCourse[], getTagProps: any) =>
                          courses.map((course: PdCourse, index: number) => (
                            <Chip
                              key={course?.id}
                              label={course?.name}
                              size={decision?.decisionType.typeCode !== DecisionTypeCode.Committee ? "small" : "medium"}
                              {...getTagProps({ index })}
                              title={course?.name}
                            />
                          ))
                        }
                        renderInput={(params) => <TextField {...params} label="Directed PD Courses" />}
                        value={
                          formik.values.directedPdCourses.map((directedCourseId) => {
                            return allPdCourses.find((course: PdCourse) => directedCourseId.courseId === course.id)!;
                          })!
                        }
                        onChange={(e, newCourses) => {
                          formik.setFieldValue(
                            "directedPdCourses",
                            newCourses.map((course) => ({
                              courseId: course.id,
                              isCompleted: formik.values.directedPdCourses.find((dpdc) => dpdc.courseId === course.id)?.isCompleted ?? false
                            }))
                          );
                          unsavedChanges();
                        }}
                      />
                    </ReadOnlyWrapper>

                    {showCommitteeFields && (
                      <ReadOnlyWrapper title={userCanEditReason} readOnly={!userCanEditNormalFields}>
                        <FormikField
                          component={PrsDatePickerField}
                          name="directedPdDueDate"
                          label="Date Directed PD to Be Taken By"
                          fullWidth
                          minDate={DateTime.local()}
                          onBlur={() => {
                            formik.setFieldTouched("directedPdDueDate", true);
                          }}
                          onChange={(newValue: DateTime) => {
                            formik.setFieldValue("directedPdDueDate", newValue);
                            unsavedChanges();
                          }}
                        />
                      </ReadOnlyWrapper>
                    )}
                  </Box>
                )}

                {!isPprpProgramReview && (
                  <>
                    <ReadOnlyWrapper title={userCanEditReason} readOnly={!userCanEditNormalFields}>
                      <Autocomplete
                        sx={{ mt: 3 }}
                        className={classes.pdCourseSelector}
                        multiple
                        disableClearable
                        filterSelectedOptions
                        options={allPdCourses.filter((d) => d.isActive)}
                        getOptionLabel={(course) => course?.name}
                        getOptionDisabled={(course) =>
                          formik.values.directedPdCourses.some((directedPdCourse) => directedPdCourse.courseId === course.id)
                        }
                        renderTags={(courses: PdCourse[], getTagProps: any) =>
                          courses.map((course: PdCourse, index: number) => (
                            <Chip key={course?.id} label={course?.name} size="small" {...getTagProps({ index })} title={course?.name} />
                          ))
                        }
                        renderInput={(params) => <TextField {...params} label="Recommended PD Courses" />}
                        value={
                          formik.values.recommendedPdCourseIds.map((recommendedCourseId) => {
                            return allPdCourses.find((course: PdCourse) => recommendedCourseId === course.id)!;
                          })!
                        }
                        onChange={(e, newCourses) => {
                          formik.setFieldValue(
                            "recommendedPdCourseIds",
                            newCourses.map((course) => course.id)
                          );
                          unsavedChanges();
                        }}
                      />
                    </ReadOnlyWrapper>
                    {userCanEditNormalFields && showDirectorCommitteeFields && (decisionIsNonComply || consequencesZone) && (
                      <Stack direction="row" justifyContent="flex-end">
                        <Button
                          variant="contained"
                          sx={{
                            padding: "0 12px",
                            fontSize: "0.75em"
                          }}
                          onClick={moveRecommendedCoursesToDirected}
                          disabled={formik.values.recommendedPdCourseIds.length === 0}>
                          Move All to Directed
                        </Button>
                      </Stack>
                    )}
                  </>
                )}
              </Grid>
              <Grid item container xs={12} columnSpacing={5}>
                <Grid item xs={12} lg={6}>
                  <FormControl component="fieldset" error={formik.touched.isComply && Boolean(formik.errors.isComply)}>
                    {props.decisionTypeCode !== DecisionTypeCode.Reviewer && (
                      <div>
                        <FormLabel component="legend" sx={{ mb: 1 }}>
                          Compliance Zone
                        </FormLabel>
                        <Stack>
                          {validComplianceZones.map((zone) => (
                            <ReadOnlyWrapper title={userCanEditReason} readOnly={!userCanEditNormalFields}>
                              <FormControlLabel
                                key={zone.id}
                                control={
                                  <Radio
                                    className={classes.complianceZoneButton}
                                    name={zone.zoneCode}
                                    checked={formik.values.complianceZoneId === zone.id}
                                    value={zone.id}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                      formik.setFieldValue("complianceZoneId", zone.id);
                                      unsavedChanges();
                                    }}
                                  />
                                }
                                label={zone.name}
                              />
                            </ReadOnlyWrapper>
                          ))}
                        </Stack>
                      </div>
                    )}
                    {zone?.requiresFollowUpScope && (
                      <Box sx={{ mt: 1 }}>
                        {/* MUST INCLUDE TO AVOID REACT CONDITIONAL ERROR */ decision?.isComplete}
                        <ReadOnlyWrapper title={userCanEditReason} readOnly={!userCanEditNormalFields}>
                          <FormControl
                            component="fieldset"
                            error={formik.touched.requiresFullScopeFollowUp && Boolean(formik.errors.requiresFullScopeFollowUp)}>
                            <FormLabel className={classes.label}>Follow-up Scope</FormLabel>
                            <Box sx={{ display: "grid", gridTemplateColumns: "6em 6em" }}>
                              <FormControlLabel
                                control={
                                  <Radio
                                    checked={formik.values.requiresFullScopeFollowUp === "true"}
                                    value="true"
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                      formik.setFieldValue("requiresFullScopeFollowUp", "true");
                                      unsavedChanges();
                                    }}
                                  />
                                }
                                label="Full"
                              />
                              <FormControlLabel
                                control={
                                  <Radio
                                    checked={formik.values.requiresFullScopeFollowUp === "false"}
                                    value="false"
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                      formik.setFieldValue("requiresFullScopeFollowUp", "false");
                                      unsavedChanges();
                                    }}
                                  />
                                }
                                label="Partial"
                              />
                              <FormHelperText sx={{ ml: 0 }}>{requiredFullScopeFollowUpValidation}</FormHelperText>
                            </Box>
                          </FormControl>
                        </ReadOnlyWrapper>

                        {formik.values.requiresFullScopeFollowUp === "true" && (
                          <ReadOnlyWrapper title={userCanEditReason} readOnly={!userCanEditNormalFields}>
                            {/* MUST INCLUDE TO AVOID REACT CONDITIONAL ERROR */ decision?.isComplete}
                            <RichTextEditor
                              label={"Full-scope Follow-up Comments"}
                              minHeight={userCanEditNormalFields ? "6em" : undefined}
                              html={formik.values.fullScopeFollowUpComments}
                              passContentRetriever={(getContentAsHtml) => {
                                fullScopeFollowUpCommentsContentRetriever.current = { getContentAsHtml };
                              }}
                              reportUnsavedChanges
                              hideToolbar
                              onChange={onRichTextChange}
                            />
                          </ReadOnlyWrapper>
                        )}
                      </Box>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Stack spacing={2}>
                    {showFollowUp && zone?.maySupplyFollowUpDate && (
                      <Box sx={{ maxWidth: "12em" }}>
                        <ReadOnlyWrapper title={userCanEditReason} readOnly={!userCanEditNormalFields}>
                          <FormikField
                            component={PrsDatePickerField}
                            name="followUpDate"
                            label="Follow-up Date"
                            fullWidth
                            minDate={DateTime.local()}
                            onBlur={() => {
                              formik.setFieldTouched("followUpDate", true);
                            }}
                            onChange={(newValue: DateTime) => {
                              formik.setFieldValue("followUpDate", newValue);
                              unsavedChanges();
                            }}
                          />
                        </ReadOnlyWrapper>
                      </Box>
                    )}
                    {nonComplyZone && showFollowUp && (
                      <ReadOnlyWrapper title={userCanEditReason} readOnly={!userCanEditNormalFields}>
                        <RichTextEditor
                          label="Non-comply Zone Note"
                          minHeight={userCanEditNormalFields ? "6em" : undefined}
                          html={formik.values.nonComplyZoneNote}
                          passContentRetriever={(getContentAsHtml) => {
                            nonComplyZoneNoteContentRetriever.current = { getContentAsHtml };
                          }}
                          reportUnsavedChanges
                          hideToolbar
                          onChange={onRichTextChange}
                        />
                      </ReadOnlyWrapper>
                    )}
                  </Stack>
                </Grid>
              </Grid>
              {showDirectorCommitteeFields && consequencesZone && (
                <Grid item xs={12}>
                  <ReadOnlyWrapper title={userCanEditReason} readOnly={!userCanEditNormalFields || !userCanEditDirectorCommitteeFields}>
                    <RichTextEditor
                      label="Description of Intended Action"
                      minHeight={userCanEditDirectorCommitteeFields ? "6em" : undefined}
                      html={formik.values.descriptionOfIntendedAction}
                      passContentRetriever={(getContentAsHtml) => {
                        descriptionOfIntendedActionContentRetriever.current = { getContentAsHtml };
                      }}
                      reportUnsavedChanges
                      hideToolbar
                      onChange={onRichTextChange}
                    />
                  </ReadOnlyWrapper>
                </Grid>
              )}
              {isPprpProgramReview && (
                <Grid item xs={3}>
                  <FormControlLabel
                    control={
                      <ReadOnlyWrapper title={userCanEditReason} readOnly={!userCanEditNormalFields}>
                        <Checkbox
                          checked={formik.values.monitoringRequired}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            formik.setFieldValue("monitoringRequired", e.target.checked);
                            unsavedChanges();
                          }}
                          name="monitoringRequired"
                        />
                      </ReadOnlyWrapper>
                    }
                    label="Monitoring Required"
                  />
                </Grid>
              )}
              {showMonitoringFields && (
                <Grid container item xs={6} spacing={2}>
                  <Grid item xs={5}>
                    <ReadOnlyWrapper title={userCanEditReason} readOnly={!userCanEditDirectorCommitteeFields}>
                      <FormControl fullWidth>
                        <InputLabel id="monitoringFrequencySelect-label" shrink required>
                          Frequency
                        </InputLabel>
                        <Select
                          labelId="monitoringFrequencySelect-label"
                          label="Frequency *"
                          value={formik.values.monitoringFrequencyInDays}
                          onChange={(e) => {
                            formik.setFieldValue("monitoringFrequencyInDays", e.target.value);
                            formik.setFieldTouched("monitoringFrequencyInDays", true);
                            unsavedChanges();
                          }}>
                          <MenuItem value={90}>Quarterly</MenuItem>
                          <MenuItem value={180}>Semi-Annually</MenuItem>
                          <MenuItem value={365}>Annually</MenuItem>
                        </Select>
                      </FormControl>
                    </ReadOnlyWrapper>
                  </Grid>
                  <Grid item xs={7} style={{ paddingTop: 0 }}>
                    <ReadOnlyWrapper title={userCanEditReason} readOnly={!userCanEditDirectorCommitteeFields}>
                      <FormikField
                        component={FmuiTextField}
                        name="monitoringCount"
                        label="Number of Occurrences"
                        type="number"
                        fullWidth
                        required
                        InputProps={{
                          inputProps: { min: 1 }
                        }}
                      />
                    </ReadOnlyWrapper>
                  </Grid>
                </Grid>
              )}
              {showApproxMonitoringDates && (
                <Grid item style={{ paddingTop: 0 }}>
                  <Stack sx={{ pl: 4 }}>
                    <Typography>Approximate monitoring dates:</Typography>
                    <ul style={{ margin: 0 }}>
                      {Array.from({ length: formik.values.monitoringCount! }).map((_, i) => {
                        console.log(props.practiceReview.committeeMeeting?.meetingDate);
                        const monitoringDate = DateTime.fromISO(props.practiceReview.committeeMeeting!.meetingDate)
                          .plus({
                            days: 14 + formik.values.monitoringFrequencyInDays! * (i + 1)
                          })
                          .toISO();
                        return (
                          <li>
                            <Typography>{formatDate(monitoringDate)}</Typography>
                          </li>
                        );
                      })}
                    </ul>
                  </Stack>
                </Grid>
              )}
              <Grid item container spacing={5}>
                {(isPprpProgramReview || Boolean(decision?.pprpProgramContinuance)) && (
                  <Grid item xs={12} md={6} xl={4}>
                    <ReadOnlyWrapper title={userCanEditReason} readOnly={!userCanEditNormalFields}>
                      <YesNoNaQuestion
                        formik={formik}
                        fieldName="pprpProgramContinuance"
                        label="Continuance of PPRP status?"
                        requireExplanationForNo
                      />
                    </ReadOnlyWrapper>
                  </Grid>
                )}
                {!isPprpProgramReview && (
                  <>
                    <Grid item xs={12} md={6} xl={4}>
                      <ReadOnlyWrapper title={userCanEditReason} readOnly={!userCanEditNormalFields}>
                        <YesNoNaQuestion formik={formik} fieldName="cpabParticipating" label="CPAB participating firm?" />
                      </ReadOnlyWrapper>
                    </Grid>
                    <Grid item xs={12} md={6} xl={4}>
                      <ReadOnlyWrapper title={userCanEditReason} readOnly={!userCanEditNormalFields}>
                        <YesNoNaQuestion formik={formik} fieldName="cpdCompliance" label="CPD compliance?" requireExplanationForNo />
                      </ReadOnlyWrapper>
                    </Grid>
                    <Grid item xs={12} md={6} xl={4}>
                      <ReadOnlyWrapper title={userCanEditReason} readOnly={!userCanEditNormalFields}>
                        <YesNoNaQuestion formik={formik} fieldName="pliCompliance" label="PLI compliance?" requireExplanationForNo />
                      </ReadOnlyWrapper>
                    </Grid>
                  </>
                )}
              </Grid>
              {showDirectorCommitteeFields && (
                <>
                  <Grid item xs={12} container columnSpacing={3}>
                    <Grid item xs={12}>
                      <Typography variant="h4" sx={{ mb: 0 }}>
                        Motions
                      </Typography>
                    </Grid>
                    {isPprpProgramReview ? (
                      <>
                        <MotionSelector
                          fieldName="motionPprpProgramReviewId"
                          label="PPRP Programs"
                          motionType={MotionTypeCode.PPRPProgram}
                          showPprpProgramReviewMotions
                        />
                      </>
                    ) : (
                      <>
                        <MotionSelector fieldName="motionStandardId" label="Standard" motionType={MotionTypeCode.Standard} />
                        <MotionSelector fieldName="motionPdId" label="PD" motionType={MotionTypeCode.PD} />
                        <MotionSelector fieldName="motionCpabId" label="CPAB" motionType={MotionTypeCode.CPAB} />
                        <MotionSelector fieldName="motionCicId" label="CIC" motionType={MotionTypeCode.CIC} />
                      </>
                    )}
                  </Grid>

                  <Grid item xs={12}>
                    <Stack direction="row" spacing={1}>
                      <Typography variant="h4" sx={{ mb: 0, mr: 3, alignSelf: "center" }}>
                        Additional Comments
                      </Typography>

                      {userCanEditDirectorCommitteeFields && (
                        <>
                          <FormControl variant="outlined" size="small" sx={{ flex: 1 }}>
                            <InputLabel htmlFor="additional-paragraph-id">Additional Paragraph to Append</InputLabel>
                            <Select
                              label="Additional Paragraph to Append"
                              inputProps={{
                                id: "additional-paragraph-id"
                              }}
                              value={selectedAdditionalParagraphId}
                              onChange={(e: SelectChangeEvent<any>) => setSelectedAdditionalParagraphId(e.target.value)}>
                              {_.orderBy(additionalParagraphs, (ap) => ap.name).map((ap) => (
                                <MenuItem value={ap.id} key={ap.id}>
                                  {ap.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <Button variant="outlined" onClick={() => appendSelectedAdditionalParagraph()}>
                            Append
                          </Button>
                        </>
                      )}
                    </Stack>
                  </Grid>

                  <Grid item xs={12}>
                    <RichTextEditor
                      label="Additional Comments for Minutes"
                      minHeight={userCanEditDirectorCommitteeFields ? "6em" : undefined}
                      html={formik.values.additionalCommentsForMinutes}
                      passContentRetriever={(getContentAsHtml) => {
                        additionalCommentsForMinutesContentRetriever.current = { getContentAsHtml };
                      }}
                      reportUnsavedChanges
                      showMergeFields
                      mergeFieldType={isPprpProgramReview ? MergeFieldType.RegComMeetingItem : MergeFieldType.MeetingMinutes}
                      templateMarkup
                      readOnly={!userCanEditNormalFields || !userCanEditDirectorCommitteeFields}
                      onChange={onRichTextChange}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <ReadOnlyWrapper title={userCanEditReason} readOnly={!userCanEditNormalFields || !userCanEditDirectorCommitteeFields}>
                      <RichTextEditor
                        label="Additional Comments for Decision Letter"
                        minHeight={userCanEditDirectorCommitteeFields ? "6em" : undefined}
                        html={formik.values.additionalCommentsForDecisionLetter}
                        passContentRetriever={(getContentAsHtml) => {
                          additionalCommentsForDecisionLetterContentRetriever.current = { getContentAsHtml };
                        }}
                        reportUnsavedChanges
                        showMergeFields
                        mergeFieldType={MergeFieldType.DecisionLetter}
                        templateMarkup
                        onChange={onRichTextChange}
                      />
                    </ReadOnlyWrapper>
                  </Grid>

                  {decision?.legacyAdditionalComments && decision.legacyAdditionalComments.trim().length > 0 && (
                    <Grid item xs={12}>
                      <ReadOnlyNotes
                        header="Additional Comments (from old PRS)"
                        value={decision.legacyAdditionalComments}
                        plainText={false}
                        height="100%"
                      />
                    </Grid>
                  )}
                </>
              )}
              {showReviewerFields && !isPprpProgramReview && (
                <Grid item xs={12}>
                  <ReadOnlyNotes
                    header="Notes from Estimate Time"
                    value={props.practiceReview.estimate.notes ?? "--"}
                    plainText={false}
                    height="100%"
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <ReadOnlyWrapper title={userCanEditReason} readOnly={!userCanEditNormalFields || !userCanEditReviewerFields}>
                  <RichTextEditor
                    label="Reviewer's Memo to Director"
                    minHeight={userCanEditReviewerFields ? "16em" : undefined}
                    html={formik.values.reviewerMemo}
                    passContentRetriever={(getContentAsHtml) => {
                      reviewerMemoContentRetriever.current = { getContentAsHtml };
                    }}
                    reportUnsavedChanges
                    hideToolbar
                    onChange={onRichTextChange}
                  />
                </ReadOnlyWrapper>
              </Grid>
              {showManagerMemo && !isPprpProgramReview && (
                <Grid item xs={12}>
                  <ReadOnlyWrapper title={userCanEditReason} readOnly={!userCanEditFieldsEditableAfterSignoff || !userCanEditManagerFields}>
                    <RichTextEditor
                      label="Manager's Memo to Director"
                      minHeight={userCanEditManagerFields ? "16em" : undefined}
                      html={formik.values.managerMemo}
                      passContentRetriever={(getContentAsHtml) => {
                        managerMemoContentRetriever.current = { getContentAsHtml };
                      }}
                      reportUnsavedChanges
                      hideToolbar
                      onChange={onRichTextChange}
                    />
                  </ReadOnlyWrapper>
                </Grid>
              )}
              {showDirectorCommitteeFields && (
                <>
                  <Grid item xs={12}>
                    <ReadOnlyWrapper title={userCanEditReason} readOnly={!userCanEditNormalFields || !userCanEditDirectorFields}>
                      <RichTextEditor
                        label="Director's Highlights"
                        minHeight={userCanEditDirectorFields ? "6em" : undefined}
                        html={formik.values.directorsHighlights}
                        passContentRetriever={(getContentAsHtml) => {
                          directorsHighlightsContentRetriever.current = { getContentAsHtml };
                        }}
                        reportUnsavedChanges
                        hideToolbar
                        onChange={onRichTextChange}
                      />
                    </ReadOnlyWrapper>
                  </Grid>

                  <Grid item xs={12}>
                    <ReadOnlyWrapper
                      title={userCanEditReason}
                      readOnly={!userCanEditFieldsEditableAfterSignoff || !userCanEditDirectorFields}>
                      <RichTextEditor
                        label="Director's Summary of Firm's Response"
                        minHeight={userCanEditDirectorFields ? "6em" : undefined}
                        html={formik.values.directorsSummaryOfFirmResponse}
                        passContentRetriever={(getContentAsHtml) => {
                          directorsSummaryOfFirmResponseContentRetriever.current = { getContentAsHtml };
                        }}
                        reportUnsavedChanges
                        hideToolbar
                        onChange={onRichTextChange}
                      />
                    </ReadOnlyWrapper>
                  </Grid>

                  {isPprpProgramReview && (
                    <Grid item xs={12}>
                      <ReadOnlyWrapper title={userCanEditReason} readOnly={!userCanEditNormalFields || !userCanEditDirectorFields}>
                        <RichTextEditor
                          label="Recommendations to Committee"
                          minHeight={userCanEditDirectorFields ? "6em" : undefined}
                          html={formik.values.recommendationsToCommittee}
                          passContentRetriever={(getContentAsHtml) => {
                            recommendationsToCommitteeContentRetriever.current = { getContentAsHtml };
                          }}
                          reportUnsavedChanges
                          hideToolbar
                          onChange={onRichTextChange}
                        />
                      </ReadOnlyWrapper>
                    </Grid>
                  )}
                </>
              )}
            </Grid>

            {userCanSaveDecision && (
              <Box sx={{ mt: 3, display: "flex", justifyContent: "flex-end" }}>
                <Tooltip
                  title={
                    decision?.decisionType.typeCode === DecisionTypeCode.Reviewer && signedOff && userIsLeadReviewer
                      ? "The decision has been signed off."
                      : ""
                  }>
                  <span>
                    <LoadingButton
                      color="primary"
                      variant="outlined"
                      loading={saveMutation.loading}
                      onClick={
                        shouldConfirmDecisionChange(
                          decision,
                          props.practiceReview.decisions,
                          formik.values.isComply,
                          props.practiceReview.isPprpProgramReview
                        )
                          ? async () => {
                              const errors = await formik.validateForm();
                              formik.setTouched(setNestedObjectValues(errors, true));

                              if (formik.isValid) {
                                setConfirmingDecisionChange(true);
                              }
                            }
                          : () => {
                              formik.setFieldValue("discussDecisionChange", false, false);
                              formik.setFieldValue(
                                "cancelDecisionChange",
                                shouldCancelDecisionChange(decision, props.practiceReview.decisions, formik.values.isComply),
                                false
                              );
                              formik.submitForm();
                            }
                      }
                      disabled={decision?.decisionType.typeCode === DecisionTypeCode.Reviewer && signedOff}>
                      Save
                    </LoadingButton>
                  </span>
                </Tooltip>
              </Box>
            )}

            {confirmingDecisionChange && (
              <ConfirmationDialog
                open={true}
                body={
                  <>
                    <DialogContentText>
                      {`You have changed the decision. Do you want the changes discussed with the lead reviewer and firm?`}
                    </DialogContentText>
                    <DialogContentText>
                      {`(Note that you will not be able to sign off on your decision until the discussion INA is completed.)`}
                    </DialogContentText>
                  </>
                }
                title="Decision Changed"
                noDanger
                cancelButtonText="Cancel"
                cancelButtonVariant="outlined"
                cancel={() => setConfirmingDecisionChange(false)}
                confirmButtonText="Ok"
                confirm={() => confirmDecisionChange(true)}
              />
            )}

            {confirmingDecisionChangeWithoutSaveButton.confirmChange && (
              <ConfirmationDialog
                open={true}
                body={
                  <>
                    <DialogContentText>
                      {`You have changed the decision. Do you want the changes discussed with the lead reviewer and firm?`}
                    </DialogContentText>
                    <DialogContentText>
                      {`(Note that you will not be able to sign off on your decision until the discussion INA is completed.)`}
                    </DialogContentText>
                  </>
                }
                title="Decision Changed"
                noDanger
                cancelButtonText="Cancel"
                cancelButtonVariant="outlined"
                cancel={() => setConfirmingDecisionChangeWithoutSaveButton({ confirmChange: null, signOffChecklistItem: null })}
                confirmButtonText="Ok"
                confirm={() =>
                  confirmDecisionChangeWithoutSaveButton(true, confirmingDecisionChangeWithoutSaveButton.signOffChecklistItem!)
                }
              />
            )}
          </Card>
        </Grid>
      </FormikProvider>
      <Grid item xs={12} md={4} lg={3}>
        <Stack spacing={3}>
          {decision?.decisionType.typeCode === DecisionTypeCode.Committee && (
            <Tooltip
              title={props.practiceReview.isFinalReportReviewedByDirector ? "The final report has already been marked as reviewed." : ""}>
              <span>
                <Card variant="outlined" sx={{ p: 2 }}>
                  <Typography variant="h3" gutterBottom>
                    Table to Future Meeting
                  </Typography>
                  {meetingsThatPrCanBeTabledTo.length === 0 && (
                    <Alert color="warning">There are no meetings eligible to table PRs to.</Alert>
                  )}
                  <Formik
                    initialValues={{ committeeMeetingId: null, reason: "" } as TableFormValues}
                    validationSchema={tablingFormValidationSchema}
                    onSubmit={tablePr}>
                    {(formikProps) => (
                      <>
                        <FormikField
                          component={FmuiTextField}
                          select
                          name="committeeMeetingId"
                          label="Committee Meeting"
                          fullWidth
                          required
                          disabled={
                            formikProps.isSubmitting ||
                            props.practiceReview.isFinalReportReviewedByDirector ||
                            meetingsThatPrCanBeTabledTo.length === 0 ||
                            committeeDecision?.signedOff
                          }
                          error={formikProps.touched.committeeMeetingId && Boolean(formikProps.errors.committeeMeetingId)}
                          helperText={formikProps.touched.committeeMeetingId && formikProps.errors.committeeMeetingId}>
                          {_.orderBy(meetingsThatPrCanBeTabledTo, (m) => m.meetingDate).map((meeting) => (
                            <MenuItem value={meeting.id}>
                              <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                {meeting.name}
                                <span
                                  className={cx(classes.meetingCapacity, {
                                    [classes.meetingOverCapacity]: meeting.assignedReviews.length >= meeting.reviewQuota
                                  })}>{`${meeting.assignedReviews.length}/${meeting.reviewQuota}`}</span>
                              </Box>
                            </MenuItem>
                          ))}
                        </FormikField>

                        <FormikField
                          component={FmuiTextField}
                          name="reason"
                          label="Reason"
                          required
                          multiline
                          fullWidth
                          helperText="This will appear in the meeting minutes."
                          disabled={
                            formikProps.isSubmitting ||
                            props.practiceReview.isFinalReportReviewedByDirector ||
                            meetingsThatPrCanBeTabledTo.length === 0 ||
                            committeeDecision?.signedOff
                          }
                        />

                        <Stack alignItems="flex-end" sx={{ mt: 3 }}>
                          <LoadingButton
                            variant="outlined"
                            color="primary"
                            onClick={async () => {
                              await formikProps.submitForm();

                              if (formikProps.isValid) {
                                formikProps.resetForm();
                              }
                            }}
                            loading={tablePrMutation.loading}
                            disabled={
                              props.practiceReview.isFinalReportReviewedByDirector ||
                              meetingsThatPrCanBeTabledTo.length === 0 ||
                              committeeDecision?.signedOff
                            }>
                            Table
                          </LoadingButton>
                        </Stack>
                      </>
                    )}
                  </Formik>
                </Card>
              </span>
            </Tooltip>
          )}
          <Card variant="outlined" className={classes.signOffChecklistContainer}>
            <Typography variant="h3">
              {props.decisionTypeCode !== DecisionTypeCode.Committee
                ? `${
                    props.decisionTypeCode === DecisionTypeCode.Reviewer
                      ? "Practice Reviewer"
                      : props.decisionTypeCode === DecisionTypeCode.Manager
                      ? "Manager"
                      : props.decisionTypeCode === DecisionTypeCode.Director
                      ? "Director"
                      : ""
                  } Sign-off`
                : "Decision Completion"}
            </Typography>
            {signoffPreconditionMessages.length === 0 ? (
              props.decisionTypeCode !== DecisionTypeCode.Committee ? (
                <>
                  {userCanSaveDecision ? (
                    <Typography variant="body1">I have reviewed the following in detail:</Typography>
                  ) : (
                    <StackedStaticDataDisplay label="Lead Reviewer" value={props.practiceReview.leadReviewer?.user.name ?? "--"} />
                  )}
                  <div>
                    {signOffChecklistItems.map((item) => (
                      <div key={item.id} className={classes.signOffChecklistItem}>
                        {userCanSignOffDecision && !signedOff ? (
                          <Checkbox
                            title={item.questionText}
                            checked={item.isChecked}
                            onClick={
                              shouldConfirmDecisionChange(
                                decision,
                                props.practiceReview.decisions,
                                formik.values.isComply,
                                props.practiceReview.isPprpProgramReview
                              )
                                ? async () => {
                                    const errors = await formik.validateForm();
                                    formik.setTouched(setNestedObjectValues(errors, true));
                                    if (formik.isValid) {
                                      if (confirmingDecisionChangeWithoutSaveButton.confirmChange === null) {
                                        setConfirmingDecisionChangeWithoutSaveButton({ confirmChange: true, signOffChecklistItem: item });
                                      } else {
                                        toggleSignOffChecklistItem(item);
                                      }
                                    }
                                  }
                                : () => {
                                    toggleSignOffChecklistItem(item);
                                  }
                            }
                            disabled={
                              item.displayTemplateName === "DraftReviewReportSignOffChecklistItemTemplate"
                                ? props.practiceReview.practiceReviewReportUrl === null
                                : false
                            }
                          />
                        ) : (
                          <div className={classes.signOffChecklistItemState}>
                            <YesNoIcon yes={item.isChecked} />
                          </div>
                        )}
                        {getSignoffChecklistItemText(item)}
                      </div>
                    ))}
                  </div>

                  {userCanSaveDecision && !decision?.signedOff && (
                    <Stack alignItems="flex-end">
                      <Tooltip
                        title={
                          !signOffChecklistComplete
                            ? "Complete the checklist."
                            : !userCanSignOffDecision
                            ? "There are unsaved changes to the decision."
                            : ""
                        }>
                        <span>
                          <LoadingButton
                            variant="contained"
                            color="primary"
                            loading={saveMutation.loading || signOffDecisionMutation.loading}
                            disabled={!signOffChecklistComplete || !userCanSignOffDecision || false} // Strange problem here: without the "|| false", causes rules-of-hooks error
                            onClick={() => signOffDecision()}>
                            Sign Off
                          </LoadingButton>
                        </span>
                      </Tooltip>
                    </Stack>
                  )}

                  {decision?.signedOff && (
                    <Typography variant="h5">
                      {`Signed off by ${decision.signedOffByUser?.name ?? "unknown"} on
                  ${decision.signedOffDate ? formatDate(decision.signedOffDate) : "unknown"}`}
                    </Typography>
                  )}
                </>
              ) : committeeDecision?.signedOff ? (
                <Typography variant="body1">The committee decision is complete and final.</Typography>
              ) : (
                <Typography variant="body1">The committee decision is complete, but not final.</Typography>
              )
            ) : (
              signoffPreconditionMessages.map((message) => (
                <Typography key={message} variant="body1">
                  {message}
                </Typography>
              ))
            )}
          </Card>
        </Stack>
      </Grid>
    </Grid>
  );
};
