import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { IconButton, InputAdornment } from "@mui/material";

interface AdornmentProps {
  value: number;
  onChange: (newValue: number) => void;
  min?: number;
  max?: number;
}

export const NumberedTextFieldEndAdornment: React.FunctionComponent<AdornmentProps> = (props) => {
  const handleIncrement = () => {
    const newValue = Math.min((props.value || 0) + 1, props.max ? Number(props.max) : Infinity);
    props.onChange(newValue);
  };

  const handleDecrement = () => {
    const newValue = Math.max((props.value || 0) - 1, props.min ? Number(props.min) : -Infinity);
    props.onChange(newValue);
  };

  return (
    <InputAdornment position="end">
      <IconButton onClick={handleDecrement} size={"small"} aria-label="decrease value">
        <FontAwesomeIcon icon={faMinus} />
      </IconButton>
      <IconButton onClick={handleIncrement} size={"small"} aria-label="increase value">
        <FontAwesomeIcon icon={faPlus} />
      </IconButton>
    </InputAdornment>
  );
};
