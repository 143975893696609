import { gql } from "@apollo/client";

export const SaveExemptionNotesMutation = gql`
  mutation SaveExemptionNotes($exemptionId: Int!, $notes: String) {
    exemption {
      saveNotes(exemptionId: $exemptionId, notes: $notes) {
        id
        notes
      }
    }
  }
`;

export const ReleaseExemptionsForApprovalMutation = gql`
  mutation ReleaseExemptionForApproval($exemptionIds: [Int!]!) {
    exemption {
      releaseLettersForApproval(exemptionIds: $exemptionIds) {
        id
        letterIsReviewed
      }
    }
  }
`;

export const ApproveExemptionLettersMutation = gql`
  mutation ApproveExemptionLetters($exemptionIds: [Int!]!) {
    exemption {
      approveLetters(exemptionIds: $exemptionIds) {
        id
        letterIsApproved
      }
    }
  }
`;

export const GenerateExemptionLettersMutation = gql`
  mutation GenerateExemptionLetters($exemptionIds: [Int!]!) {
    exemption {
      generateExemptionLetters(exemptionIds: $exemptionIds) {
        id
        practiceReview {
          id
          exemptionLetterUrl
        }
      }
    }
  }
`;

export const ConfirmExemptionMutation = gql`
  mutation ConfirmExemption($exemptionId: Int!) {
    exemption {
      confirm(exemptionId: $exemptionId) {
        id
        status
        startedOn
        approvedByUser {
          id
          name
        }
        practiceReview {
          id
          status {
            id
            statusName
          }
        }
      }
    }
  }
`;

export const ApproveExemptionLetterMutation = gql`
  mutation ApproveExemptionLetter($exemptionId: Int!) {
    exemption {
      approveLetter(exemptionId: $exemptionId) {
        id
        letterIsApproved
        startedOn
        approvedByUser {
          id
          name
        }
      }
    }
  }
`;

export const CancelPendingExemptionMutation = gql`
  mutation CancelPendingExemption($practiceReviewId: Int!, $endDate: Date!, $reason: String!, $selectNewDate: Boolean!, $newDate: Date) {
    practiceReview {
      cancelPendingExemption(
        practiceReviewId: $practiceReviewId
        endDate: $endDate
        reason: $reason
        selectNewDate: $selectNewDate
        newDate: $newDate
      ) {
        id
        startDate
        endDate
        reviewYear
        committeeMeeting {
          id
          name
          isOpen
        }
        status {
          id
          baseStatusCode
          statusName
        }
        phase {
          id
          name
        }
        exemption {
          id
          status
          endedOn
          endedByUser {
            id
            name
          }
          endedReason
        }
      }
    }
  }
`;

export const EndExemptionMutation = gql`
  mutation EndExemption($practiceReviewId: Int!, $endDate: Date!, $reason: String!, $selectNewDate: Boolean!, $newDate: Date) {
    practiceReview {
      endExemption(
        practiceReviewId: $practiceReviewId
        endDate: $endDate
        reason: $reason
        selectNewDate: $selectNewDate
        newDate: $newDate
      ) {
        id
        status {
          id
          baseStatusCode
          statusName
        }
        phase {
          id
          name
        }
        exemption {
          id
          status
          endedOn
          endedByUser {
            id
            name
          }
          endedReason
        }
      }
    }
  }
`;

export const FetchExemptionsWithLettersQuery = gql`
  query FetchExemptionsWithLetters($stage: ExemptionLetterStage, $onPprpProgramReviews: Boolean!) {
    exemptionsWithLetters(stage: $stage, onPprpProgramReviews: $onPprpProgramReviews) {
      id
      status
      startedOn
      notes
      practiceReview {
        id
        prNumber
        reviewType
        firm {
          id
          name
        }
        contactName
        contactEmail
        startDate
        status {
          id
          statusName
        }
        exemptionLetterUrl
      }
    }
  }
`;

export const FetchExemptionsQuery = gql`
  query FetchExemptions($ended: Boolean) {
    exemptions(ended: $ended) {
      id
      status
      startedOn
      startedReason
      approvedByUser {
        id
        name
      }
      endedOn
      endedReason
      endedByUser {
        id
        name
      }
      exemptionLetterSent
      exemptionLetterAcknowledged
      practiceReview {
        id
        prNumber
        prStage
        startDate
        reviewType
        isPprpProgramReview
        firm {
          id
          name
          entityNumber
        }
        inas {
          id
          isComplete
          type {
            id
            friendlyName
          }
        }
        exemption {
          id
          status
          startedOn
        }
        pprpProgram {
          id
          programEntityNumber
        }
      }
    }
  }
`;
