import React, { useState } from "react";
import { PracticeReview, ToggleQuestionnaireItemReviewedChecklistItemMutation } from "practice-reviews";
import { DocType } from "common/DocType";
import { Link, Tooltip, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus } from "@fortawesome/free-solid-svg-icons/faMinus";
import { makeStyles } from "../../makeStyles";
import { YesNoIcon } from "../../common/YesNoIcon";
import { useCurrentUser } from "../../users";
import { useMutation } from "@apollo/client";
import { summaryChecklistStyles } from "../../styles/common";
import { LoadingButton } from "@mui/lab";
import { SummaryTabHeader } from "./SummaryTabHeader";
import { getOpenableUrl } from "../../util/utilities";

const useStyles = makeStyles()((theme) => ({
  ...summaryChecklistStyles(theme)
}));

interface Props {
  practiceReview: PracticeReview;
}

export const QuestionnaireSummary: React.FunctionComponent<Props> = (props) => {
  const { classes } = useStyles();
  const [togglingReviewedDocType, setTogglingReviewedDocType] = useState<DocType | null>(null);

  const practiceReview = props.practiceReview;
  const { userIsLeadReviewer } = useCurrentUser();
  const isLeadReviewer = userIsLeadReviewer(practiceReview);

  const [toggleSignOffChecklistItemMutate, toggleSignOffChecklistMutation] = useMutation<
    {
      practiceReview: {
        toggleQuestionnaireReviewed: Partial<PracticeReview>;
      };
    },
    {
      practiceReviewId: number;
      reviewedDocument: DocType;
    }
  >(ToggleQuestionnaireItemReviewedChecklistItemMutation);

  async function toggleChecklistItem(reviewedDocumentType: DocType) {
    if (reviewedDocumentType == null) return;

    setTogglingReviewedDocType(reviewedDocumentType);

    await toggleSignOffChecklistItemMutate({
      variables: {
        practiceReviewId: practiceReview.id,
        reviewedDocument: reviewedDocumentType
      }
    });

    setTogglingReviewedDocType(reviewedDocumentType);
  }

  const ReviewableFormLink: React.FunctionComponent<{
    name: string;
    checked: boolean;
    url: string | null;
    editable: boolean;
    linkToggled: (documentType: DocType) => void;
    documentTypeToToggle: DocType;
  }> = (props) => {
    return props.url ? (
      <div className={classes.checklistRow}>
        {props.editable && (
          <div className={classes.checklistItemState}>
            <YesNoIcon yes={props.checked} />
          </div>
        )}
        <Tooltip title="Open" className={classes.checklistItem}>
          <Link href={getOpenableUrl(props.url)} target="_blank">
            <Typography variant="body1">{props.name}</Typography>
          </Link>
        </Tooltip>
        {props.editable && (
          <div className={classes.checklistItemAction}>
            <LoadingButton
              variant="outlined"
              size="small"
              onClick={() => props.linkToggled(props.documentTypeToToggle)}
              disabled={props.url == null}
              className={props.checked ? "removeAction" : ""}
              loading={toggleSignOffChecklistMutation.loading && togglingReviewedDocType === props.documentTypeToToggle}>
              {props.checked ? "Mark Not Reviewed" : "Mark Reviewed"}
            </LoadingButton>
          </div>
        )}
      </div>
    ) : (
      <div className={classes.checklistDisabledRow}>
        {props.editable && (
          <div className={classes.checklistItemState}>
            <FontAwesomeIcon icon={faMinus} />
          </div>
        )}
        <Tooltip title="This questionnaire was expected but was not provided">
          <Typography variant="body1" className={classes.checklistItem}>
            {props.name}
          </Typography>
        </Tooltip>
      </div>
    );
  };

  return (
    <div>
      <SummaryTabHeader header="Questionnaires" />
      <div className={classes.checklist}>
        {practiceReview.requiresSchedulingQuestionnaire && (
          <ReviewableFormLink
            name="Scheduling Questionnaire"
            checked={practiceReview.isSchedulingQuestionnaireReviewedByLead}
            url={practiceReview.schedulingQuestionnaireUrl}
            editable={isLeadReviewer}
            documentTypeToToggle={DocType.SchedulingQuestionnaire}
            linkToggled={toggleChecklistItem}
          />
        )}
        {practiceReview.requiresTaxQuestionnaire && (
          <ReviewableFormLink
            name="Tax Questionnaire"
            checked={practiceReview.isTaxQuestionnaireReviewedByLead}
            url={practiceReview.taxQuestionnaireUrl}
            editable={isLeadReviewer}
            documentTypeToToggle={DocType.TaxQuestionnaire}
            linkToggled={toggleChecklistItem}
          />
        )}
        {practiceReview.requiresSelfEvaluationQuestionnaire && (
          <ReviewableFormLink
            name="Self-evaluation Questionnaire"
            checked={practiceReview.isSelfEvaluationQuestionnaireReviewedByLead}
            url={practiceReview.selfEvaluationQuestionnaireUrl}
            editable={isLeadReviewer}
            documentTypeToToggle={DocType.SelfEvaluationQuestionnaire}
            linkToggled={toggleChecklistItem}
          />
        )}
      </div>
    </div>
  );
};
