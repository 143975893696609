import { gql } from "@apollo/client";

export const QuestionFieldsFragment = gql`
  fragment QuestionFields on Question {
    id
    parentId
    sortOrder
    cpaRef
    text
    standardParagraphs {
      id
      cpaRef
      title
      text
      recommendation
      isSignificantByDefault
      isRemedialByDefault
    }
  }
`;

export const AnswerFieldsFragment = gql`
  fragment AnswerFields on ChecklistAnswer {
    id
    questionId
    reviewedClientId
    isYes
    isReportable
    isNonReportable
    isNa
    note
    standardParagraphId
    isSignificant
    requiresRemedialAction
    fileDetails
    hasCustomDeficiency
    customCpaRef
    customParagraphText
    customRecommendationText
  }
`;

export const QuestionContainerFieldsFragment = gql`
  fragment QuestionContainerFields on QuestionContainer {
    id
    parentId
    sortOrder
    text
  }
`;

export const ChecklistSectionFieldsFragment = gql`
  fragment ChecklistSectionFields on ChecklistSection {
    shortDescription
  }
`;

export const QuestionHeaderFieldsFragment = gql`
  fragment QuestionHeaderFields on QuestionHeader {
    cpaRef
  }
`;
