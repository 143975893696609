import { gql } from "@apollo/client";
import { DecisionFieldsFragment } from "../decisions";

export const PrScreenQuery = gql`
  query GetPracticeReview($prNumber: String!) {
    practiceReviewFromPrNumber(prNumber: $prNumber) {
      id
      prNumber
      prNumberPrefix
      prStage
      reviewYear
      isNonAssurance
      isDeskReview
      isPprpProgramReview
      hasIncreasedRisk
      hasPaperFiles
      hasScannableFiles
      hasElectronicFiles
      hasTaxOnlyFiles
      isDeficiencyReportPosted
      hasBeenReturned
      isReturnedComplete
      reasonReturnedIncomplete
      firm {
        id
        name
        entityNumber
        firmStatus
        subStatus
        lastRefreshedFromDataSource
        entityType {
          typeCode
          typeShortName
        }
        address1
        address2
        city
        province
        country
        postalCode
        phoneNumber
        faxNumber
        practiceLocale
        zone
        pprpSubStatus
        isCpab
        designatedPartnerFirstName
        designatedPartnerLastName
        designatedPartnerTitle
        designatedPartnerDesignation
        hoDesignatedPartnerFirstName
        hoDesignatedPartnerLastName
        hoDesignatedPartnerTitle
        hoDesignatedPartnerDesignation
        hoDesignatedPartnerEmail
        satelliteOffices {
          id
          entityNumber
          name
          address
          city
          practiceReviewContactName
          practiceReviewContactPhone
          practiceReviewContactEmail
          includeWithMainOffice
        }
        latestPartners {
          id
          reviewYear

          name
          designation
          yearDesignationObtained
          isOther
          withFirmSince
          compilation
          audit
          review
        }
        latestClienteleProfile {
          firmId
          reviewYear

          publicCompaniesHours
          publicCompaniesClients
          offeringDocumentsHours
          offeringDocumentsClients
          otherEntitiesUnderIfrsHours
          otherEntitiesUnderIfrsClients
          notForProfitHours
          notForProfitClients
          publicSectorHours
          publicSectorClients
          otherAuditHours
          otherAuditClients

          reviewYearEndHours
          reviewYearEndClients
          reviewEntitiesUnderIfrsHours
          reviewEntitiesUnderIfrsClients
          reviewInterimHours
          reviewInterimClients

          businessValuationsHours
          businessValuationsClients
          insolvencyHours
          insolvencyClients
          trustAccountingHours
          trustAccountingClients
          compilationHours
          compilationClients
          handlingOfTrustFundsHours
          handlingOfTrustFundsClients
          accountingServicesHours
          accountingServicesClients
          taxServicesHours
          taxServicesClients
          forensicHours
          forensicClients
          otherServicesHours
          otherServicesClients

          totalHours
          totalClients
        }
      }
      startDate
      endDate
      completedDate
      initialDueDate
      datesHaveBeenConfirmed
      actualTimeToDate
      timeEntries {
        id
        enteredDate
        enteredHours
        isAdmin
        user {
          id
          cammsEntityNumber
          name
        }
      }
      reviewType
      prNotes
      notesFromSchedulingPhase
      status {
        id
        baseStatusCode
        statusName
      }
      phase {
        id
        name
      }
      contactName
      contactTitle
      contactDesignation
      contactPhone
      contactEmail
      contactEmailUri

      requiresSchedulingQuestionnaire
      schedulingQuestionnaireUrl
      isMissingSchedulingQuestionnaire
      isSchedulingQuestionnaireReviewedByLead

      requiresTaxQuestionnaire
      taxQuestionnaireUrl
      isMissingTaxQuestionnaire
      isTaxQuestionnaireReviewedByLead

      requiresConfidentialClientList
      confidentialClientListUrl
      isMissingConfidentialClientList

      requiresSelfEvaluationQuestionnaire
      selfEvaluationQuestionnaireUrl
      isMissingSelfEvaluationQuestionnaire
      isSelfEvaluationQuestionnaireReviewedByLead

      deficiencyReportUrl
      practiceReviewReportUrl
      checklistNotesReportUrl
      decisionLetterUrl
      pdDeclarationFormUrl
      finalReportPackageUrl

      directedPDNotes
      isDirectedPdCompleted
      directedPdCompletedByUser {
        id
        name
      }
      directedPdCompletedDate

      firmProfileForReviewYear {
        firmId
        reviewYear

        fullTimePartners
        designatedAccountantsFt
        designatedAccountantsPt
        candidatesFt
        candidatesPt
        otherFt
        otherPt
      }
      hasBeenEstimated
      estimate {
        id
        date
        isFinalized
        compilationTotal
        otherQamTotal
        finalizeReviewTotal
        totalSystemEstimate
        overrideEstimate
        finalEstimate
        notes
        partners {
          id
          firmPartnerId
          timeEstimate
        }
      }
      previousReview {
        id
        prNumber
        prNumberPrefix
        startDate
        actualTimeToDate
        reviewYear
        otherReviewers {
          practiceReviewId
          userId
          user {
            id
            name
          }
        }
        leadReviewer {
          practiceReviewId
          userId
          user {
            id
            name
          }
        }
        status {
          id
          baseStatusCode
        }
        mostAuthoritativeDecision {
          id
          complianceZone {
            id
            zoneCode
          }
        }
      }
      committeeMeeting {
        id
        name
        isOpen
        meetingDate
      }
      leadReviewer {
        practiceReviewId
        userId
        user {
          id
          name
        }
        isLead
        reviewerNotes
      }
      otherReviewers {
        practiceReviewId
        userId
        user {
          id
          name
        }
        isLead
        reviewedClientsSubmitted
        reviewedClientsHaveBeenUnsubmitted
        reviewerNotes
        resubmissionNotes
      }
      upcomingReviewNotice {
        id
        notes
      }
      attachedDocuments {
        id
        type
        typeFriendlyName
        fileName
        url
        portalSigneeName
        portalDeclarationDate
      }
      sharePointUrl
      levelOfAdjustment {
        id
        level
        reasonForAdjustment
        adjustmentQuestions {
          id
          questionText
          answer
        }
      }
      clientsToReview {
        id
        clientName
      }
      reviewedClients {
        id
        refNum
        name
        partnerName
        engagementType {
          id
          name
        }
        reviewedByUserId
        checklistCompletion {
          someQuestionsIncomplete
        }
        clientFile {
          businessNature
          fiscalYearEnd
          cpabReview
          competitiveBid
          isHoursNotFees
          feesOrHoursAmount
          materiality
          assets
          liabilities
          revenue
          netIncomeBeforeTaxes
          reportDate
        }
        candidate {
          idNo
          perEffectiveDate
          monthsExperience
          mentorReviews
          requiredNoOfReviews
          rotation1
          rotation1Duration
          rotation2
          rotation2Duration
          rotation3
          rotation3Duration
          rotation4
          rotation4Duration
          reasonableAuditChargeableHours
          reasonableReviewChargeableHours
          reasonableOtherAssuranceChargeableHours
          reasonableCompilationChargeableHours
          reasonableTaxationChargeableHours
          reasonableOtherChargeableHours
          additionalComments
        }
      }
      firmChecklistCompletion {
        someQuestionsComplete
        someQuestionsIncomplete
      }
      decisions {
        ...DecisionFields
      }
      invoiceAmount
      invoiceGst
      invoiceTotal
      invoiceIsFinal
      firmResponse {
        id
        dateReceived
        firmAcknowledgesReports
        noFirmResponse
        responseHtml
        isRedacted
      }
      mostRecentlyTabled {
        practiceReviewId
        committeeMeetingId
        committeeMeeting {
          id
          name
        }
      }
      isFinalReportReviewedByCoordinator
      exemption {
        id
        status
        startedOn
        startedReason
        approvedByUser {
          id
          name
        }
        endedOn
        endedByUser {
          id
          name
        }
        endedReason
        notes
        letterIsReviewed
        letterIsApproved
        exemptionLetterAcknowledged
      }
      exemptionLetterUrl
      signedExemptionLetterUrl
      pprpProgram {
        id
        programEntityNumber
        name
        status
        subStatus
        approvalDate
        programPath
        restrictions
        conditions
        positionsApproved

        address1
        address2
        city
        province
        postalCode

        pertCandidateTotal
        pertCandidateTotalPerPert
        pertCandidateApprovalLimit
        pertCandidateCountPerLocation
        pertCandidateDifference

        members {
          id
          memberEntityNumber
          name
          isPrimaryProgramLeader
          isSecondaryProgramLeader
          isProgramManager
          hasSigningAuthority
        }
      }
      pprpProgramDetails {
        technicalCompetencyApprovedDepth
        technicalCompetencyApprovedCore
        approvalLimitComments
        firmProfileCurrentApprovalLimit
        firmProfileCurrentStudentsInProgram
        firmProfileMaxStudentSupportBySch
      }

      monitoringOccurrencesRemaining
      monitoringFrequencyInDays
      monitoringNotes
      monitoringCompleted
      monitorings {
        id
        monitoringDate
        isCompleted
        isPass
        isReleased
        sendToCommitteeOnFail
        monitoringNotes
        monitoringDate
        monitoringCompletedLetterId
        letterGeneratedDate
        createdDate
        completedDate
        completedByUser {
          id
          name
        }
      }
      latestMonitoring {
        id
        monitoringDate
        completedDate
        completedByUser {
          id
          name
        }
      }
    }
  }
  ${DecisionFieldsFragment}
`;

export const GeneratePrReportMutation = gql`
  mutation GeneratePrReport($practiceReviewId: Int!) {
    practiceReview {
      generateReport(practiceReviewId: $practiceReviewId, documentType: PracticeReviewReport) {
        id
        practiceReviewReportUrl
      }
    }
  }
`;

export const GenerateDeficiencyReportMutation = gql`
  mutation GenerateDeficiencyReport($practiceReviewId: Int!, $parameters: ReviewReportParametersInput!) {
    practiceReview {
      generateReport(practiceReviewId: $practiceReviewId, documentType: DeficiencyReport, parameters: $parameters) {
        id
        deficiencyReportUrl
      }
    }
  }
`;

export const GenerateChecklistNotesReportMutation = gql`
  mutation GenerateChecklistNotesReport($practiceReviewId: Int!) {
    practiceReview {
      generateReport(practiceReviewId: $practiceReviewId, documentType: ChecklistNotesReport) {
        id
        checklistNotesReportUrl
      }
    }
  }
`;

export const ReturnReviewMutation = gql`
  mutation ReturnReview($practiceReviewId: Int!, $complete: Boolean!, $reasonReturnedIncomplete: String) {
    practiceReview {
      return(practiceReviewId: $practiceReviewId, complete: $complete, reasonReturnedIncomplete: $reasonReturnedIncomplete) {
        id
        hasBeenReturned
        isReturnedComplete
        phase {
          id
          name
        }
        decisions {
          id
        }
      }
    }
  }
`;

export const ReturnReviewToReviewerMutation = gql`
  mutation ReturnReviewToReviewer($practiceReviewId: Int!, $reasonForChange: String!) {
    practiceReview {
      returnToReviewer(practiceReviewId: $practiceReviewId, reasonForChange: $reasonForChange) {
        id
        prNotes
        hasBeenReturned
        isReturnedComplete
        phase {
          id
          name
        }
      }
    }
  }
`;

export const SubmitReviewedClientsMutation = gql`
  mutation SubmitReviewedClients($practiceReviewId: Int!, $userId: Int!, $submitted: Boolean!, $resubmissionNotes: String) {
    reviewer {
      submitReviewedClients(
        practiceReviewId: $practiceReviewId
        userId: $userId
        submitted: $submitted
        resubmissionNotes: $resubmissionNotes
      ) {
        practiceReviewId
        userId
        reviewedClientsSubmitted
        resubmissionNotes
      }
    }
  }
`;

export const SaveSummaryNotesMutation = gql`
  fragment ReviewerNotesFields on Reviewer {
    userId
    practiceReviewId
    reviewerNotes
  }
  mutation SaveNotes($practiceReviewId: Int!, $notes: String) {
    practiceReview {
      saveSummaryNotes(practiceReviewId: $practiceReviewId, notes: $notes) {
        id
        leadReviewer {
          ...ReviewerNotesFields
        }
        otherReviewers {
          ...ReviewerNotesFields
        }
      }
    }
  }
`;

export const ToggleQuestionnaireItemReviewedChecklistItemMutation = gql`
  mutation ToggleQuestionnaireItemReviewedChecklistItem($practiceReviewId: Int!, $reviewedDocument: DocumentTypeCode!) {
    practiceReview {
      toggleQuestionnaireReviewed(practiceReviewId: $practiceReviewId, reviewedDocument: $reviewedDocument) {
        id
        isSchedulingQuestionnaireReviewedByLead
        isTaxQuestionnaireReviewedByLead
        isSelfEvaluationQuestionnaireReviewedByLead
      }
    }
  }
`;

export const RemoveAttachedDocumentFromPracticeReviewMutation = gql`
  mutation RemoveAttachedDocumentFromPracticeReview($practiceReviewId: Int!, $attachedDocumentId: Int!, $documentType: DocumentTypeCode!) {
    practiceReview {
      removeAttachedDocument(practiceReviewId: $practiceReviewId, attachedDocumentId: $attachedDocumentId, documentType: $documentType) {
        id
        isMissingSchedulingQuestionnaire
        schedulingQuestionnaireUrl
        isMissingTaxQuestionnaire
        taxQuestionnaireUrl
        isMissingConfidentialClientList
        confidentialClientListUrl
        isMissingSelfEvaluationQuestionnaire
        selfEvaluationQuestionnaireUrl
        signedExemptionLetterUrl
        attachedDocuments {
          id
          type
          fileName
          url
        }
      }
    }
  }
`;

export const CreateRedactedCopyOfFirmResponseMutation = gql`
  mutation CreateRedactedCopyOfFirmResponse($practiceReviewId: Int!) {
    practiceReview {
      createRedactedCopyOfFirmResponse(practiceReviewId: $practiceReviewId) {
        id
        attachedDocuments {
          id
          type
          fileName
          url
        }
      }
    }
  }
`;

export const ApproveFinalReportsMutation = gql`
  mutation ApproveFinalReport(
    $committeeMeetingId: Int!
    $prIdsToApproveFinalReports: [Int!]!
    $prIdsToApproveDecisionTabledLetters: [Int!]!
  ) {
    practiceReview {
      approveFinalReports(
        committeeMeetingId: $committeeMeetingId
        prIdsToApproveFinalReports: $prIdsToApproveFinalReports
        prIdsToApproveDecisionTabledLetters: $prIdsToApproveDecisionTabledLetters
      ) {
        id
        status {
          id
          baseStatusCode
          statusName
        }
        phase {
          id
          name
        }
        attachedDocuments {
          id
          type
          fileName
          url
        }
      }
    }
  }
`;

export const ToggleIsDeficiencyReportPostedMutation = gql`
  mutation ToggleIsDeficiencyReportPosted($practiceReviewId: Int!) {
    practiceReview {
      toggleIsDeficiencyReportPosted(practiceReviewId: $practiceReviewId) {
        id
        isDeficiencyReportPosted
      }
    }
  }
`;
