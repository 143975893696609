import { DecisionTypeCode } from "decisions";
import { PracticeReview, PRPhaseCode } from "practice-reviews";
import { CurrentUser, Permissions } from "users";

export function showLinkAndActionsGenerateButton(
  practiceReview: PracticeReview | undefined,
  currentUser: CurrentUser,
  reviewerMustBeLead: boolean
) {
  if (!practiceReview) return null;

  // if the review is still in the scheduling phase, then perform review documents can't be generated at all
  if (practiceReview.phase.id < PRPhaseCode.PerformPracticeReview) {
    return false;
  }

  // if the review has been returned, then ReturnedPR.Update is required to generate perform review documents
  if (practiceReview.hasBeenReturned) {
    return currentUser.userHasPermission(Permissions.ReturnedPrUpdate);
  }

  // otherwise, you have to be assigned to the review,
  return (
    currentUser.userIsLeadReviewer(practiceReview) ||
    (!reviewerMustBeLead && // but if you don't have to be lead, then you could be an other reviewer
      currentUser.userIsOtherReviewer(practiceReview))
  );
}

export function getReasonPrTypeCannotBeChanged(practiceReview: PracticeReview | undefined) {
  if (!practiceReview) return null;

  if (practiceReview.decisions.find((dec) => dec.decisionType.typeCode === DecisionTypeCode.Manager)?.signedOff)
    return "Manager decision has already been signed off.";

  if (practiceReview.reviewType === "PPRP Program") return "Cannot change type of PPRP Program Reviews.";

  return null;
}
