import {
  EngagementType,
  GenerateDeficiencyReportMutation,
  PracticeReview,
  PrReportParameters,
  SortOrderForClientFiles
} from "practice-reviews";
import { DocType } from "common/DocType";
import { useMutation } from "@apollo/client";
import { Dialog, DialogContent, DialogActions, Button, MenuItem, FormControl, Typography, Grid, Alert } from "@mui/material";
import React from "react";
import ClosableDialogTitle from "../common/ClosableDialogTitle";
import { LoadingButton } from "@mui/lab";
import { makeStyles } from "../makeStyles";
import { Permissions, useCurrentUser } from "../users";
import { Field as FormikField, Form as FormikForm, Formik } from "formik";
import { CheckboxWithLabel as FmuiCheckboxWithLabel } from "formik-mui";
import _ from "lodash";
import { TextField as FmuiTextField } from "formik-mui";
import { getOpenableUrl } from "../util/utilities";

const useStyles = makeStyles()((theme) => ({
  filtersHeader: {
    marginTop: theme.spacing(2)
  }
}));

interface Props {
  onClose: () => void;
  practiceReview: PracticeReview;
  regenerating: boolean;
}

export const DeficiencyReportDialog: React.FunctionComponent<Props> = (props) => {
  const { classes } = useStyles();
  const { user, userIsLeadReviewer, userHasPermission } = useCurrentUser();
  const canGenerateForAll = userIsLeadReviewer(props.practiceReview) || userHasPermission(Permissions.ReturnedPrUpdate);

  const [generateReportMutate, generateReportMutation] = useMutation<
    { practiceReview: { generateReport: PracticeReview } },
    { practiceReviewId: number; documentType: DocType; parameters: PrReportParameters }
  >(GenerateDeficiencyReportMutation);

  async function generateReport(values: FormValues) {
    const reportParameters: PrReportParameters = {
      sortOrder: values.sortOrder,
      filterFromReferenceLetter: values.filterFromReferenceLetter === "" ? null : values.filterFromReferenceLetter,
      filterToReferenceLetter: values.filterToReferenceLetter === "" ? null : values.filterToReferenceLetter,
      filterClientId: values.filterClientId === "All" ? null : Number(values.filterClientId),
      filterPartnerName: values.filterPartnerName === "All" ? null : values.filterPartnerName,
      filterReviewerUserId: values.filterReviewerUserId === "All" ? null : Number(values.filterReviewerUserId),
      filterEngagementTypeId: values.filterEngagementTypeId === "All" ? null : Number(values.filterEngagementTypeId),
      includeDecision: values.includeDecision
    };

    const result = await generateReportMutate({
      variables: { practiceReviewId: props.practiceReview.id, documentType: DocType.DeficiencyReport, parameters: reportParameters }
    });

    if (result.data?.practiceReview.generateReport.deficiencyReportUrl) {
      window.open(getOpenableUrl(result.data.practiceReview.generateReport.deficiencyReportUrl!));
    }

    props.onClose();
  }

  interface FormValues {
    sortOrder: SortOrderForClientFiles;
    filterFromReferenceLetter: string;
    filterToReferenceLetter: string;
    filterClientId: number | "All";
    filterPartnerName: string;
    filterReviewerUserId: number | "All";
    filterEngagementTypeId: number | "All";
    includeDecision: boolean;
  }

  const availableEngagementTypes: EngagementType[] = _.sortBy(
    _.uniq(_.map(props.practiceReview.reviewedClients, "engagementType")),
    "name"
  );

  const availablePartners: string[] = _.orderBy(
    _.union(
      props.practiceReview.firm?.latestPartners?.map((m) => m.name),
      props.practiceReview.reviewedClients?.map((r) => r.partnerName)
    ),
    (x) => x.toLowerCase()
  );

  const availableReviewers = _.union(
    [{ id: props.practiceReview.leadReviewer?.userId, name: props.practiceReview.leadReviewer?.user.name }],
    props.practiceReview.otherReviewers?.map((r) => {
      return {
        id: r.userId,
        name: r.user.name
      };
    })
  ).filter((r) => canGenerateForAll || r.id === user.id);

  const availableClients = _.orderBy(props.practiceReview.reviewedClients, (x) => x.name?.toLocaleUpperCase());

  const refnums = _.orderBy(
    _.map(
      props.practiceReview.reviewedClients.filter((rc) => rc.refNum?.length > 0),
      (rc) => rc.refNum?.toLocaleUpperCase()
    ),
    [(x: string) => x.replace("*", "").length, (x: string) => x]
  );

  const initialFormValues: FormValues = {
    sortOrder: canGenerateForAll ? SortOrderForClientFiles.ReferenceLetter : SortOrderForClientFiles.ClientName,
    filterFromReferenceLetter: "",
    filterToReferenceLetter: "",
    filterClientId: "All",
    filterPartnerName: "All",
    filterReviewerUserId: canGenerateForAll ? "All" : user.id,
    filterEngagementTypeId: "All",
    includeDecision: false
  };

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      open={true}
      onClose={() => {
        props.onClose();
      }}>
      <ClosableDialogTitle onClose={props.onClose}>Generate Deficiency Report</ClosableDialogTitle>

      <Formik
        initialValues={initialFormValues}
        onSubmit={async (values) => {
          await generateReport(values);
        }}>
        {(formikProps) => (
          <FormikForm>
            <DialogContent>
              {props.regenerating && (
                <Alert severity="warning" sx={{ mb: 2 }}>
                  You have already generated this document. If you regenerate it, any changes you may have made will be lost.
                </Alert>
              )}

              <Grid container rowSpacing={1} columnSpacing={4}>
                <Grid item xs={6}>
                  <FormControl variant="outlined" fullWidth size="small">
                    <FormikField component={FmuiTextField} select name="sortOrder" label="Sort Order for Client Files">
                      <MenuItem value="ClientName">Client Name</MenuItem>
                      <MenuItem value="EngagementGroup">Engagement Group</MenuItem>
                      <MenuItem value="EngagementType">Engagement Type</MenuItem>
                      <MenuItem value="Partner">Partner</MenuItem>
                      {canGenerateForAll && <MenuItem value="ReferenceLetter">Reference Letter</MenuItem>}
                      <MenuItem value="Reviewer">Reviewer</MenuItem>
                    </FormikField>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h4" className={classes.filtersHeader}>
                    Filters
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <FormControl variant="outlined" fullWidth size="small">
                    <FormikField component={FmuiTextField} select name="filterReviewerUserId" label="Reviewer">
                      {canGenerateForAll && <MenuItem value="All">All</MenuItem>}
                      {availableReviewers.map((reviewer) => (
                        <MenuItem value={reviewer.id} key={reviewer.id}>
                          {reviewer.name}
                        </MenuItem>
                      ))}
                    </FormikField>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl variant="outlined" fullWidth size="small">
                    <FormikField component={FmuiTextField} select name="filterPartnerName" label="Partner">
                      <MenuItem value="All">All</MenuItem>
                      {availablePartners.map((partner) => (
                        <MenuItem value={partner} key={partner}>
                          {partner}
                        </MenuItem>
                      ))}
                    </FormikField>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl variant="outlined" fullWidth size="small">
                    <FormikField component={FmuiTextField} select name="filterEngagementTypeId" label="Engagement Type">
                      <MenuItem value="All">All</MenuItem>
                      {availableEngagementTypes.map((engType) => (
                        <MenuItem value={engType.id} key={engType.id}>
                          {engType.name}
                        </MenuItem>
                      ))}
                    </FormikField>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl variant="outlined" fullWidth size="small">
                    <FormikField component={FmuiTextField} select name="filterClientId" label="Client">
                      <MenuItem value="All">All</MenuItem>
                      {availableClients.map((client) => (
                        <MenuItem value={client.id} key={client.id}>
                          {client.name}
                        </MenuItem>
                      ))}
                    </FormikField>
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <FormControl variant="outlined" fullWidth size="small">
                    <FormikField component={FmuiTextField} select name="filterFromReferenceLetter" label="From Ref. Num">
                      {refnums.map((refnum) => (
                        <MenuItem value={refnum} key={refnum}>
                          {refnum}
                        </MenuItem>
                      ))}
                    </FormikField>
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <FormControl variant="outlined" fullWidth size="small">
                    <FormikField component={FmuiTextField} select name="filterToReferenceLetter" label="To Ref. Num">
                      {refnums.map((refnum) => (
                        <MenuItem value={refnum} key={refnum}>
                          {refnum}
                        </MenuItem>
                      ))}
                    </FormikField>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormikField
                    component={FmuiCheckboxWithLabel}
                    type="checkbox"
                    name="includeDecision"
                    Label={{ label: "Include Decision" }}
                    onChange={(e: React.ChangeEvent<any>, newValue: boolean) => {
                      formikProps.setFieldValue("includeDecision", newValue);
                    }}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  props.onClose();
                }}>
                Cancel
              </Button>
              <LoadingButton
                loading={generateReportMutation.loading}
                onClick={() => formikProps.submitForm()}
                color={props.regenerating ? "error" : "primary"}
                variant="contained">
                {props.regenerating ? "Regenerate" : "Generate"}
              </LoadingButton>
            </DialogActions>
          </FormikForm>
        )}
      </Formik>
    </Dialog>
  );
};
