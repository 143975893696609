import React from "react";
import { Route, RouteProps, RouteComponentProps, StaticContext } from "react-router";
import { LoadingScreen } from "chrome/LoadingScreen";
import { useAuthentication } from "./AuthenticationProvider";
import { useCurrentUser, Permissions } from "../users";
import { AppRoute } from "../Routes";
import { Redirect } from "react-router-dom";

interface SecureRouteProps extends RouteProps {
  component: React.ComponentType<RouteComponentProps<any, StaticContext, unknown>> | React.ComponentType<any>;
  authorizedPermissions?: Permissions[];
}

export const SecureRoute = (props: SecureRouteProps) => {
  const { authenticated } = useAuthentication();
  const { userHasPermission, userLoading } = useCurrentUser();

  const { component: Component } = props;

  if (props.authorizedPermissions) {
    if (userLoading) {
      return <LoadingScreen mode="auth" />;
    } else if (!props.authorizedPermissions.some((p) => userHasPermission(p))) {
      return <Redirect to={AppRoute.INAs} />;
    }
  }

  return (
    <Route
      {...props}
      component={undefined}
      render={(componentProps) => (authenticated ? <Component {...componentProps} /> : <LoadingScreen mode="auth" />)}
    />
  );
};
