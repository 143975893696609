import { Card, CardContent, Stack, Grid, FormControlLabel, Radio } from "@mui/material";
import { Field as FormikField } from "formik";
import { TextField as FmuiTextField, RadioGroup as FmuiRadioGroup } from "formik-mui";
import StackedStaticDataDisplay from "common/StackedStaticDataDisplay";
import { formatDate } from "util/formats";
import ReadOnlyWrapper from "common/ReadOnlyWrapper";
import { MonitoringCompleteNotificationCard } from "./MonitoringCompleteNotificationCard";
import { Monitoring } from "./models";
import { AttachedDocument } from "practice-reviews";
import MonitoringFailedWorkflowSelector from "./MonitoringFailedWorkflowSelector";

export interface MonitoringCardProps {
  index: number;
  monitoring: Monitoring;
  monitoringCompletedLetter?: AttachedDocument;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  monitoringFailedWorkflowReadOnly: boolean;
  containerType: "review" | "item";
}

export const MonitoringCard: React.FC<MonitoringCardProps> = ({
  index,
  monitoring,
  monitoringCompletedLetter,
  setFieldValue,
  monitoringFailedWorkflowReadOnly,
  containerType
}) => {
  return (
    <Card variant="outlined" sx={{ width: "100%" }}>
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={2}>
            <Stack spacing={1}>
              <StackedStaticDataDisplay label="Review Progress On" value={formatDate(monitoring.monitoringDate)} />
              <StackedStaticDataDisplay label="Completed Monitoring" value={formatDate(monitoring.completedDate)} />
            </Stack>
          </Grid>
          <Grid item xs={8}>
            <ReadOnlyWrapper title={""} readOnly={monitoring.isCompleted}>
              <FormikField
                component={FmuiTextField}
                name={`monitorings[${index}].monitoringNotes`}
                multiline
                label="Monitoring Notes"
                variant="outlined"
                fullWidth
                minRows={4}
                sx={{ margin: 0 }}
                InputProps={{
                  readOnly: monitoring.isCompleted
                }}
              />
            </ReadOnlyWrapper>
          </Grid>
          <Grid item xs={2}>
            <ReadOnlyWrapper title={""} readOnly={monitoring.isCompleted}>
              <FormikField
                component={FmuiRadioGroup}
                name={`monitorings[${index}].isPass`}
                onChange={(_: React.ChangeEvent, value: string) => setFieldValue(`monitorings[${index}].isPass`, value === "true")}>
                <FormControlLabel value={true} control={<Radio />} label="Pass" />
                <FormControlLabel value={false} control={<Radio />} label="Fail" />
              </FormikField>
            </ReadOnlyWrapper>
          </Grid>
          <Grid item xs={12}>
            {monitoring.isCompleted && (
              <Card variant="outlined" sx={{ pr: 2 }}>
                <CardContent>
                  <Stack direction="row" spacing={8} alignItems="flex-start">
                    <MonitoringCompleteNotificationCard
                      monitoringId={monitoring.id}
                      monitoringCompletedLetter={monitoringCompletedLetter}
                      isReleased={monitoring.isReleased}
                      letterGeneratedDate={monitoring.letterGeneratedDate}
                      containerType={containerType}
                    />
                    {monitoring.isPass === false && (
                      <MonitoringFailedWorkflowSelector
                        sendToComitteeOnFail={monitoring.sendToCommitteeOnFail}
                        onChange={(sendToComitteeOnFail) => {
                          setFieldValue(`monitorings[${index}].sendToCommitteeOnFail`, sendToComitteeOnFail);
                        }}
                        index={index}
                        readOnly={monitoringFailedWorkflowReadOnly}
                      />
                    )}
                  </Stack>
                </CardContent>
              </Card>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default MonitoringCard;
