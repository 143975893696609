import React, { useEffect, useState } from "react";
import { HighlightRowType, Ina, InaTypeCode } from "./models";
import { IconButton, Link, Tooltip, Paper, Typography, Collapse, Box, Stack, Button, DialogContentText } from "@mui/material";
import { dataGridDateValueFormatter } from "util/formats";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { DataGridWithHeader } from "common/DataGridWithHeader";
import { datagridStyles, expanderStyles, inaPanelStyles } from "styles/common";
import useLocalStorage from "util/useLocalStorage";
import { makeStyles } from "../makeStyles";
import { GridColDef, GridRowId, GridSortCellParams, GridSortModel } from "@mui/x-data-grid-pro";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons/faChevronDown";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons/faArrowRight";
import _ from "lodash";
import ReassignInasDialog from "./ReassignInasDialog";
import SetPriorityOfInasDialog from "./SetPriorityOfInasDialog";
import { InaMenu } from "./InaMenu";
import { CommitteeMeeting } from "../committee-meetings";
import { ConfirmationDialog } from "../common/ConfirmationDialog";
import { Reference, useApolloClient, useMutation } from "@apollo/client";
import { CompleteMultipleInasMutation, GetInasForUserQuery } from ".";

const useStyles = makeStyles<Props>()((theme) => ({
  ...datagridStyles(theme),
  ...expanderStyles(theme),
  ...inaPanelStyles(theme),
  actions: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    width: "100%",
    "& :not(:first-child)": {
      marginLeft: theme.spacing(1)
    }
  },
  header: {
    display: "flex",
    alignItems: "baseline",
    padding: theme.spacing(2),
    color: theme.palette.primary.main,
    "& h2": {
      fontWeight: 500
    }
  },
  subtitle: {
    marginLeft: theme.spacing(5),
    color: theme.palette.text.secondary
  }
}));

interface Props {
  title: string;
  inas: Ina[];
  getNavigationRouteForMeeting?: (meeting: CommitteeMeeting) => string;
  getNavigationRouteForIna?: (ina: Ina) => string;
  completable?: boolean;
  className?: string;
  meetingActions?: React.ReactNode;
  prActions?: React.ReactNode;
  prActionsWidth?: number;
  getLastClickedRow?: HighlightRowType | null;
  navigateTo?: (event: React.MouseEvent<HTMLElement>, ina: Ina, id: GridRowId, location: string) => void;
  isRegistrationCommittee?: boolean;
}

export const MeetingInaPanel: React.FunctionComponent<Props> = (props) => {
  const { classes, cx } = useStyles(props);
  const history = useHistory();
  const apolloClient = useApolloClient();

  const [inasToReassign, setInasToReassign] = useState<Ina[] | null>(null);
  const [inasToSetPriorityOf, setInasToSetPriorityOf] = useState<Ina[] | null>(null);
  const [inasToComplete, setInasToComplete] = useState<Ina[] | null>(null);

  const inaTypeCode = props.inas[0]?.type.typeCode;

  const inasForPrsOrItemsWithMeetings = props.inas.filter((ina) =>
    props.isRegistrationCommittee ? ina.registrationCommitteeItem!.committeeMeeting : ina.practiceReview!.committeeMeeting
  );
  const meetingGroups = _.groupBy(inasForPrsOrItemsWithMeetings, (ina) =>
    props.isRegistrationCommittee ? ina.registrationCommitteeItem!.committeeMeeting!.id : ina.practiceReview!.committeeMeeting!.id
  );
  const sortedMeetingGroups = _.orderBy(
    _.values(meetingGroups),
    (group) =>
      props.isRegistrationCommittee
        ? group[0].registrationCommitteeItem!.committeeMeeting?.meetingDate
        : group[0].practiceReview!.committeeMeeting?.meetingDate,
    "desc"
  );

  const defaultSortModel = _.mapValues(meetingGroups, (group) => [
    {
      field: "practiceReview.startDate",
      sort: "asc"
    }
  ]);
  const [sortModels, setSortModels] = useLocalStorage(`INA meeting panel sort models: ${inaTypeCode}`, defaultSortModel);
  useEffect(() => {
    setSortModels({ ...defaultSortModel, ...sortModels }); // Need this in case there are new meetings not in the local storage object
  }, []);

  const [expanded, setExpanded] = useLocalStorage(`INA panel expanded: ${props.inas[0].type}`, true);

  const [completeInasMutate, completeInasMutation] = useMutation<
    { ina: { completeMultiple: number } },
    { inaIds: number[]; inaTypeCode: InaTypeCode }
  >(CompleteMultipleInasMutation);

  async function completeInas(inas: Ina[]) {
    const result = await completeInasMutate({
      variables: { inaIds: inas.map((ina) => ina.id), inaTypeCode: inas[0].type.typeCode },
      refetchQueries: [{ query: GetInasForUserQuery }]
    });

    if ((result.data?.ina.completeMultiple ?? 0) > 0) {
      for (let ina of inas) {
        // Immediately remove the INA from the cache
        const inaCacheId = apolloClient.cache.identify(ina as any);
        apolloClient.cache.modify({
          fields: {
            inas(existingInas: Reference[]) {
              return existingInas.filter((inaRef) => inaRef.__ref !== inaCacheId);
            }
          }
        });
      }

      setInasToComplete(null);
    }
  }

  const prColumns: GridColDef<Ina>[] = [
    {
      field: "practiceReview.prNumber",
      headerName: "PR No.",
      width: 70,
      valueGetter: ({ row }) => row.practiceReview!.prNumber,
      cellClassName: ({ row }) =>
        cx(classes.defaultLeftBorder, {
          [classes.new]: row.isNew,
          [classes.semiNew]: row.isSemiNew && !row.isNew
        }),
      sortComparator: (v1: any, v2: any, param1: GridSortCellParams, param2: GridSortCellParams) =>
        param1.api.getRow(param1.id)!.practiceReview.prNumber.localeCompare(param2.api.getRow(param2.id)!.practiceReview.prNumber)
    },
    {
      field: "practiceReview.firm.entityNumber",
      headerName: "Entity No.",
      headerClassName: classes.wrapHeader,
      width: 60,
      valueGetter: ({ row }) => row.practiceReview!.firm.entityNumber,
      sortComparator: (v1: any, v2: any, param1: GridSortCellParams, param2: GridSortCellParams) =>
        param1.api.getRow(param1.id)!.practiceReview.firm.entityNumber - param2.api.getRow(param2.id)!.practiceReview.firm.entityNumber
    },
    {
      field: "practiceReview.firm.name",
      headerName: "Firm Name",
      flex: 2,
      renderCell: ({ row, id }) => (
        <Tooltip title={row.practiceReview!.firm.name}>
          <Link to={props.getNavigationRouteForIna!(row)} component={RouterLink}>
            {row.practiceReview!.firm.name}
          </Link>
        </Tooltip>
      ),
      sortComparator: (v1: any, v2: any, param1: GridSortCellParams, param2: GridSortCellParams) =>
        param1.api.getRow(param1.id)!.practiceReview.firm.name.localeCompare(param2.api.getRow(param2.id)!.practiceReview.firm.name)
    },
    {
      field: "practiceReview.pprpProgram.programPath",
      headerName: "Program Path",
      headerClassName: classes.wrapHeader,
      flex: 1,
      valueGetter: ({ row }) => row.practiceReview!.pprpProgram?.programPath ?? "--",
      hide: props.inas.every((ina) => ina.practiceReview?.pprpProgram === null ?? true)
    },
    {
      field: "practiceReview.startDate",
      headerName: "Review Date",
      headerClassName: classes.wrapHeader,
      type: "date",
      width: 100,
      valueGetter: ({ row }) => row.practiceReview!.startDate,
      valueFormatter: dataGridDateValueFormatter
    },
    {
      field: "practiceReview.reviewType",
      headerName: "Type",
      width: 110,
      valueGetter: ({ row }) => row.practiceReview!.reviewType
    },
    {
      field: "actions",
      headerName: "Actions",
      width: props.prActionsWidth ?? 100,
      renderCell: ({ row, id }) => {
        const ina = row;

        return (
          <div className={classes.actions}>
            <Stack direction="row" spacing={1}>
              {props.prActions}
              {props.getNavigationRouteForIna && (
                <IconButton
                  color="primary"
                  size="small"
                  title="Go"
                  onClick={(event) => {
                    props.navigateTo?.(event, ina, id, props.getNavigationRouteForIna!(row));
                  }}>
                  <FontAwesomeIcon icon={faArrowRight} />
                </IconButton>
              )}
            </Stack>
          </div>
        );
      },
      hide: !props.prActions && !props.getNavigationRouteForIna
    }
  ];

  const regComColumns: GridColDef<Ina>[] = [
    {
      field: "registrationCommitteItem.entityNumber",
      headerName: "Entity Number",
      headerClassName: classes.wrapHeader,
      width: 90,
      valueGetter: ({ row }) => row.registrationCommitteeItem?.entityNumber ?? "--"
    },
    {
      field: "registrationCommitteeItem.registeredName",
      headerName: "Registered Name",
      headerClassName: classes.wrapHeader,
      flex: 1,
      valueGetter: ({ row }) =>
        !!props.getNavigationRouteForIna ? (
          <Tooltip title={row.registrationCommitteeItem!.registeredName}>
            <Link to={props.getNavigationRouteForIna(row)} component={RouterLink}>
              {row.registrationCommitteeItem?.registeredName ?? "--"}
            </Link>
          </Tooltip>
        ) : (
          row.registrationCommitteeItem?.registeredName ?? "--"
        )
    },
    {
      field: "registrationCommitteeItem.categoryName",
      headerName: "Category",
      headerClassName: classes.wrapHeader,
      flex: 1,
      valueGetter: ({ row }) => row.registrationCommitteeItem?.itemType.category.name ?? "--"
    },
    {
      field: "registrationCommitteeItem.itemTypeName",
      headerName: "Type",
      headerClassName: classes.wrapHeader,
      flex: 1,
      valueGetter: ({ row }) => row.registrationCommitteeItem?.itemType.name ?? "--"
    },
    {
      field: "registrationCommitteeItem.createdDate",
      headerName: "Created On",
      headerClassName: classes.wrapHeader,
      type: "date",
      width: 100,
      valueGetter: ({ row }) => row.registrationCommitteeItem?.createdDate,
      valueFormatter: dataGridDateValueFormatter
    },
    {
      field: "actions",
      headerName: "Actions",
      width: props.prActionsWidth ?? 100,
      renderCell: ({ row, id }) => {
        const ina = row;

        return (
          <div className={classes.actions}>
            <Stack direction="row" spacing={1}>
              {props.prActions}
              {props.getNavigationRouteForIna && (
                <IconButton
                  color="primary"
                  size="small"
                  title="Go"
                  onClick={(event) => {
                    props.navigateTo?.(event, ina, id, props.getNavigationRouteForIna!(row));
                  }}>
                  <FontAwesomeIcon icon={faArrowRight} />
                </IconButton>
              )}
            </Stack>
          </div>
        );
      },
      hide: !props.prActions && !props.getNavigationRouteForIna
    }
  ];

  return (
    <Paper className={cx(props.className)}>
      <div className={classes.header}>
        <IconButton size="small" onClick={() => setExpanded(!expanded)} className={classes.expander}>
          <FontAwesomeIcon icon={faChevronDown} className={cx(classes.expandedIcon, { rotated: expanded })} />
        </IconButton>
        <Typography variant="h2">{props.title}</Typography>
        <Typography variant="subtitle1" className={classes.subtitle}>
          {`${props.inas.length} ${props.isRegistrationCommittee ? "Registration Committee Item" : "PR"}${
            props.inas.length !== 1 ? "s" : ""
          } in ${sortedMeetingGroups.length} meeting${sortedMeetingGroups.length !== 1 ? "s" : ""}`}
        </Typography>
      </div>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        {_.map(sortedMeetingGroups, (meetingGroup, index) => {
          const meeting = props.isRegistrationCommittee
            ? meetingGroup[0].registrationCommitteeItem!.committeeMeeting!
            : meetingGroup[0].practiceReview!.committeeMeeting!;

          return (
            <Box key={meeting.id} pl={5} pr={2} pb={index === sortedMeetingGroups.length - 1 ? 2 : 3}>
              <DataGridWithHeader
                title={meeting.name}
                subtitle={`Priority: ${meetingGroup[0].priority}`}
                columns={props.isRegistrationCommittee ? regComColumns : prColumns}
                rows={meetingGroup}
                sortModel={sortModels[meeting.id] as GridSortModel}
                onSortModelChange={(newSortModel) => setSortModels({ ...sortModels, [meeting.id]: newSortModel })}
                className={props.className}
                collapsible
                storageKey={`${inaTypeCode} ${meeting.name}`}
                disableSelectionOnClick
                deemphasizeHeader
                displayWithoutContainer
                getLastClickedRow={props.getLastClickedRow}
                headerActions={
                  <Stack direction="row" spacing={1}>
                    {props.meetingActions}

                    {props.completable && (
                      <Button
                        color="secondary"
                        variant="outlined"
                        size="small"
                        className={classes.actionTextButton}
                        onClick={() => setInasToComplete(meetingGroup)}>
                        Done
                      </Button>
                    )}

                    <InaMenu inas={meetingGroup} setInasToReassign={setInasToReassign} setInasToSetPriorityOf={setInasToSetPriorityOf} />

                    {props.getNavigationRouteForMeeting && (
                      <IconButton
                        color="primary"
                        size="small"
                        title="Go"
                        onClick={() => history.push(props.getNavigationRouteForMeeting!(meeting))}>
                        <FontAwesomeIcon icon={faArrowRight} />
                      </IconButton>
                    )}
                  </Stack>
                }
              />
            </Box>
          );
        })}
      </Collapse>
      {inasToReassign && <ReassignInasDialog inas={inasToReassign} handleClose={() => setInasToReassign(null)} />}
      {inasToSetPriorityOf && <SetPriorityOfInasDialog inas={inasToSetPriorityOf} handleClose={() => setInasToSetPriorityOf(null)} />}
      {inasToComplete !== null && (
        <ConfirmationDialog
          open={true}
          body={<DialogContentText>Do you want to complete the meeting INAs?</DialogContentText>}
          title="Complete INAs for meeting?"
          noDanger
          cancel={() => setInasToComplete(null)}
          confirm={() => completeInas(inasToComplete)}
          loading={completeInasMutation.loading}
        />
      )}
    </Paper>
  );
};
