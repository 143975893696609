import { gql } from "@apollo/client";

export const FetchRegistrationCommitteeItemByIdQuery = gql`
  query FetchRegistrationCommitteeItemById($id: Int!) {
    registrationCommitteeItemById(id: $id) {
      id
      entityNumber
      dueDate
      createdDate
      registeredName
      contactName
      emailAddress
      phoneNumber
      mailingAddress1
      mailingAddress2
      city
      province
      postalCode
      notes
      background
      issue
      committeeToDecide
      decisionOptions
      isReleasedForApproval
      isApprovedForMeeting

      isFinalReportReviewedByRegistrar
      isFinalReportReviewedByChair

      isCompleted
      completedDate

      monitoringFrequencyInDays
      monitoringOccurrencesRemaining
      monitoringNotes
      monitoringCompleted
      monitoringCompletedDate

      itemType {
        id
        name
        category {
          id
          name
        }
      }
      committeeMeeting {
        id
        name
        binderGenerated
        meetingDate
      }
      attachedDocuments {
        id
        fileName
        isExcludedFromRegComBinder
        url
        type
      }
      decision {
        id
        isApproved
        isDeferred
        isRefused
        additionalCommentsForMinutes
        additionalCommentsForDecisionLetter
        generalNotes
        monitoringRequired
        monitoringFrequencyInDays
        monitoringCount
      }

      monitorings {
        id
        monitoringDate
        isCompleted
        isPass
        isReleased
        sendToCommitteeOnFail
        monitoringNotes
        monitoringCompletedLetterId
        createdDate
        completedDate
        completedByUser {
          id
          name
        }
      }
    }
  }
`;

export const FetchRegistrationCommitteeItemTypesWithCategories = gql`
  query FetchRegistrationCommitteeItemTypesWithCategories($includeInactive: Boolean!) {
    registrationCommitteeItemTypes(includeInactive: $includeInactive) {
      id
      name
      isActive
      sortOrder
      issueTemplate
      backgroundTemplate
      committeeToDecideTemplate
      decisionOptionsTemplate
      category {
        id
        name
        sortOrder
      }
    }
  }
`;

export const FetchRegistrationCommitteeItemCategories = gql`
  query FetchRegistrationCommitteeItemCategories($includeInactive: Boolean!) {
    registrationCommitteeItemCategories(includeInactive: $includeInactive) {
      id
      name
      sortOrder
      isActive
    }
  }
`;

export const FetchRegistrationCommitteeItemTypes = gql`
  query FetchRegistrationCommitteeItemTypes($includeInactive: Boolean!) {
    registrationCommitteeItemTypes(includeInactive: $includeInactive) {
      id
      name
      sortOrder
      isActive
      issueTemplate
      backgroundTemplate
      committeeToDecideTemplate
      decisionOptionsTemplate
      category {
        id
        name
      }
    }
  }
`;

export const SaveRegistrationCommitteeItemMutation = gql`
  mutation SaveRegistrationCommitteeItem($item: RegistrationCommitteeItemInput!) {
    registrationCommitteeItem {
      save(item: $item) {
        id
        entityNumber
        dueDate
        createdDate
        registeredName
        contactName
        emailAddress
        phoneNumber
        mailingAddress1
        mailingAddress2
        city
        province
        postalCode
        notes
        background
        issue
        committeeToDecide
        decisionOptions
        isReleasedForApproval
        isApprovedForMeeting

        isFinalReportReviewedByRegistrar
        isFinalReportReviewedByChair

        isCompleted
        completedDate

        monitoringFrequencyInDays
        monitoringOccurrencesRemaining
        monitoringNotes
        monitoringCompleted
        monitoringCompletedDate

        itemType {
          id
          name
        }
        committeeMeeting {
          id
          name
          binderGenerated
          meetingDate
        }
        attachedDocuments {
          id
          fileName
          isExcludedFromRegComBinder
          url
          type
        }

        monitorings {
          id
          monitoringDate
          isCompleted
          isPass
          isReleased
          sendToCommitteeOnFail
          monitoringNotes
          createdDate
          completedDate
          completedByUser {
            id
            name
          }
        }
      }
    }
  }
`;

export const SaveRegistrationCommitteeItemTypeMutation = gql`
  mutation SaveRegistrationCommtiteeItemType($itemType: RegistrationCommitteeItemTypeInput!) {
    registrationCommitteeItem {
      saveType(itemType: $itemType) {
        id
        name
        sortOrder
        isActive
        issueTemplate
        backgroundTemplate
        committeeToDecideTemplate
        decisionOptionsTemplate
        category {
          id
          name
        }
      }
    }
  }
`;

export const AssignRegistrationCommitteeItemsToMeetingMutation = gql`
  mutation AssignRegistrationCommitteeItemsToMeeting($itemIds: [Int!]!, $committeeMeetingId: Int!) {
    registrationCommitteeItem {
      assignToCommitteeMeeting(itemIds: $itemIds, committeeMeetingId: $committeeMeetingId) {
        id
        committeeMeeting {
          id
        }
      }
    }
  }
`;

export const DeleteFileFromRegistrationCommitteeItemMutation = gql`
  mutation DeleteFileFromRegistrationCommitteeItem($itemId: Int!, $attachedDocumentId: Int!) {
    registrationCommitteeItem {
      deleteFile(itemId: $itemId, attachedDocumentId: $attachedDocumentId) {
        id
        attachedDocuments {
          id
          fileName
          isExcludedFromRegComBinder
          url
        }
      }
    }
  }
`;

export const SendRegistrationCommitteeItemForApprovalMutation = gql`
  mutation SendRegistrationCommitteeItemForApproval($itemId: Int!) {
    registrationCommitteeItem {
      sendForApproval(itemId: $itemId) {
        id
        isReleasedForApproval
      }
    }
  }
`;

export const ApproveRegistrationCommitteeItemForMeetingMutation = gql`
  mutation SendRegistrationCommitteeItemForApproval($itemId: Int!) {
    registrationCommitteeItem {
      approveForMeeting(itemId: $itemId) {
        id
        isApprovedForMeeting
        attachedDocuments {
          id
          fileName
          url
          type
        }
      }
    }
  }
`;

export const SaveRegistrationCommitteeItemDecisionMutation = gql`
  mutation SaveRegistrationCommitteeItemDecision($decisionInput: RegistrationCommitteeItemDecisionInput!) {
    registrationCommitteeItem {
      saveDecision(decisionInput: $decisionInput) {
        id
        isApproved
        isDeferred
        isRefused
        additionalCommentsForMinutes
        additionalCommentsForDecisionLetter
        generalNotes
        monitoringRequired
        monitoringFrequencyInDays
        monitoringCount
      }
    }
  }
`;

export const ToggleFinalReportReviewedMutation = gql`
  mutation ToggleRegistrationCommitteeFinalReportReviewed($itemId: Int!, $role: RegistrationCommitteeDecisionReviewRole!) {
    registrationCommitteeItem {
      toggleFinalReportReviewed(itemId: $itemId, role: $role) {
        id
        isFinalReportReadyForReview
        isFinalReportReviewedByRegistrar
        isFinalReportReviewedByChair
      }
    }
  }
`;

export const ApproveFinalReportMutation = gql`
  mutation ApproveRegistrationCommitteeFinalReport($itemIds: [Int!]!) {
    registrationCommitteeItem {
      approveFinalReports(itemIds: $itemIds) {
        id
        isCompleted
        completedDate
        monitoringFrequencyInDays
        monitoringOccurrencesRemaining
        monitorings {
          id
          id
          monitoringDate
          isCompleted
          isPass
          isReleased
          sendToCommitteeOnFail
          monitoringNotes
          createdDate
          completedDate
          completedByUser {
            id
            name
          }
        }
      }
    }
  }
`;
