import React from "react";
import { Button, Dialog, DialogActions, DialogContent, FormHelperText, Grid, MenuItem, Stack } from "@mui/material";
import ClosableDialogTitle from "common/ClosableDialogTitle";
import { useMutation, useQuery } from "@apollo/client";
import { Field as FormikField, Form as FormikForm, Formik, FormikErrors, FormikProps } from "formik";
import { TextField as FmuiTextField, CheckboxWithLabel as FmuiCheckbox } from "formik-mui";
import RichTextEditor from "../common/RichTextEditor";
import { LoadingButton } from "@mui/lab";
import { MotionParagraph, MotionParagraphInput, MotionType, MotionTypeCode } from "../decisions";
import { AddMotionsMutation, FetchMotionsQuery, FetchMotionTypesQuery, UpdateMotionsMutation } from "./queries";
import { FetchActiveMotionsQuery } from "../decisions/queries";
import { fieldStyles } from "styles/common";
import { makeStyles } from "../makeStyles";
import { MergeFieldType } from "../common/HtmlMergeFields/models";
import { getHtmlForField, htmlHasContent } from "../util/utilities";

interface EditMotionDialogProps {
  handleClose: () => void;
  title: string;
  confirmButtonText: string;
  motion?: MotionParagraph;
  existingMotions: MotionParagraph[];
}

const useStyles = makeStyles()((theme) => ({
  ...fieldStyles(theme),
  inputRow: {
    alignItems: "center",
    justifyContent: "start"
  },
  checkboxes: {
    alignSelf: "stretch"
  },
  activeCheckbox: {
    alignSelf: "end"
  }
}));

interface FormValues {
  isActive: boolean;
  isNonComply: boolean;
  isComply: boolean;
  shortDescription: string | null;
  motionCode: string | null;
  motionTypeId: number | null;
  sortOrder: number | null;
  paragraphForMinutes: string | null;
  paragraphForDecisionLetter: string | null;
  paragraphForPprpProgramReviewSummaryDataSheet: string | null;
}

const EditMotionDialog = (props: EditMotionDialogProps) => {
  const { classes } = useStyles();

  const minutesContentRetriever = React.useRef<{ getContentAsHtml: () => string | null }>({ getContentAsHtml: () => null });
  const letterContentRetriever = React.useRef<{ getContentAsHtml: () => string | null }>({ getContentAsHtml: () => null });
  const pprpProgramDataSheetContentRetriever = React.useRef<{ getContentAsHtml: () => string | null }>({ getContentAsHtml: () => null });

  const motionTypeQuery = useQuery<{ motionTypes: MotionType[] }>(FetchMotionTypesQuery);
  const motionTypes = motionTypeQuery.data?.motionTypes ?? [];

  const [addMutation, { loading: loadingAdd }] = useMutation<
    {
      motionParagraphs: { update: MotionParagraph };
    },
    { motion: MotionParagraphInput }
  >(AddMotionsMutation, {
    refetchQueries: [{ query: FetchMotionsQuery }, { query: FetchActiveMotionsQuery }]
  });

  const [updateMutation, { loading: loadingUpdate }] = useMutation<
    {
      motionParagraphs: { update: MotionParagraph };
    },
    { motion: MotionParagraphInput }
  >(UpdateMotionsMutation, {
    refetchQueries: [{ query: FetchActiveMotionsQuery }]
  });

  const mutate = props.motion?.id === undefined ? addMutation : updateMutation;
  const loading = loadingAdd || loadingUpdate || motionTypeQuery.loading;

  function validate(values: FormValues) {
    const errors: FormikErrors<MotionParagraphInput> = {};

    if (!values.isComply && !values.isNonComply) {
      errors.isComply = "A motion must either be Comply or Non-comply.";
      errors.isNonComply = "A motion must either be Comply or Non-comply.";
    }

    if (values.motionCode === null) {
      errors.motionCode = "A code is required.";
    } else if (values.motionCode.length > 25) {
      errors.motionCode = "A code cannot be longer than 25 characters.";
    }

    if (values.motionTypeId === null) {
      errors.motionTypeId = "A motion type must be selected.";
    }

    if (values.shortDescription === null) {
      errors.shortDescription = "A short description is required.";
    } else if (values.shortDescription.length > 255) {
      errors.motionCode = "A short description cannot be longer than 255 characters.";
    }

    if (values.sortOrder === null) {
      errors.sortOrder = "A sort order is required.";
    } else if (props.existingMotions.find((m) => m.id !== props.motion?.id && m.sortOrder === values.sortOrder && values.isActive)) {
      errors.sortOrder = "Sort order must be unique.";
    }

    if (getHtmlForField(minutesContentRetriever) === null) {
      errors.paragraphForMinutes = "A paragraph for minutes is required.";
    }

    if (getHtmlForField(letterContentRetriever) === null) {
      errors.paragraphForDecisionLetter = "A paragraph for the decision letter is required.";
    }
    let selectedMotion = motionTypes.find((f) => f.id == values.motionTypeId);
    if (selectedMotion?.typeCode == MotionTypeCode.PPRPProgram && getHtmlForField(pprpProgramDataSheetContentRetriever) === null) {
      errors.paragraphForPprpProgramReviewSummaryDataSheet =
        "A paragraph for the PPRP Program Review Summary Data Sheet is required for PPRP Program Review motions.";
    }

    return errors;
  }

  const initialValues: FormValues = {
    isActive: props.motion?.isActive ?? true,
    isComply: props.motion?.isComply ?? false,
    isNonComply: props.motion?.isNonComply ?? false,
    motionCode: props.motion?.motionCode ?? null,
    motionTypeId: props.motion?.motionType.id ?? null,
    shortDescription: props.motion?.shortDescription ?? null,
    sortOrder: props.motion?.sortOrder ?? null,
    paragraphForMinutes: props.motion?.paragraphForMinutes ?? null,
    paragraphForDecisionLetter: props.motion?.paragraphForDecisionLetter ?? null,
    paragraphForPprpProgramReviewSummaryDataSheet: props.motion?.paragraphForPprpProgramReviewSummaryDataSheet ?? null
  };

  function showComplyNonComplyError(formikProps: FormikProps<FormValues>): boolean {
    return (
      (!!formikProps.touched.isComply || !!formikProps.touched.isNonComply) &&
      (!!formikProps.errors.isComply || !!formikProps.errors.isNonComply)
    );
  }
  const submitMotion = async (values: FormValues) => {
    let motion: MotionParagraphInput = {
      id: props.motion?.id,
      isActive: values.isActive,
      isComply: values.isComply,
      isNonComply: values.isNonComply,
      motionCode: values.motionCode ?? "",
      motionTypeId: values.motionTypeId ?? 0,
      shortDescription: values.shortDescription ?? "",
      sortOrder: values.sortOrder ?? 0,
      paragraphForMinutes: getHtmlForField(minutesContentRetriever) ?? "",
      paragraphForDecisionLetter: getHtmlForField(letterContentRetriever) ?? "",
      paragraphForPprpProgramReviewSummaryDataSheet: getHtmlForField(pprpProgramDataSheetContentRetriever) ?? ""
    };
    await mutate({
      variables: { motion }
    });
    props.handleClose();
  };

  return (
    <Dialog open={true} onClose={props.handleClose} fullWidth={true} scroll="body" maxWidth="md">
      <ClosableDialogTitle onClose={props.handleClose}>{props.title}</ClosableDialogTitle>
      <Formik initialValues={initialValues} onSubmit={submitMotion} validate={validate}>
        {(formikProps) => {
          let selectedMotion = motionTypes?.find((f) => f.id == formikProps.values.motionTypeId);
          return (
            <FormikForm>
              <DialogContent>
                <Grid container direction="row" spacing={1} className={classes.inputRow}>
                  <Grid item xs={6}>
                    <FormikField component={FmuiTextField} name="motionCode" label="Code" fullWidth required />
                    <FormikField component={FmuiTextField} name="shortDescription" label="Short Description" fullWidth required />
                    <FormikField
                      component={FmuiTextField}
                      type="number"
                      className={classes.noNumberSpinners}
                      name="sortOrder"
                      label="Sort Order"
                      fullWidth
                      required
                      helperText="Sort Order can be any number, with or without a decimal."
                    />
                    <FormikField component={FmuiTextField} name="motionTypeId" label="Type" fullWidth required select>
                      {motionTypeQuery.loading ? (
                        <p>Please wait...</p>
                      ) : (
                        motionTypes.map((type) => (
                          <MenuItem key={type.id} value={type.id}>
                            {type.name}
                          </MenuItem>
                        ))
                      )}
                    </FormikField>
                  </Grid>
                  <Grid item xs={2}></Grid>
                  <Grid container item xs={4} direction="row" className={classes.checkboxes}>
                    <Grid item xs={12}>
                      <Stack>
                        <FormikField type="checkbox" component={FmuiCheckbox} name="isNonComply" Label={{ label: "Non-comply" }} />
                        <FormikField type="checkbox" component={FmuiCheckbox} name="isComply" Label={{ label: "Comply" }} />
                        {showComplyNonComplyError(formikProps) && <FormHelperText error>{formikProps.errors.isComply}</FormHelperText>}
                      </Stack>
                    </Grid>
                    {props.motion?.id && (
                      <Grid item xs={12} className={classes.activeCheckbox}>
                        <FormikField type="checkbox" component={FmuiCheckbox} name="isActive" Label={{ label: "Active" }} />
                      </Grid>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <FormikField
                      component={RichTextEditor}
                      name="paragraphForMinutes"
                      label="Paragraph for Minutes"
                      html={formikProps.values.paragraphForMinutes}
                      passContentRetriever={(getContentAsHtml: any) => {
                        minutesContentRetriever.current = { getContentAsHtml };
                      }}
                      showMergeFields
                      mergeFieldType={
                        selectedMotion?.typeCode == MotionTypeCode.PPRPProgram
                          ? MergeFieldType.RegComMeetingItem
                          : MergeFieldType.MeetingMinutes
                      }
                      required
                      templateMarkup
                    />
                    {formikProps.errors.paragraphForMinutes && formikProps.touched.paragraphForMinutes && (
                      <FormHelperText error>{formikProps.errors.paragraphForMinutes}</FormHelperText>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <FormikField
                      component={RichTextEditor}
                      name="paragraphForDecisionLetter"
                      label="Paragraph for Decision Letter"
                      html={formikProps.values.paragraphForDecisionLetter}
                      passContentRetriever={(getContentAsHtml: any) => {
                        letterContentRetriever.current = { getContentAsHtml };
                      }}
                      showMergeFields
                      mergeFieldType={MergeFieldType.DecisionLetter}
                      required
                      templateMarkup
                    />
                    {formikProps.errors.paragraphForDecisionLetter && formikProps.touched.paragraphForDecisionLetter && (
                      <FormHelperText error>{formikProps.errors.paragraphForDecisionLetter}</FormHelperText>
                    )}
                  </Grid>
                  {selectedMotion?.typeCode == MotionTypeCode.PPRPProgram && (
                    <Grid item xs={12}>
                      <FormikField
                        component={RichTextEditor}
                        name="paragraphForPprpProgramReviewSummaryDataSheet"
                        label="Paragraph for PPRP Program Review Summary Data Sheet (Presentation Format)"
                        html={formikProps.values.paragraphForPprpProgramReviewSummaryDataSheet}
                        passContentRetriever={(getContentAsHtml: any) => {
                          pprpProgramDataSheetContentRetriever.current = { getContentAsHtml };
                        }}
                        showMergeFields
                        mergeFieldType={MergeFieldType.ProgramReview}
                        required
                        templateMarkup
                      />
                      {formikProps.errors.paragraphForPprpProgramReviewSummaryDataSheet &&
                        formikProps.touched.paragraphForPprpProgramReviewSummaryDataSheet && (
                          <FormHelperText error>{formikProps.errors.paragraphForPprpProgramReviewSummaryDataSheet}</FormHelperText>
                        )}
                    </Grid>
                  )}
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={props.handleClose}>Cancel</Button>
                <LoadingButton color="primary" variant="contained" loading={loading} onClick={() => formikProps.submitForm()}>
                  {props.confirmButtonText}
                </LoadingButton>
              </DialogActions>
            </FormikForm>
          );
        }}
      </Formik>
    </Dialog>
  );
};

export default EditMotionDialog;
