import { useQuery } from "@apollo/client";

import React from "react";
import { ScreenHeader } from "../common/ScreenHeader";
import CrudTable from "../common/CrudTable";
import { DecisionAdditionalParagraph } from "decisions";
import { FetchDecisionAdditionalParagraphsQuery } from "./queries";
import { Helmet } from "react-helmet";
import _ from "lodash";
import EditDecisionAdditionalParagraphDialog from "./EditDecisionAdditionalParagraphDialog";
import DeleteDecisionAdditionalParagraphDialog from "./DeleteDecisionAdditionalParagraphDialog";

interface ParagraphsProps {
  forRegistrationCommitteeItems?: boolean;
}

const DecisionAdditionalParagraphsScreen: React.FunctionComponent<ParagraphsProps> = (props) => {
  const paragraphQuery = useQuery<
    { decisionAdditionalParagraphs: DecisionAdditionalParagraph[] },
    { forRegistrationCommitteeItems: boolean }
  >(FetchDecisionAdditionalParagraphsQuery, {
    variables: { forRegistrationCommitteeItems: props.forRegistrationCommitteeItems ?? false }
  });

  const paragraphs = paragraphQuery.data?.decisionAdditionalParagraphs ?? [];

  return (
    <>
      <Helmet>
        <title>Additional Paragraphs for Decisions - PRS Online</title>
      </Helmet>
      <ScreenHeader
        title={
          props.forRegistrationCommitteeItems
            ? "Paragraphs for Registration Committee Item Decisions"
            : "Additional Paragraphs for Review Decisions"
        }
      />
      <CrudTable
        columnDefinitions={[
          {
            field: "name",
            headerName: "Name",
            flex: 1
          },
          {
            field: "isActive",
            headerName: "Active",
            type: "boolean"
          }
        ]}
        rows={paragraphs}
        loading={paragraphQuery.loading}
        noDataMessage="No additional paragraphs found."
        renderAddDialog={(dialogProps) => (
          <EditDecisionAdditionalParagraphDialog
            title="Add Paragraph"
            confirmButtonText="Add"
            handleClose={dialogProps.onClose}
            forRegistrationCommitteeItems={props.forRegistrationCommitteeItems}
          />
        )}
        renderEditDialog={(id, dialogProps) => (
          <EditDecisionAdditionalParagraphDialog
            title="Edit Paragraph"
            confirmButtonText="Save"
            handleClose={dialogProps.onClose}
            forRegistrationCommitteeItems={props.forRegistrationCommitteeItems}
            paragraph={paragraphs.find((ac) => ac.id === id)}
          />
        )}
        renderDeleteDialog={(id, dialogProps) => (
          <DeleteDecisionAdditionalParagraphDialog
            paragraph={paragraphs.find((ac) => ac.id === id)!}
            forRegistrationCommitteeItems={props.forRegistrationCommitteeItems}
            handleClose={dialogProps.onClose}
          />
        )}
      />
    </>
  );
};

export default DecisionAdditionalParagraphsScreen;
