import React from "react";
import { Button, Dialog, DialogActions, DialogContent, Grid } from "@mui/material";
import ClosableDialogTitle from "common/ClosableDialogTitle";
import { Field as FormikField, Form as FormikForm, Formik } from "formik";
import { TextField as FmuiTextField, CheckboxWithLabel as FmuiCheckboxWithLabel } from "formik-mui";
import { DateTime } from "luxon";
import { LoadingButton } from "@mui/lab";
import {
  AddCommitteeMemberMutation,
  CommitteeMember,
  CommitteeMemberInput,
  EditCommitteeMemberMutation,
  FetchCommitteeMembersQuery
} from ".";
import * as Yup from "yup";
import PrsDatePickerField from "common/FormikFields/PrsDatePickerField";
import { useMutation } from "@apollo/client";

interface Props {
  handleClose: () => void;
  title: string;
  confirmButtonText: string;
  member?: CommitteeMember;
  registrationCommittee?: boolean;
}

interface FormValues {
  firstName: string;
  lastName: string;
  designation: string;
  startDate?: DateTime;
  endDate?: DateTime;
  isChair: boolean;
  isRegistrar?: boolean;
  isActive: boolean;
}

const EditMemberDialog = (props: Props) => {
  const [addMemberMutate, addMemberMutation] = useMutation<
    { committeeMembers: { add: CommitteeMember } },
    { member: CommitteeMemberInput }
  >(AddCommitteeMemberMutation, { refetchQueries: [FetchCommitteeMembersQuery] });
  const [editMemberMutate, editMemberMutation] = useMutation<
    { committeeMembers: { edit: CommitteeMember } },
    { member: CommitteeMemberInput }
  >(EditCommitteeMemberMutation, { refetchQueries: [FetchCommitteeMembersQuery] });

  const editing = props.member !== undefined;

  const initialValues: FormValues = {
    firstName: props.member?.firstName ?? "",
    lastName: props.member?.lastName ?? "",
    designation: props.member?.designation ?? "",
    startDate: DateTime.fromISO(props.member?.startDate ?? ""),
    endDate: DateTime.fromISO(props.member?.endDate ?? ""),
    isChair: props.member?.isChair ?? false,
    isRegistrar: props.member?.isRegistrar ?? false,
    isActive: props.member?.isActive ?? true
  };

  const validationSchema = Yup.object({
    firstName: Yup.string().required("Enter the member's first name."),
    lastName: Yup.string().required("Enter the member's last name.")
  });

  return (
    <Dialog open={true} fullWidth={true} scroll="paper" maxWidth="sm">
      <ClosableDialogTitle onClose={props.handleClose}>{props.title}</ClosableDialogTitle>
      <Formik
        initialValues={initialValues}
        onSubmit={async (values, actions) => {
          if (editing) {
            editMemberMutate({
              variables: {
                member: {
                  ...values,
                  id: props.member?.id ?? 0,
                  startDate: values.startDate?.toISODate() ?? null,
                  endDate: values.endDate?.toISODate() ?? null
                }
              }
            });
          } else {
            addMemberMutate({
              variables: {
                member: {
                  ...values,
                  id: props.member?.id ?? 0,
                  startDate: values.startDate?.toISODate() ?? null,
                  endDate: values.endDate?.toISODate() ?? null,
                  isRegistrationCommittee: props.registrationCommittee ?? false
                }
              }
            });
          }
          props.handleClose();
        }}
        validationSchema={validationSchema}>
        {(formikProps) => {
          return (
            <FormikForm>
              <DialogContent>
                <Grid direction="column" item container rowSpacing={1}>
                  <Grid item>
                    <FormikField name={`firstName`} label="First Name" required component={FmuiTextField} fullWidth />
                  </Grid>
                  <Grid item>
                    <FormikField name={`lastName`} label="Last Name" required component={FmuiTextField} fullWidth />
                  </Grid>
                  <Grid item>
                    <FormikField name={`designation`} label="Designation" component={FmuiTextField} fullWidth />
                  </Grid>
                  <Grid item>
                    <FormikField
                      name={`isChair`}
                      type="checkbox"
                      component={FmuiCheckboxWithLabel}
                      fullWidth
                      Label={{ label: "Committee Chair" }}
                    />
                  </Grid>
                  {props.registrationCommittee && (
                    <Grid item>
                      <FormikField
                        name={`isRegistrar`}
                        type="checkbox"
                        component={FmuiCheckboxWithLabel}
                        fullWidth
                        Label={{ label: "Registrar" }}
                      />
                    </Grid>
                  )}
                  <Grid item>
                    <FormikField
                      name={`startDate`}
                      label="Start Date"
                      component={PrsDatePickerField}
                      fullWidth
                      maxDate={formikProps.values.endDate}
                    />
                  </Grid>
                  <Grid item>
                    <FormikField
                      name={`endDate`}
                      label="End Date"
                      component={PrsDatePickerField}
                      fullWidth
                      minDate={formikProps.values.startDate}
                    />
                  </Grid>
                  <Grid item>
                    <FormikField
                      name={`isActive`}
                      type="checkbox"
                      component={FmuiCheckboxWithLabel}
                      fullWidth
                      Label={{ label: "Active" }}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => props.handleClose()}>Cancel</Button>
                <LoadingButton
                  loading={editMemberMutation.loading || addMemberMutation.loading}
                  color="primary"
                  variant="contained"
                  onClick={() => formikProps.submitForm()}>
                  {props.confirmButtonText}
                </LoadingButton>
              </DialogActions>
            </FormikForm>
          );
        }}
      </Formik>
    </Dialog>
  );
};

export default EditMemberDialog;
