import React from "react";
import { AttachedDocument, PracticeReview } from "practice-reviews";
import { DocType } from "common/DocType";
import { Grid, Link, Typography, Button, DialogContentText } from "@mui/material";

import _ from "lodash";
import { ConfirmationDialog } from "common/ConfirmationDialog";
import { LoadingButton } from "@mui/lab";
import { makeStyles } from "../makeStyles";
import { PrReportLinkAndActions } from "./report-links/PrReportLinkAndActions";
import { documentListStyles } from "../styles/common";
import { DeficiencyReportLinkAndActions } from "./report-links/DeficiencyReportLinkAndActions";
import { useCurrentUser, Permissions } from "../users";
import { ChecklistNotesReportLinkAndActions } from "./report-links/ChecklistNotesReportLinkAndActions";
import { MicrosoftWordLink } from "../common/MicrosoftWordLink";
import { getOpenableUrl } from "../util/utilities";
import { useAttachedDocumentActions } from "./AttachedDocumentActions";

interface Props {
  practiceReview: PracticeReview;
}

export const ReportsAndQuestionnaires: React.FunctionComponent<Props> = (props) => {
  const useStyles = makeStyles()((theme) => ({
    ...documentListStyles(theme),
    tabContent: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      "& > :first-child": {
        flexGrow: 1
      },
      "& > :last-child": {
        alignSelf: "flex-start"
      }
    },
    additionalFilesHeader: {
      display: "flex",
      "& > :not(:last-child)": {
        marginRight: theme.spacing(3)
      }
    }
  }));

  const { classes } = useStyles();
  const {
    attachingDocumentType,
    documentsAttached,
    setRemovingDocument,
    removeAttachedDocumentMutation,
    removingDocument,
    removeAttachedDocument
  } = useAttachedDocumentActions();

  const { practiceReview } = props;

  const errorMessage = `This Additional Files section is intended only for attaching *miscellaneous* files.

  The file you have selected cannot be attached using this function, because its filename contains the text {match}, which is too similar to the reserved name of a document that has a defined purpose in this system.
  This is not allowed because that file could be confused with, or even overwrite or be overwritten by, the other file.

  The most common reason to see this message is that there may be a different area of the screen dedicated to uploading the specific type of document, which must be used instead so that the file can play its intended role.

  Please look over the rest of this screen for a spot dedicated to a {match} file.

  If you cannot find such a part of the screen, and/or are certain this file should be attached as an uncategorized miscellaneous file instead, please rename the file to not include the text {match} in its filename, then try again.`;

  const FormLinkAndActions: React.FunctionComponent<{
    name: string;
    required: boolean;
    showIfExistsAnyway?: boolean;
    isMissing: boolean;
    showActions: boolean;
    url: string | null;
    documentType: DocType;
  }> = (componentProps) => {
    const { name, required, showIfExistsAnyway, isMissing, url, documentType, showActions } = componentProps;

    const formDocument = practiceReview.attachedDocuments.find((ad) => ad.type === documentType);

    return required || (showIfExistsAnyway && url) ? (
      <div className={classes.documentRow}>
        <div className={classes.documentRow}>
          {isMissing ? (
            <Typography variant="body1" className={classes.missingForm}>
              {name}
            </Typography>
          ) : (
            <Link href={getOpenableUrl(url!)} target="_blank">
              <Typography variant="body1">{name}</Typography>
            </Link>
          )}
        </div>
        {showActions && (
          <div className={classes.documentAction}>
            {!url ? (
              <LoadingButton
                variant="outlined"
                size="small"
                component="label"
                loading={attachingDocumentType === documentType}
                disabled={attachingDocumentType !== null}>
                Attach
                <input
                  type="file"
                  hidden
                  accept="*/*"
                  onChange={(e) => documentsAttached(e.target.files!, documentType, practiceReview, errorMessage)}
                />
              </LoadingButton>
            ) : (
              <Button
                variant="outlined"
                size="small"
                color="error"
                onClick={() => setRemovingDocument(formDocument!)}
                disabled={attachingDocumentType !== null || removeAttachedDocumentMutation.loading}
                className="removeButton">
                Remove
              </Button>
            )}
          </div>
        )}
      </div>
    ) : null;
  };

  const DocumentLinkAndActions: React.FunctionComponent<{ document: AttachedDocument; showActions: boolean }> = (componentProps) => {
    const { document, showActions } = componentProps;
    return (
      <div className={classes.documentRow}>
        <div className={classes.documentName}>
          <Link href={getOpenableUrl(document.url)} target="_blank">
            <Typography variant="body1">{document.fileName}</Typography>
          </Link>
        </div>
        <div className={classes.documentAction}>
          {showActions && (
            <Button
              variant="outlined"
              size="small"
              color="error"
              onClick={() => setRemovingDocument(document)}
              disabled={attachingDocumentType !== null || removeAttachedDocumentMutation.loading}
              className="removeButton">
              Remove
            </Button>
          )}
        </div>
      </div>
    );
  };

  const additionalFiles = _.orderBy(
    practiceReview.attachedDocuments.filter((ad) => ad.type === "AdditionalFile"),
    (ad) => ad.id
  );

  const { userHasPermission } = useCurrentUser();

  const userCanUpdatePr = !practiceReview.hasBeenReturned || userHasPermission(Permissions.ReturnedPrUpdate);
  const userCanUpdateFiles = userHasPermission(Permissions.PrUpdateFiles) || userCanUpdatePr;

  const signedDeficiencyReportUrl = practiceReview.attachedDocuments.filter((ad) => ad.type === DocType.SignedDeficiencyReport)[0]?.url;
  const signedDraftPrReportUrl = practiceReview.attachedDocuments.filter((ad) => ad.type === DocType.SignedDraftPracticeReviewReport)[0]
    ?.url;
  const decisionLetterDocType = practiceReview.isPprpProgramReview
    ? DocType.PprpProgramReviewDecisionLetter
    : practiceReview.mostAuthoritativeDecision?.isComply ?? true
    ? DocType.DecisionComplyLetter
    : DocType.DecisionNonComplyLetter;
  const decisionLetterDocument = practiceReview.attachedDocuments.filter((ad) => ad.type === decisionLetterDocType)[0];
  const reviewerEvaluationForm = practiceReview.attachedDocuments.filter((ad) => ad.type === DocType.ReviewerEvaluationForm)[0];

  return (
    <div className={classes.tabContent}>
      <Grid container spacing={5}>
        <Grid item xs={12} md={4}>
          <Typography variant="h3" gutterBottom>
            Reports
          </Typography>
          <div className={classes.documentList}>
            <div className={classes.documentRow}>
              <DeficiencyReportLinkAndActions practiceReview={practiceReview} useMissingStyling userCanPostToPortal={false} />
            </div>
            <div className={classes.documentRow}>
              <ChecklistNotesReportLinkAndActions practiceReview={practiceReview} />
            </div>
            <div className={classes.documentRow}>
              <PrReportLinkAndActions practiceReview={practiceReview} useMissingStyling />
            </div>
            <div className={classes.documentRow}>
              <div className={classes.documentName}>
                {!practiceReview.decisionLetterUrl ? (
                  <Typography variant="body1" className={classes.missingForm}>
                    Decision Letter
                  </Typography>
                ) : (
                  <Link href={getOpenableUrl(practiceReview.decisionLetterUrl)} target="_blank">
                    <Typography variant="body1">Decision Letter</Typography>
                  </Link>
                )}
              </div>
              {practiceReview.isPprpProgramReview && (
                <div className={classes.documentAction}>
                  {!practiceReview.decisionLetterUrl ? (
                    <LoadingButton
                      variant="outlined"
                      size="small"
                      component="label"
                      loading={attachingDocumentType === decisionLetterDocType}
                      disabled={attachingDocumentType !== null}>
                      Attach
                      <input
                        type="file"
                        hidden
                        accept=".docx,.pdf"
                        onChange={(e) => documentsAttached(e.target.files!, decisionLetterDocType, practiceReview, errorMessage)}
                      />
                    </LoadingButton>
                  ) : (
                    <Button
                      variant="outlined"
                      size="small"
                      color="error"
                      onClick={() => setRemovingDocument(decisionLetterDocument!)}
                      disabled={attachingDocumentType !== null || removeAttachedDocumentMutation.loading}
                      className="removeButton">
                      Remove
                    </Button>
                  )}
                </div>
              )}
            </div>
            <div className={classes.documentRow}>
              <div className={classes.documentName}>
                {practiceReview.finalReportPackageUrl ? (
                  <Link href={getOpenableUrl(practiceReview.finalReportPackageUrl)} target="_blank">
                    <Typography variant="body1">Final Report Package</Typography>
                  </Link>
                ) : (
                  <Typography variant="body1" className={classes.missingForm}>
                    Final Report Package
                  </Typography>
                )}
              </div>
            </div>
            <div className={classes.documentRow}>
              <div className={classes.documentName}>
                {practiceReview.pdDeclarationFormUrl ? (
                  <MicrosoftWordLink href={practiceReview.pdDeclarationFormUrl}>
                    Professional Development Declaration Form
                  </MicrosoftWordLink>
                ) : (
                  <Typography variant="body1" className={classes.missingForm}>
                    Professional Development Declaration Form
                  </Typography>
                )}
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography variant="h3">Questionnaires and Forms</Typography>
          <div className={classes.documentList}>
            <FormLinkAndActions
              name="Scheduling Questionnaire"
              required={practiceReview.requiresSchedulingQuestionnaire}
              isMissing={practiceReview.isMissingSchedulingQuestionnaire}
              url={practiceReview.schedulingQuestionnaireUrl}
              documentType={DocType.SchedulingQuestionnaire}
              showActions={userCanUpdateFiles}
            />
            <FormLinkAndActions
              name="Tax Questionnaire"
              required={practiceReview.requiresTaxQuestionnaire}
              isMissing={practiceReview.isMissingTaxQuestionnaire}
              url={practiceReview.taxQuestionnaireUrl}
              documentType={DocType.TaxQuestionnaire}
              showActions={userCanUpdateFiles}
            />
            <FormLinkAndActions
              name="Confidential Client List"
              required={practiceReview.requiresConfidentialClientList}
              showIfExistsAnyway
              isMissing={practiceReview.isMissingConfidentialClientList}
              url={practiceReview.confidentialClientListUrl}
              documentType={DocType.ConfidentialClientList}
              showActions={userCanUpdateFiles}
            />
            <FormLinkAndActions
              name="Self-evaluation Questionnaire"
              required={practiceReview.requiresSelfEvaluationQuestionnaire}
              isMissing={practiceReview.isMissingSelfEvaluationQuestionnaire}
              url={practiceReview.selfEvaluationQuestionnaireUrl}
              documentType={DocType.SelfEvaluationQuestionnaire}
              showActions={userCanUpdateFiles}
            />
            <FormLinkAndActions
              name="Deficiency Report (Signed)"
              required={true}
              isMissing={!Boolean(signedDeficiencyReportUrl)}
              url={signedDeficiencyReportUrl}
              documentType={DocType.SignedDeficiencyReport}
              showActions={userCanUpdateFiles}
            />
            <FormLinkAndActions
              name="Draft Practice Review Report (Signed)"
              required={true}
              isMissing={!Boolean(signedDraftPrReportUrl)}
              url={signedDraftPrReportUrl}
              documentType={DocType.SignedDraftPracticeReviewReport}
              showActions={userCanUpdateFiles}
            />
            <div className={classes.documentRow}>
              <div className={classes.documentName}>
                {reviewerEvaluationForm ? (
                  <Link href={getOpenableUrl(reviewerEvaluationForm.url)} target="_blank">
                    <Typography variant="body1">Reviewer Evaluation Form</Typography>
                  </Link>
                ) : (
                  <Typography variant="body1" className={classes.missingForm}>
                    Reviewer Evaluation Form
                  </Typography>
                )}
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={4}>
          <div className={classes.additionalFilesHeader}>
            <Typography variant="h3">Additional Files</Typography>
            {userCanUpdateFiles && (
              <LoadingButton
                variant="outlined"
                size="small"
                component="label"
                loading={attachingDocumentType === "AdditionalFile"}
                disabled={attachingDocumentType !== null}>
                Attach
                <input
                  type="file"
                  hidden
                  accept="*/*"
                  multiple
                  onChange={(e) => documentsAttached(e.target.files!, DocType.AdditionalFile, practiceReview, errorMessage)}
                />
              </LoadingButton>
            )}
          </div>
          <div className={classes.documentList}>
            {additionalFiles.map((d) => (
              <DocumentLinkAndActions key={d.id} document={d} showActions={userCanUpdateFiles} />
            ))}
          </div>
        </Grid>
      </Grid>
      <Link href={practiceReview.sharePointUrl} target="_blank">
        <Typography variant="h3">View all documents in SharePoint</Typography>
      </Link>

      {removingDocument !== null && (
        <ConfirmationDialog
          open={true}
          body={
            <DialogContentText>
              {`Do you want to remove the ${
                removingDocument?.type === "AdditionalFile" ? `file "${removingDocument?.fileName}"` : removingDocument?.typeFriendlyName
              }?`}
            </DialogContentText>
          }
          title="Remove file?"
          cancel={() => setRemovingDocument(null)}
          confirm={() => removeAttachedDocument(removingDocument, practiceReview.id)}
          loading={removeAttachedDocumentMutation.loading}
        />
      )}
    </div>
  );
};
