import { Alert, Box, Button, Dialog, DialogActions, DialogContent, FormControlLabel, MenuItem, Stack } from "@mui/material";
import ClosableDialogTitle from "common/ClosableDialogTitle";
import { PracticeReview } from "practice-reviews";
import React from "react";
import { CommitteeMeeting } from "./models";
import { Formik, Field as FormikField, FormikHelpers } from "formik";
import { TextField as FmuiTextField } from "formik-mui";
import { useMutation, useQuery } from "@apollo/client";
import { useNotifications } from "notifications";
import * as Yup from "yup";
import { LoadingButton } from "@mui/lab";
import { makeStyles } from "../makeStyles";
import {
  AvailableMeetingsQuery,
  ChangePrCommitteeMeetingMutation,
  FetchCommitteeMeetingsQuery,
  FetchUnassignedRegistrationCommitteeItemsQuery,
  FetchUnassignedPrsQuery
} from ".";
import _ from "lodash";
import PrList from "../inas/PrList";
import { Checkbox as FmuiCheckbox } from "formik-mui";
import { meetingStyles } from "styles/common";
import { RegistrationCommitteeItem } from "registration-committee-items";
import { AssignRegistrationCommitteeItemsToMeetingMutation } from "registration-committee-items/queries";
import RegistrationCommitteeItemList from "inas/RegistrationCommitteeItemList";

const useStyles = makeStyles()((theme) => ({
  ...meetingStyles(theme)
}));

interface Props {
  meetingType: "prc" | "registration-committee";
  registrationCommitteeItems?: RegistrationCommitteeItem[];
  practiceReviews?: PracticeReview[];
  onClose: () => void;
}

export const ChangeMeetingDialog: React.FunctionComponent<Props> = (props) => {
  const { classes, cx } = useStyles();
  const notifications = useNotifications();

  interface FormValues {
    committeeMeetingId: number | null;
    notifyFirm: boolean;
  }

  const initialFormValues: FormValues = {
    committeeMeetingId:
      props.registrationCommitteeItems?.[0]?.committeeMeeting?.id ?? props.practiceReviews?.[0]?.committeeMeeting?.id ?? null,
    notifyFirm: false
  };

  const availableMeetingsQuery = useQuery<{ availableMeetings: CommitteeMeeting[] }>(AvailableMeetingsQuery);
  const availableMeetings = availableMeetingsQuery.data?.availableMeetings ?? [];

  const ids = props.registrationCommitteeItems?.map((item) => item.id) ?? props.practiceReviews?.map((pr) => pr.id) ?? [];

  const meetingsEligibleForPrType = availableMeetings.filter((m) =>
    props.meetingType === "registration-committee" ? m.isRegistrationCommittee : !m.isRegistrationCommittee
  );

  const [changePrsMeetingMutate, changePrsMeetingMutation] = useMutation<
    { practiceReview: { changeMeeting: PracticeReview[] } },
    { practiceReviewIds: number[]; newCommitteeMeetingId: number; notifyFirm: boolean }
  >(ChangePrCommitteeMeetingMutation, {
    refetchQueries: [
      { query: AvailableMeetingsQuery },
      {
        query: FetchCommitteeMeetingsQuery,
        variables: { registrationCommittee: false }
      },
      { query: FetchUnassignedPrsQuery, variables: { registrationCommittee: props.meetingType === "registration-committee" } }
    ]
  });
  const [changeItemsMeetingMutate, changeItemsMeetingMutation] = useMutation<
    { registrationCommitteeItem: { assignToCommitteeMeeting: RegistrationCommitteeItem[] } },
    { itemIds: number[]; committeeMeetingId: number }
  >(AssignRegistrationCommitteeItemsToMeetingMutation, {
    refetchQueries: [
      { query: AvailableMeetingsQuery },
      { query: FetchCommitteeMeetingsQuery, variables: { registrationCommittee: true } },
      { query: FetchUnassignedRegistrationCommitteeItemsQuery }
    ]
  });

  const mutate = async (ids: number[], meetingId: number, notifyFirm?: boolean) =>
    props.registrationCommitteeItems
      ? await changeItemsMeetingMutate({
          variables: {
            itemIds: ids,
            committeeMeetingId: meetingId
          }
        })
      : await changePrsMeetingMutate({
          variables: {
            practiceReviewIds: ids,
            newCommitteeMeetingId: meetingId,
            notifyFirm: !!notifyFirm
          }
        });
  const result = props.registrationCommitteeItems
    ? changeItemsMeetingMutation.data?.registrationCommitteeItem.assignToCommitteeMeeting
    : changePrsMeetingMutation.data?.practiceReview.changeMeeting;

  async function changeMeeting(values: FormValues, actions: FormikHelpers<FormValues>) {
    await mutate(ids, values.committeeMeetingId!, values.notifyFirm);

    if ((result?.length ?? 0) > 0) {
      notifications.success(`Assigned PR${(result?.length ?? 0) > 0 ? "s" : ""} to meeting.`);
    }

    actions.setSubmitting(false);
    props.onClose();
  }

  const validationSchema = Yup.object({
    committeeMeetingId: Yup.number()
      .nullable()
      .required(props.meetingType === "registration-committee" ? "Select a Registration Committee meeting." : "Select a PRC meeting.")
  });

  return (
    <Dialog open={true} onClose={props.onClose} fullWidth maxWidth="sm">
      <Formik initialValues={initialFormValues} validationSchema={validationSchema} onSubmit={changeMeeting}>
        {(formikProps) => {
          const selectedMeeting = availableMeetings.find((m) => m.id === formikProps.values.committeeMeetingId);

          return (
            <>
              <ClosableDialogTitle onClose={props.onClose}>
                {formikProps.initialValues.committeeMeetingId ? "Change Committee Meeting" : "Assign to Committee Meeting"}
              </ClosableDialogTitle>
              <DialogContent>
                <Stack spacing={2}>
                  {meetingsEligibleForPrType.length === 0 && (
                    <Alert color="warning">{`There are no available meetings that ${
                      ids.length > 1
                        ? `these ${props.registrationCommitteeItems ? "items" : "reviews"}`
                        : `this ${props.registrationCommitteeItems ? "item" : "review"}`
                    } can be assigned to.`}</Alert>
                  )}

                  {props.registrationCommitteeItems && <RegistrationCommitteeItemList items={props.registrationCommitteeItems} />}
                  {props.practiceReviews && <PrList prs={props.practiceReviews} />}

                  <FormikField
                    component={FmuiTextField}
                    select
                    name="committeeMeetingId"
                    label="Committee Meeting"
                    fullWidth
                    disabled={formikProps.isSubmitting}
                    error={formikProps.touched.committeeMeetingId && Boolean(formikProps.errors.committeeMeetingId)}
                    helperText={formikProps.touched.committeeMeetingId && formikProps.errors.committeeMeetingId}>
                    {_.orderBy(meetingsEligibleForPrType, (m) => m.meetingDate).map((meeting) => (
                      <MenuItem key={meeting.id} value={meeting.id}>
                        <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                          {meeting.name}
                          <span
                            className={cx(classes.meetingCapacity, {
                              [classes.meetingOverCapacity]: meeting.assignedReviews.length >= meeting.reviewQuota
                            })}>
                            {props.registrationCommitteeItems ? "" : `${meeting.assignedReviews.length}/${meeting.reviewQuota}`}
                          </span>
                        </Box>
                      </MenuItem>
                    ))}
                  </FormikField>

                  {selectedMeeting &&
                    selectedMeeting.id !== formikProps.initialValues.committeeMeetingId &&
                    selectedMeeting.assignedReviews.length >= selectedMeeting.reviewQuota && (
                      <Alert color="warning">This meeting is full.</Alert>
                    )}

                  {props.practiceReviews && (
                    <FormControlLabel
                      control={<FormikField component={FmuiCheckbox} type="checkbox" name="notifyFirm" />}
                      label={`Notify firm${props.practiceReviews.length === 1 ? "" : "s"} of meeting change`}
                    />
                  )}
                </Stack>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => props.onClose()} disabled={changePrsMeetingMutation.loading}>
                  Cancel
                </Button>
                <LoadingButton
                  color="primary"
                  variant="contained"
                  onClick={() => formikProps.submitForm()}
                  disabled={!formikProps.isValid}
                  loading={changePrsMeetingMutation.loading}>
                  OK
                </LoadingButton>
              </DialogActions>
            </>
          );
        }}
      </Formik>
    </Dialog>
  );
};
