import { RegistrationCommitteeItem } from ".";
import React, { useMemo, useState } from "react";
import { CommitteeMeeting } from "committee-meetings";
import { useCollapsibleGrids } from "util/CollapsibleProvider";
import { Box, Button, Collapse, Grid, IconButton, Link, Paper, Stack, Tooltip, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faEdit } from "@fortawesome/free-solid-svg-icons";
import { DataGridWithHeader } from "common/DataGridWithHeader";
import { dataGridDateValueFormatter } from "util/formats";
import { makeStyles } from "makeStyles";
import { datagridStyles, expanderStyles } from "styles/common";
import DOMPurify from "dompurify";
import _ from "lodash";
import { GridSelectionModel } from "@mui/x-data-grid-pro";
import { YesNoIcon } from "common/YesNoIcon";
import { getRouteForRegistrationCommitteeItem, RegistrationCommitteeItemTabs } from "./RegistrationCommitteeItemScreen";

const useStyles = makeStyles()((theme) => ({
  ...datagridStyles(theme),
  ...expanderStyles(theme)
}));

interface Props {
  items: RegistrationCommitteeItem[];
  meeting: CommitteeMeeting;
  selectingItems: boolean;
  setSelectingItems: (newValue: boolean) => void;
  setReassingingIds: (ids: GridSelectionModel) => void;
}

const RegistrationCommitteeItemsTable: React.FunctionComponent<Props> = (props) => {
  const { items, meeting, selectingItems, setSelectingItems, setReassingingIds } = props;
  const { cx, classes } = useStyles();
  const { states, setExpanded } = useCollapsibleGrids();

  const [selectionModelDict, setSelectionModelDict] = useState<{ [key: string]: GridSelectionModel }>({});

  const groupedRegistrationCommitteeItems = useMemo(
    () =>
      _.groupBy(
        _.groupBy(
          _.sortBy(items, (item) => item.registeredName),
          (item) => item.itemType.id
        ),
        (group) => group[0].itemType.category.id
      ),
    [items]
  );

  const storageKey = `CommitteeMeeting:${meeting.id} RegistrationCommitteeItems`;
  const expanded = states[storageKey] ?? true;

  return (
    <Grid item xs={12}>
      <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ pb: 1 }}>
        <Stack direction="row" spacing={1}>
          <IconButton size="small" onClick={() => setExpanded(storageKey, !expanded)}>
            <FontAwesomeIcon icon={faChevronDown} className={cx(classes.expandedIcon, { rotated: expanded })} />
          </IconButton>
          <Typography variant="h2">Committee Items</Typography>
        </Stack>
        <Stack direction="row" spacing={3}>
          {items.length === 0 ? null : selectingItems ? (
            <>
              <Button
                onClick={() => {
                  setSelectionModelDict({});
                  setSelectingItems(false);
                }}
                size="small"
                variant="outlined">
                Cancel
              </Button>
              <Button
                onClick={() => {
                  setReassingingIds(Object.values(selectionModelDict).flat());
                  setSelectionModelDict({});
                  setSelectingItems(false);
                }}
                size="small"
                variant="contained"
                color="primary"
                disabled={Object.values(selectionModelDict).flat().length === 0}>
                Reassign
              </Button>
            </>
          ) : (
            <>
              <Button
                onClick={() => {
                  setSelectingItems(true);
                  setExpanded(storageKey, true);
                }}
                size="small"
                variant="outlined">
                Select for Reassignment
              </Button>
            </>
          )}
        </Stack>
      </Stack>
      <Collapse in={expanded}>
        <Paper sx={{ p: 2 }}>
          <Stack spacing={5}>
            {items.length === 0 ? (
              <Typography>No items assigned.</Typography>
            ) : (
              Object.keys(groupedRegistrationCommitteeItems).map((key) => {
                const group = groupedRegistrationCommitteeItems[key];
                const totalItems = group.reduce((sum, items) => sum + items.length, 0);
                const category = group[0][0].itemType.category.name;
                const categoryStorageKey = `${storageKey} ${category}`;
                const categoryExpanded = states[categoryStorageKey] ?? true;
                return (
                  <div>
                    <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
                      <IconButton size="small" onClick={() => setExpanded(categoryStorageKey, !categoryExpanded)}>
                        <FontAwesomeIcon icon={faChevronDown} className={cx(classes.expandedIcon, { rotated: categoryExpanded })} />
                      </IconButton>
                      <Typography variant="h3" color="primary">
                        {category}
                      </Typography>
                      <Typography color="primary">
                        {totalItems} Item{totalItems !== 1 ? "s" : ""}
                      </Typography>
                    </Stack>
                    <Collapse in={categoryExpanded}>
                      <Stack spacing={2}>
                        {group.map((items) => (
                          <Box sx={{ pl: 3, pr: 3 }}>
                            <DataGridWithHeader
                              title={<Typography variant="h4">{items[0].itemType.name}</Typography>}
                              headerAlign="center"
                              displayWithoutContainer
                              deemphasizeHeader
                              collapsible
                              checkboxSelection={selectingItems}
                              selectionModel={selectionModelDict[`${category} - ${items[0].itemType.name}`] ?? []}
                              onSelectionModelChange={(newSelectionModel) =>
                                setSelectionModelDict((dict) => ({
                                  ...dict,
                                  [`${category} - ${items[0].itemType.name}`]: newSelectionModel
                                }))
                              }
                              storageKey={`CommitteeMeeting:${meeting.id} RegistrationCommitteeItems ${category} - ${items[0].itemType.name}`}
                              rows={items}
                              columns={[
                                {
                                  field: "createdDate",
                                  headerName: "Date",
                                  headerClassName: classes.wrapHeader,
                                  type: "date",
                                  width: 100,
                                  valueGetter: (params) => (params.row as RegistrationCommitteeItem).createdDate,
                                  valueFormatter: dataGridDateValueFormatter
                                },
                                {
                                  field: "registeredName",
                                  headerName: "Registered Name",
                                  flex: 1,
                                  renderCell: (params) => (
                                    <Tooltip title={(params.row as RegistrationCommitteeItem).registeredName}>
                                      <Link
                                        to={getRouteForRegistrationCommitteeItem(
                                          params.row as RegistrationCommitteeItem,
                                          RegistrationCommitteeItemTabs.Overview
                                        )}
                                        component={RouterLink}>
                                        {(params.row as RegistrationCommitteeItem).registeredName}
                                      </Link>
                                    </Tooltip>
                                  )
                                },
                                {
                                  field: "itemType.name",
                                  headerName: "Type",
                                  flex: 1,
                                  renderCell: (params) => (
                                    <Tooltip title={(params.row as RegistrationCommitteeItem).itemType.name}>
                                      <span>{(params.row as RegistrationCommitteeItem).itemType.name}</span>
                                    </Tooltip>
                                  )
                                },
                                {
                                  field: "entityNumber",
                                  headerName: "Entity No.",
                                  width: 100
                                },
                                {
                                  field: "filesAttached",
                                  headerName: "Files Attached",
                                  width: 120,
                                  valueGetter: (params) => (params.row as RegistrationCommitteeItem).attachedDocuments.length
                                },
                                {
                                  field: "notes",
                                  headerName: "Notes",
                                  flex: 2,
                                  renderCell: (params) => {
                                    const cleanHtml = DOMPurify.sanitize((params.row as RegistrationCommitteeItem).notes ?? "");
                                    return <span dangerouslySetInnerHTML={{ __html: cleanHtml }} />;
                                  }
                                },
                                {
                                  field: "isReleasedForApproval",
                                  headerName: "Package Prepared",
                                  headerClassName: classes.wrapHeader,
                                  renderCell: (params) => (
                                    <YesNoIcon yes={(params.row as RegistrationCommitteeItem).isReleasedForApproval} />
                                  ),
                                  align: "center"
                                },
                                {
                                  field: "isApprovedForMeeting",
                                  headerName: "Approved for Meeting",
                                  headerClassName: classes.wrapHeader,
                                  renderCell: (params) => (
                                    <YesNoIcon yes={(params.row as RegistrationCommitteeItem).isApprovedForMeeting} />
                                  ),
                                  align: "center"
                                }
                              ]}
                            />
                          </Box>
                        ))}
                      </Stack>
                    </Collapse>
                  </div>
                );
              })
            )}
          </Stack>
        </Paper>
      </Collapse>
    </Grid>
  );
};

export default RegistrationCommitteeItemsTable;
