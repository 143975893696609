import { useQuery } from "@apollo/client";
import CrudTable from "common/CrudTable";
import { ScreenHeader } from "common/ScreenHeader";
import { YesNoIcon } from "common/YesNoIcon";
import React from "react";
import { Helmet } from "react-helmet";
import { RegistrationCommitteeItemCategory, RegistrationCommitteeItemType } from "registration-committee-items";
import EditRegistrationCommitteeItemTypeDialog from "./EditRegistrationCommitteeItemTypeDialog";
import { FetchRegistrationCommitteeItemCategories, FetchRegistrationCommitteeItemTypes } from "./queries";

const RegistrationCommitteeItemTypesScreen: React.FunctionComponent = () => {
  const categoriesQuery = useQuery<
    { registrationCommitteeItemCategories: RegistrationCommitteeItemCategory[] },
    { includeInactive: boolean }
  >(FetchRegistrationCommitteeItemCategories, {
    variables: { includeInactive: true }
  });
  const categories = categoriesQuery.data?.registrationCommitteeItemCategories ?? [];

  const typesQuery = useQuery<{ registrationCommitteeItemTypes: RegistrationCommitteeItemType[] }, { includeInactive: boolean }>(
    FetchRegistrationCommitteeItemTypes,
    {
      variables: { includeInactive: true }
    }
  );
  const types = typesQuery.data?.registrationCommitteeItemTypes ?? [];
  return (
    <>
      <Helmet>
        <title>Registration Committee Item Types - PRS Online</title>
      </Helmet>
      <ScreenHeader title="Registration Committee Item Types" />
      <CrudTable
        title="Item Types"
        rows={types}
        columnDefinitions={[
          {
            field: "name",
            headerName: "Name",
            flex: 1
          },
          {
            field: "categoryName",
            headerName: "Category",
            valueGetter: ({ row }) => (row as RegistrationCommitteeItemType).category.name,
            flex: 1
          },
          {
            field: "sortOrder",
            headerName: "Sort Order",
            headerAlign: "center",
            width: 90,
            align: "center"
          },
          {
            field: "isActive",
            headerName: "Active",
            headerAlign: "center",
            valueFormatter: ({ value }) => <YesNoIcon yes={!!value} />,
            width: 80,
            align: "center"
          }
        ]}
        initialState={{
          sorting: {
            sortModel: [
              { field: "categoryName", sort: "desc" },
              { field: "sortOrder", sort: "asc" }
            ]
          }
        }}
        renderAddDialog={(dialogProps) => (
          <EditRegistrationCommitteeItemTypeDialog
            {...dialogProps}
            categories={categories}
            title="Add Registration Committee Item Type"
            confirmButtonText="Add"
          />
        )}
        renderEditDialog={(id, dialogProps) => (
          <EditRegistrationCommitteeItemTypeDialog
            {...dialogProps}
            data={types.find((t) => t.id === id)}
            categories={categories}
            title="Edit Registration Committee Item Type"
            confirmButtonText="Save"
          />
        )}
      />
    </>
  );
};

export default RegistrationCommitteeItemTypesScreen;
